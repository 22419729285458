<template>
  <span class="player-club">
    <img :src="image" height="20" :alt="player.getClub" />
  </span>
</template>

<script>
import Player from "@/domain/models/Player";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerClub",
  props: {
    player: Player,
  },
  setup(props) {
    return {
      image: process.env.VUE_APP_CLUB_API_URL + (props.player.getClubImageBadge ? props.player.getClubImageBadge : "default.png"),
    };
  },
});
</script>
