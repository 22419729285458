<template>
  <div class="bidCard">
    <ion-row>
      <ion-col>
        <div class="bidCard__header">
          <div class="bidCard__header__item">
            <img src="/assets/calendar.svg" height="24">
            <span>Ronda {{ bids.getCurrentDay }}</span>
          </div>
          <div class="bidCard__header__item">
            <img src="/assets/buy.svg" height="20">
            <span>{{ bids.getFormattedFinishIn }}</span>
          </div>
          <div class="bidCard__header__item">
            <img src="/assets/wallet.svg" height="20">
            <span>{{ team.getFormattedBudgetLong }}</span>
          </div>
        </div>
        <div class="bidCard__my-bids" v-if="myBids.length > 0">
          <div class="bidCard__my-bids__header">
            <span>Mis pujas</span>
            <span>{{ formattedSumBids }}</span>
          </div>
          <div
            class="bidCard__my-bids__bid"
            v-for="bid in myBids"
            :key="bid"
          >
            <UpdateBidElement
              :bid="bid"
              @bid="refresh"
              :bidBatchId="bids.getId"
              @click="showPlayerDetails(bid.getBidBatchPlayer.getPlayer)"
            />
          </div>
          <div class="bidCard__my-bids__footer">
            Saldo disponible: {{ team.getFormattedVirtualBudgetLong }}
          </div>
        </div>
        <div class="bidCard__players">
          <div
            class="bidCard__players__player"
            v-for="bidBatchGroup in bids.getSortedBidBatchPlayers"
            :key="bidBatchGroup"
          >
            <div v-if="hasNonBiddedPlayers(bidBatchGroup.getPlayers)">
              <div class="bidCard__players__player__position">
                {{ bidBatchGroup.getLocalizedPosition }}
              </div>
              <div v-for="bidBatchPlayer in bidBatchGroup.getPlayers" :key="bidBatchPlayer">
                <BidElement
                  v-if="canBid(bidBatchPlayer.getId)"
                  :player="bidBatchPlayer.getPlayer"
                  :bidsCount="bidBatchPlayer.getBidsCount"
                  :bidBatchPlayerId="bidBatchPlayer.getId"
                  :budget="team.getVirtualBudget"
                  :bidBatchId="bids.getId"
                  @bid="refresh"
                  @click="showPlayerDetails(bidBatchPlayer.getPlayer)"
                />
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <FullModal
      :openedModal="playerDetails"
      @onPressBackButton="hidePlayerDetails()"
    >
      <PlayerProfile
        :player="playerToShowDetails"
      />
    </FullModal>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { IonRow, IonCol } from '@ionic/vue';
import BidElement from './BidElement';
import { longCurrency } from '@/domain/services/CurrencyFormatter'
import League from '@/domain/models/League'
import Season from '@/domain/models/Season'
import Team from '@/domain/models/Team'
import FullModal from '@/components/FullModal/FullModal.vue';
import PlayerProfile from '@/components/Player/PlayerProfile.vue';
import UpdateBidElement from './UpdateBidElement.vue';

export default {
  props: {
    league: League,
    season: Season,
    team: Team,
  },
  components: {
    IonRow,
    IonCol,
    BidElement,
    FullModal,
    PlayerProfile,
    UpdateBidElement,
  },
  emits: ['bidded'],
  setup(props, { emit }) {
    const myBids = ref([])
    const bids = ref([])
    const sumBids = ref(0)
    const playerDetails = ref(false)
    const playerToShowDetails = ref()
    const store = useStore()

    const showPlayerDetails = (player) => {
      playerToShowDetails.value = player
      playerDetails.value = true
    }

    const hidePlayerDetails = () => {
      playerDetails.value = false
    }

    const formattedBudgetLeftLong = computed(() => {
      return longCurrency(props.team.getBudget)
    })
    const formattedSumBids = computed(() => {
      return longCurrency(sumBids.value)
    })

    const canBid = (bidPBatchPlayerId) => {
      return !myBids
        .value
        .some(bid => {
          return bid.getBidBatchPlayer.getId == bidPBatchPlayerId
        })
    }

    const hasNonBiddedPlayers = (players) => {
      const biddedPlayersId = myBids.value.map(bid => bid.getBidBatchPlayer.getId)
      const playersId = players.map(player => player.getId)
      return playersId.filter(playerId => !biddedPlayersId.includes(playerId)).length > 0
    }

    const refresh = (amount) => {
      store.commit('showLockScreen')
      const data = {
        leagueId: props.league.getId,
        seasonId: props.season.getId,
        teamId: props.team.getId
      }
      store.dispatch('getBids', data)
        .then(res => {
          myBids.value = res
          sumBids.value = 0
          res.forEach((bid) => {
            sumBids.value += bid.getAmount
          })

          const data = {
            leagueId: props.league.getId,
            seasonId: props.season.getId,
          }
          store.dispatch('getBidBatch', data)
            .then(res => {
              const reload = new Date()
              reload.setSeconds(reload.getSeconds() + res.getFinishInSeconds)
              setInterval(() => {
                if (new Date().getTime() >= reload.getTime()) {
                  window.location.reload()
                }
              }, 1000)
              bids.value = res
              emit('bidded', amount)
            })
            .finally(() => {
              store.commit('hideLockScreen')
            })
        })
    }

    refresh(0)

    return {
      formattedSumBids,
      myBids,
      bids,
      playerDetails,
      playerToShowDetails,
      hasNonBiddedPlayers,

      showPlayerDetails,
      hidePlayerDetails,
      canBid,
      formattedBudgetLeftLong,

      refresh,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'BidCard.sass'
</style>
