<template>
  <InfoTable :items="tableItems()"/>
</template>

<script>
import InfoTable from "@/components/Table/InfoTable/InfoTable";
import League from "@/domain/models/League";

export default {
  name: 'Classification',
  components: { InfoTable },
  props: {
    league: League
  },
  setup(props) {
    const tableItems = () => {
      return props.league.getSeason.getPositionSortedTeams.map(team => team)
    }

    return {
      tableItems
    }
  }
}
</script>
