import UserFactory from '@/domain/services/UserFactory'
import TeamPlayerFactory from '@/domain/services/TeamPlayerFactory'
import SponsorFactory from '@/domain/services/SponsorFactory'
import Team from "@/domain/models/Team"

class TeamFactory {
  get (data) {
    const teamPlayers = processTeamPlayers(data.teamPlayers)
    const team = {
      id: data.id,
      avatar: data.avatar,
      name: data.name,
      position: processPosition(data.position),
      points: data.points,
      matchesPlayed: data.matchesPlayed,
      victories: data.victories,
      ties: data.ties,
      loses: data.loses,
      goalsFor: data.goalsFor,
      goalsAgainst: data.goalsAgainst,
      budget: data.budget,
      sponsor: SponsorFactory.get(data.sponsor),
      virtualBudget: data.virtualBudget,
      minPlayers: data.minPlayers,
      exceededSalaryTable: data.exceededSalaryTable,
      salaryTable: data.salaryTable,
      manager: UserFactory.get(data.manager),
      teamPlayers: teamPlayers,
      groupedPlayers: processGroupedPlayers(teamPlayers),
      deleted: data.deleted
    }

    return new Team(team)
  }
}

function processTeamPlayers(teamPlayers) {
  return teamPlayers.map(teamPlayer => TeamPlayerFactory.get(teamPlayer))
}

function processGroupedPlayers(teamPlayers) {
  const groups = {
    'goalkeeper': [],
    'defenses': [],
    'centers': [],
    'strikers': [],
  }
  teamPlayers.forEach(teamPlayer => {
    groups[teamPlayer.getPlayer.getCanonicalPosition].push(
      teamPlayer
    )
  })

   return groups
}

function processPosition(position) {
  if (position === 0) {
    return 1
  }
  return position
}

export default new TeamFactory()
