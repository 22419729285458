export default class PichichiPlayer {
  constructor (data) {
    this.goals = data.goals
    this.teamName = data.teamName
    this.teamId = data.teamId
    this.player = data.player
  }

  get getGoals () {
    return this.goals
  }

  get getFormattedGoals () {
    return this.goals + ' GT'
  }

  get getTeamName () {
    return this.teamName
  }

  get getTeamId () {
    return this.teamId
  }

  get getPlayer () {
    return this.player
  }
}
