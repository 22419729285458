<template>
  <div class="scorerSelector" :class="{ filled: value.length > 0, 'has-error': !valid }">
    <ion-button expand="full" fill="clear" @click="openScorersPicker">
      <ion-label slot="start">
        <slot />
      </ion-label>
    </ion-button>
  </div>
  <div class="players">
    <DismisablePlayer
      v-for="scorer in scorers"
      :key="scorer"

      :playerId="scorer.playerId"
      :goals="scorer.goals"
      :ownGoal="scorer.ownGoal"
      @close="removeScorer"
    >
      {{ scorer.name }}
    </DismisablePlayer>
  </div>
</template>
<script>
import { computed } from 'vue'
import {
  IonButton,
  IonLabel,
  pickerController,
} from '@ionic/vue';
import DismisablePlayer from './DismisablePlayer'
import Team from '@/domain/models/Team'

export default {
  name: "ScorerSelector",
  components: {
    DismisablePlayer,
    IonButton,
    IonLabel,
  },
  emits: ['blur', 'input', 'change', 'append', 'remove'],
  props: {
    value: {
      type: Array
    },
    primaryTeam: Team,
    secondaryTeam: Team,
    primaryTeamSufix: {
      type: String,
      default: ''
    },
    secondaryTeamSufix: {
      type: String,
      default: ' (PP)'
    },
    home: Boolean,
    valid: {
      type: Boolean,
      default: true
    },
    name: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const primaryPlayers = props.primaryTeam.getTeamPlayers.map(teamPlayer => {
      return {
        text: teamPlayer.getPlayer.getName + props.primaryTeamSufix,
        value: teamPlayer.getPlayer.getId,
        ownGoal: false
      }
    })
    const secondaryPlayers = props.secondaryTeam.getTeamPlayers.map(teamPlayer => {
      return {
        text: teamPlayer.getPlayer.getName + props.secondaryTeamSufix,
        value: teamPlayer.getPlayer.getId,
        ownGoal: true
      }
    })
    const primaryPlayersId = primaryPlayers.map(player => player.value)
    const allPlayers = primaryPlayers.concat(secondaryPlayers)

    const scorers = computed (() => {
      const players = []
      props.value.forEach((propsPlayer) => {
        const index = players.findIndex((player => player.playerId == propsPlayer.playerId))
        if (index >= 0) {
          players[index].goals++
        } else {
          const data = {
            name: propsPlayer.name,
            playerId: propsPlayer.playerId,
            goals: 1,
            ownGoal: propsPlayer.ownGoal,
            home: propsPlayer.home
          }

          players.push(data)
        }
      })
      return players
    })

    const removeScorer = (playerId) => {
      emit('remove', playerId)
    }

    const appendScorer = (value) => {
      const playerId = value.scorer.value
      const ownGoal = !primaryPlayersId.includes(playerId)
      const home = ownGoal ? !props.home : props.home
      const scorer = {
        playerId: playerId,
        name: value.scorer.text,
        goals: 1,
        ownGoal: ownGoal,
        home: home,
      }
      emit('append', scorer)
    }

    const openScorersPicker = async () => {
      if (props.disabled) {
        return false
      }
      const picker = await pickerController.create({
        columns: [{
          name: 'scorer',
          options: allPlayers
        }],
        buttons: [
          { text: "Cancelar", role: "cancel", },
          { text: "Confirmar", handler: appendScorer },
        ],
      });

      for (let col of picker.columns) {
        for (let opt of col.options) {
          delete opt.selected;
          delete opt.duration;
          delete opt.transform;
        }
      }
      await picker.present();
    }

    return {
      scorers,
      removeScorer,
      openScorersPicker,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import './ScorerSelector.sass'
</style>
