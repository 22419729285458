import {
  POSITION_GOALKEEPER,
  POSITION_DEFENSE,
  POSITION_CENTERS,
  POSITION_STRIKERS
} from "@/domain/models/Player"
import TeamPlayerFactory from "@/domain/services/TeamPlayerFactory"
import { uuid } from "vue-uuid"
import DraftedPlayersGroup from "../models/DraftedPlayersGroup"

class DraftedPlayersGroupFactory {
  get (data) {
      const index = {
        [POSITION_GOALKEEPER]: 1,
        [POSITION_DEFENSE]: 2,
        [POSITION_CENTERS]: 3,
        [POSITION_STRIKERS]: 4,
      }
      const draftedPlayersGroup = {
      id: uuid.v4(),
      name: data.name,
      players: processPlayers(data.playersGroups),
      sortIndex: index[data.name]
    }

    return new DraftedPlayersGroup(draftedPlayersGroup)
  }
}

function processPlayers(players) {
  return players.map(player => {
    const data = {
      player: player,
      locked: false
    }
    return TeamPlayerFactory.get(data)
  })
}

export default new DraftedPlayersGroupFactory()
