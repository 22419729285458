<template>
  <div>
    <JoinLeagueForm
      :title="joinLeagueTitle"
      :invertedColor="invertedColor"
      @closeModal="setLeague"
      v-if="showCard == 'step1'"
    />
    <BaseCreation
      :invertedColor="invertedColor"
      :fromOnboarding="fromOnboarding"
      @create="createLeague"
      v-if="showCard == 'step2'"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import BaseCreation from './BaseCreation.vue'
import JoinLeagueForm from '../LeagueForms/JoinLeagueForm.vue'
import { ref, unref } from '@vue/reactivity'
import League from '@/domain/models/League'

export default {
  components: {
    BaseCreation,
    JoinLeagueForm,
  },
  props: {
    invertedColor: {
      type: Boolean,
      default: false
    },
    fromOnboarding: {
      type: Boolean,
      default: false
    },
    joinLeagueTitle: String,
    startingCard: {
      type: String,
      default: 'step1'
    },
    league: League
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const showCard = ref(unref(props.startingCard))
    const league = ref()

    const setLeague = (selectedLeague) => {
      league.value = selectedLeague
      showCard.value = 'step2'
    }

    const createLeague = ({ sponsorId, teamName }) => {
      store.commit('showLockScreen')
      const selectedLeague = league.value || props.league
      store.dispatch('appendUserToLeague', selectedLeague.getCode)
        .then(() => {
          const data = {
            sponsorId: sponsorId,
            leagueId: selectedLeague.getId,
            seasonId: selectedLeague.getSeason.getId,
            teamName: teamName
          }
          store.dispatch('createTeam', data)
            .then(() => {
              store.dispatch('getLeague', selectedLeague.getId)
                .then(() => {
                  router.push({ name: 'draft', params: { id: selectedLeague.getId } })
                })
            })
        })
    }

    return {
      showCard,

      setLeague,
      createLeague,
    }
  },
}
</script>
