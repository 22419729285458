export default class Sponsor {
  constructor (data) {
    this.id = data.id,
    this.name = data.name,
    this.wins = data.wins,
    this.ties = data.ties,
    this.loses = data.loses,
    this.maxScorer = data.maxScorer,
    this.maxMvp = data.maxMvp,
    this.cards = data.cards,
    this.zamora = data.zamora,
    this.changes = data.changes,
    this.bestSquad = data.bestSquad,
    this.locks = data.locks
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getWins () {
    return this.wins
  }

  get getTies () {
    return this.ties
  }

  get getLoses () {
    return this.loses
  }

  get getMaxScorer () {
    return this.maxScorer
  }

  get getMaxMvp () {
    return this.maxMvp
  }

  get getCards () {
    return this.cards
  }

  get getZamora () {
    return this.zamora
  }

  get getChanges () {
    return this.changes
  }

  get getBestSquad () {
    return this.bestSquad
  }

  get getLocks () {
    return this.locks
  }

  get getImage () {
    return encodeURIComponent(this.getName) + '.jpg'
  }
}
