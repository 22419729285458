export default class TeamPlayer {
  constructor (data) {
    this.id = data.id,
    this.player = data.player
    this.locked = data.locked
    this.receivedDirectBuy = data.receivedDirectBuy
  }

  get getId () {
    return this.id
  }

  get getPlayer () {
    return this.player
  }

  get hasReceivedDirectBuy () {
    return this.receivedDirectBuy
  }

  get isLocked () {
    return this.locked
  }

  setLocked (locked) {
    this.locked = locked
  }
}
