<template>
  <div class="pickTeamNameCard">
    <ion-row>
      <ion-col>
        <span class="pickTeamNameCard__image">
          <img src="/assets/name-team.svg" />
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="pickTeamNameCard__title">
          ¿Cómo quieres que se llame tu equipo?
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="pickTeamNameCard__form">
        <Form ref="nameTeamForm" @onSubmit="nameTeamHandler" @keyup.enter="nameTeamHandler">
          <Field name="team" label="nombre del equipo" v-slot="{ field, meta }" rules="required">
            <Input
                v-bind="field"
                :valid="!meta.validated || meta.valid"
                placeholder="Nombre equipo"
                inputType="text"
                @change="onChangeName"
            />
          </Field>
          <ErrorMessage name="team" v-slot="{ message }">
            <div v-if="message" class="error">{{ message }}</div>
          </ErrorMessage>
        </Form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <PrimaryButton text="Continuar" @onClick="nameTeamHandler" />
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonCol, IonRow } from '@ionic/vue'
import Input from "@/components/Input/Input";
import Form from "@/components/Input/Form"
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from '@vee-validate/rules';
import { ref } from '@vue/reactivity';
import PrimaryButton from '../Button/PrimaryButton.vue';

export default {
  components: {
    IonCol,
    IonRow,
    Form,
    Input,
    Field,
    ErrorMessage,
    PrimaryButton,
  },
  emits: [ 'nameSet' ],
  setup(_, { emit }) {
    const nameTeamForm = ref()
    const teamName = ref('')

    const nameTeamHandler = () => {
      nameTeamForm.value.validate()
      .then(({ valid }) => {
        if (!valid) {
          return
        }

        emit('nameSet', teamName.value)
      })
    }

    const onChangeName = (ev) => {
      teamName.value = ev.target.value
    }

    defineRule('required', required)

    return  {
      required,

      nameTeamForm,
      onChangeName,
      nameTeamHandler
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'PickTeamNameCard.sass'
</style>
