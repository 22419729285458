<template>
  <div class="Sections">
    <div class="title">
      Pichichi
    </div>
    <div v-for="pichichiPlayer in pichichiPlayers" v-bind:key="pichichiPlayer.getPlayer.getId" class="team">
      <NoAvatarInfoCard
          :initials=pichichiPlayer.getPlayer.getInitials
          :title=pichichiPlayer.getPlayer.getName
          :subtitle=pichichiPlayer.getTeamName
          :info=pichichiPlayer.getFormattedGoals
      />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import NoAvatarInfoCard from "@/components/Player/NoAvatarInfoCard";

  export default  {
  name: 'Pichichi',
  components: {
    NoAvatarInfoCard
  },
  setup() {
    const store = useStore()
    const pichichiPlayers = ref([])
    const league = store.getters.getCurrentLeague

    const data = {
      leagueId: league.getId,
      seasonId: league.getSeason.getId,
    }

    store.dispatch('getSeasonPichichi', data)
      .then(retrievedPlayers => {
        const teams = league.getSeason.getTeams
        pichichiPlayers.value = retrievedPlayers.filter(player => {
          const playerTeam = teams.find(team => team.getId === player.getTeamId)
          return !playerTeam.isDeleted
        })
      })

    return {
      pichichiPlayers
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "Sections.sass"
</style>
