<template>
  <div class="infoCard">
    <div class="infoCard__left">
      <InitialsAvatar>{{ initials }}</InitialsAvatar>
      <div class="infoCard__info">
        <div class="infoCard__title">{{ title }}</div>
        <div class="infoCard__subtitle">{{ subtitle }}</div>
      </div>
    </div>
    <div class="infoCard__rightInfo" @click="click">
      <div class="infoCard__rightInfo__data">
        {{ info }}
      </div>
    </div>
  </div>
</template>

<script>
import InitialsAvatar from '../InitialsAvatar/InitialsAvatar.vue'
export default {
  components: { InitialsAvatar },
  name: "NoAvatarInfoCard",
  emits: ['rightButtonClicked'],
  props: {
    initials: String,
    title: String,
    subtitle: String,
    info: String,
    enableRightButton: Boolean
  },
  setup(props, { emit }) {
    const click = (ev) => {
      if (!props.enableRightButton) {
        return
      }
      ev.cancelBubble = true
      emit('rightButtonClicked')
    }

    return {
      click
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'NoAvatarInfoCard.sass'
</style>
