<template>
  <div class="offerForm">
    <ion-row>
      <ion-col>
        <div class="offerForm__title">
          Oferta por {{ player.getName }}
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="offerForm__wallet">
          <img src="/assets/wallet.svg">
          <span>{{ formattedBudget }}</span>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="offerForm__wallet">
          <IncreaseDecrease
            @increase="increase"
            @decrease="decrease"
          >
            <ion-input
              class="input-amount"
              :value="formattedBidAmount"
              @ionFocus="removeFormat"
              @ionBlur="applyFormat"
            />
          </IncreaseDecrease>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="offerForm__button">
          <ion-button
            v-if="budget >= amount"
            class="bid"
            mode="ios"
            @click="$emit('offer', {
              amount: amount,
              player: player
            })"
          >
            Enviar oferta
          </ion-button>
          <ion-button
            v-if="budget < amount"
            class="error"
            mode="ios"
          >
            <img src="/assets/danger.svg"> no tienes suficiente dinero
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonButton,
  IonInput
} from "@ionic/vue";
import Player from "@/domain/models/Player";
import { longCurrency } from "@/domain/services/CurrencyFormatter";
import IncreaseDecrease from '@/components/IncreaseDecrease/IncreaseDecrease';
import { ref } from '@vue/reactivity';

export default {
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IncreaseDecrease,
  },
  props: {
    player: Player,
    budget: Number
  },
  setup(props) {
    const formattedBudget = longCurrency(props.budget)
    const min = 1000
    const step = 1000

    const getAmount = () => {
      if (props.budget >= props.player.getTerminationClause) {
        return props.player.getTerminationClause
      }

      return props.budget
    }

    const amount = ref(getAmount())

    const formattedBidAmount = ref(longCurrency(amount.value))

    const increase = () => {
      amount.value += step
      formattedBidAmount.value = longCurrency(amount.value)
    }

    const decrease = () => {
      if (amount.value <= min) {
        return
      }
      amount.value -= step
      formattedBidAmount.value = longCurrency(amount.value)
    }

    const removeFormat = (ev) => {
      ev.target.value = amount.value
    }

    const applyFormat = (ev) => {
      amount.value = parseInt(ev.target.value.toString().replace(/[^0-9]+/, ''))
      if (amount.value < min) {
        amount.value = min
      }
      ev.target.value = longCurrency(amount.value)
    }

    return {
      formattedBudget,
      formattedBidAmount,
      amount,

      removeFormat,
      applyFormat,
      increase,
      decrease
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OfferForm.sass'
</style>