<template>
  <div class="openMarketCard">
    <ion-row>
      <ion-col>
        <div class="openMarketCard__header">
          <div class="openMarketCard__header__top">
            <TimeTo :date="league.getSeason.getRegularStartAt" />
          </div>
          <div class="openMarketCard__header__text">
            Ajusta tu plantilla para tener 18 jugadores y no sobrepasar el límite de la masa salarial
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <TeamHealth :team="team" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="openMarketCard__search">
          <ion-searchbar
            placeholder="Buscar jugadores"
            inputMode="text"
            @ionChange="search"
            debounce="500"
          />
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="openMarketCard__search--sort" @click="changeSort()">
          <img :src="searchSort.getIcon" /> Ordernar por Rtg
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="openMarketCard__players">
          <div v-for="player in players" :key="player">
            <PlayerInfoCard
              :player="player"
              :locked="false"
              @click="showPlayerProfile(player)"
            >
              <template v-slot:right>
                <div class="playerInfoCard__directbuy" @click.stop="directBuy(player)">
                  <img src="/assets/buy.svg" />
                </div>
              </template>
            </PlayerInfoCard>
          </div>
          <FullModal
            :openedModal="modalPlayer"
            @onPressBackButton="hidePlayerProfile(selectedPlayer.getId)"
          >
            <PlayerProfile
              :player="selectedPlayer"
              @bought="bought"
            >
              <template v-slot:footer>
                <PrimaryButton
                  v-if="selectedPlayer.getTerminationClause <= team.getBudget"
                  text="Comprar"
                  @onClick="bought(selectedPlayer)"
                />
                <ion-button v-else class="no-funds-button" mode="ios">No tienes suficiente dinero</ion-button>
              </template>
            </PlayerProfile>
          </FullModal>
          <PopupModal
            :is-open="buyModalPlayers[selectedPlayer.getId]"
            @closed="hideBuyModalPlayers(selectedPlayer.getId)"
          >
            <div class="buy-modal-players">
              <div class="title">
                Comprar a {{ selectedPlayer.getName }} por:
              </div>
              <div class="termination-clause">
                {{ selectedPlayer.getFormattedTerminationClauseLong }}
              </div>
              <div class="button">
                <SmallButton
                  v-if="selectedPlayer.getTerminationClause <= team.getBudget"
                  text="Comprar"
                  @onClick="doBuy(selectedPlayer)" />
                <span
                  v-else
                  class="no-funds-button"
                >No tienes suficiente dinero</span>
              </div>
            </div>
          </PopupModal>
          <div class="openMarketCard__players__not-found" v-if="showPlayersNotFound()">
            No se encontraron jugadores
          </div>
        </div>
      </ion-col>
    </ion-row>
    <PopupModal
      :is-open="showSuccessfulBuy"
      @closed="closeSuccessfulBuy()"
    >
      <div class="successfulBuy">
        <div class="title">
          ¡Has comprado el jugador exitosamente!
        </div>
        <div class="button">
          <SmallButton text="Cerrar" @onClick="closeSuccessfulBuy()"></SmallButton>
        </div>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import {
  IonSearchbar,
  IonRow,
  IonCol,
  IonButton,
  modalController,
} from '@ionic/vue'
import League from '@/domain/models/League'
import Team from '@/domain/models/Team'
import SearchSort from '@/domain/services/SearchSort'
import TimeTo from '@/components/TimeTo/TimeTo'
import { useStore } from 'vuex'
import { reactive, ref } from 'vue'
import FullModal from '@/components/FullModal/FullModal'
import PlayerProfile from '@/components/Player/PlayerProfile'
import PopupModal from '@/components/PopupModal/PopupModal'
import SmallButton from '@/components/Button/SmallButton'
import PrimaryButton from '@/components/Button/PrimaryButton'
import TeamHealth from '@/components/TeamHealth/TeamHealth'
import PlayerInfoCard from '@/components/PlayerInfoCard/PlayerInfoCard.vue'

export default {
  components: {
    IonSearchbar,
    IonRow,
    IonCol,
    IonButton,
    TimeTo,
    FullModal,
    PlayerProfile,
    PopupModal,
    SmallButton,
    PrimaryButton,
    TeamHealth,
    PlayerInfoCard,
  },
  props: {
    league: League,
    team: Team,
  },
  setup(props) {
    const store = useStore()
    const players = ref([])
    const modalPlayer = ref(false)
    const buyModalPlayers = ref({})
    const showSuccessfulBuy = ref(false)
    let fromSearch = false
    const selectedPlayer = ref({})
    const searchParams = reactive({
      name: '',
      sort: null
    })
    const searchSort = ref(new SearchSort())

    setInterval(() => {
      if (new Date().getTime() >= props.league.getSeason.getRegularStartAt.getTime()) {
        window.location.reload()
      }
    }, 1000)

    const getOpenMarketPlayers = () => {
      if (searchParams.name == '') {
        store.commit('showLockScreen')
      }
      const data = {
        leagueId: props.league.getId,
        seasonId: props.league.getSeason.getId,
        name: searchParams.name.value,
        sort: searchSort.value.getValue
      }
      players.value = []
      store.dispatch('searchFreeAgents', data)
        .then((res) => {
          players.value = res
          res.forEach((player) => {
            buyModalPlayers.value[player.getId] = false
          })
        })
        .finally(() => {
          if (searchParams.name == '') {
            store.commit('hideLockScreen')
          }
          fromSearch = true
        })
    }

    getOpenMarketPlayers()

    const search = ({ detail }) => {
      searchParams.name = detail
      getOpenMarketPlayers()
    }

    const infoPlayer = (player) => {
      return player.getFormattedTerminationClause + ' · ' + player.getFormattedSalary
    }

    const showPlayersNotFound = () => {
      return fromSearch && players.value.length == 0
    }

    const showPlayerProfile = (player) => {
      modalPlayer.value = true
      selectedPlayer.value = player
    }

    const hidePlayerProfile = () => {
      modalPlayer.value = false
    }

    const hideBuyModalPlayers = (playerId) => {
      buyModalPlayers.value[playerId] = false
    }

    const bought = (player) => {
      modalPlayer.value = false
      selectedPlayer.value = player
      buyModalPlayers.value[player.getId] = true
    }

    const doBuy = (player) => {
      store.commit('showLockScreen')
      const data = {
        leagueId: props.league.getId,
        seasonId: props.league.getSeason.getId,
        teamId: props.team.getId,
        playerId: player.getId,
      }
      store.dispatch('buyPlayer', data)
        .then(() => {
          store.dispatch('getLeague', props.league.getId)
            .then(() => {
              showSuccessfulBuy.value = true
              store.commit('hideLockScreen')
            })
        })
      modalController.dismiss()
    }

    const closeSuccessfulBuy = () => {
      showSuccessfulBuy.value = false
      getOpenMarketPlayers('')
    }

    const directBuy = (player) => {
      selectedPlayer.value = player
      buyModalPlayers.value[player.getId] = true
    }

    const changeSort = () => {
      searchSort.value.inc()
      getOpenMarketPlayers()
    }

    return {
      players,
      search,
      modalPlayer,
      buyModalPlayers,
      showSuccessfulBuy,
      selectedPlayer,
      searchSort,

      infoPlayer,
      showPlayersNotFound,
      showPlayerProfile,
      hidePlayerProfile,
      hideBuyModalPlayers,
      bought,
      doBuy,
      closeSuccessfulBuy,
      directBuy,
      changeSort,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OpenMarketCard.sass'
</style>
