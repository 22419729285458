<template>
  <div class="leagueModal" :class="{ invertedColor: invertedColor }">
    <ion-row>
      <ion-col>
        <span class="leagueModal__title">{{ title }}</span>
        <Form ref="joinCodeForm" @onSubmit="handleJoinLeague" @keyup.enter="handleJoinLeague">
          <div class="leagueModal__formError" v-if="formError" v-html="formError" />
          <div class="leagueModal__body">
            <Field name="code" label="código de invitación" v-slot="{ field, meta }" rules="required|length:6">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Codigo de invitacion"
                  inputType="text"
                  @change="onChangeJoinCode"
                  :backgroundColor="invertedColor ? 'invertedColor' : ''"
              />
            </Field>
            <ErrorMessage name="code" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <span class="leagueModal__body__footer">¿No tienes código?...</span>
          </div>
          <div class="leagueModal__joinButton">
            <PrimaryButton text="Unirme" @onClick="handleJoinLeague"/>
          </div>
        </Form>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue'
import Form from "../Input/Form"
import Input from "../Input/Input";
import PrimaryButton from "../Button/PrimaryButton";
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { required, length } from "@vee-validate/rules"
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { getMessage } from "@/domain/services/ErrorMessages"

export default {
  name: "RegisterForm",
  components: {
    PrimaryButton,
    IonRow,
    IonCol,
    Form,
    Input,
    Field,
    ErrorMessage
  },
  props: {
    title: {
      type: String,
      default: 'Introduce el código'
    },
    invertedColor: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeModal'],
  setup(_, { emit }) {
    const joinCode = ref('')
    const joinCodeForm = ref()
    const store = useStore()
    const formError = ref(false)

    const handleJoinLeague = () => {
      joinCodeForm.value.validate()
        .then(({ valid }) => {
          if (!valid) {
            return
          }
          const trimmedJoinCode = joinCode.value.toString().toUpperCase().trim()

          store.dispatch('getLeagueByCode', trimmedJoinCode)
            .then((league) => {
              const user = store.getters.getLoggedUser

              if (league.getSeason.getManagers.filter(manager => manager.getId == user.getId).length > 0) {
                formError.value = getMessage('api.league.user_already_added')
                return
              }
              emit('closeModal', league)
            })
            .catch(res => {
              formError.value = getMessage(res.errors)
            })
        })
    }

    const onChangeJoinCode = (event) => {
      joinCode.value = event.target.value
      formError.value = false
    }

    defineRule('required', required)
    defineRule('length', length)

    return {
      joinCode,
      joinCodeForm,
      formError,

      handleJoinLeague,
      onChangeJoinCode,
    }
  }
};
</script>

<style lang="sass" scoped>
  @import './LeagueForms.sass'
</style>
