import PlayerFactory from "@/domain/services/PlayerFactory"
import Scorer from "@/domain/models/Scorer"

class ScorerFactory {
  get (data) {
    const scorer = {
      id: data.id,
      goals: data.goals,
      home: data.home,
      ownGoal: data.ownGoal,
      player: PlayerFactory.get(data.player)
    }

    return new Scorer(scorer)
  }
}

export default new ScorerFactory()
