export const getUserTeamByLeague = (league, user) => {
  const season = league.getSeason
  const team = season.getTeams.find((team) => {
    return team.getManager.getId === user.getId
  })

  if (!team) {
    return null
  }

  return team
}