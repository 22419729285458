import axios from '@/plugins/axios'
import { uuid } from 'vue-uuid'
import UserFactory from '@/domain/services/UserFactory'

const URL_GET_USER_INFO = '/api/users/me'
const URL_CREATE_USER = '/api/users'

export function getUser() {
  return axios.get(URL_GET_USER_INFO)
    .then(res => UserFactory.get(res.data))
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function createUser(username, fifaId, email, password, terms) {
  const user = {
    id: uuid.v4(),
    username: username,
    fifaId: fifaId,
    email: email,
    password: password,
    terms: terms,
    notification: false
  }
  return axios.post(URL_CREATE_USER, user)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}