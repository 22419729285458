<template>
  <div class="menuHeader">
    <div class="menuHeader__menu">
      <MenuButton v-if="showMenuButton" />
      <BackButton v-if="showBackButton" @onClick="$emit('onPressBackButton')"/>
    </div>
    <div class="menuHeader__info">
      <div>
        <div class="menuHeader__title">
          <slot />
        </div>
        <div class="menuHeader__subtitle">
          <slot name="info" />
        </div>
      </div>
      <ClosePhaseButton v-if="showClosePhase" />
    </div>
    <div class="menuHeader__notification" v-if="showNotifications">
      <div class="menuHeader__notification__image">
        <img src="/assets/notification.svg" />
        <div class="menuHeader__notification__image__amount" :data-amount="notificationAmount">
          {{ notificationAmount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import MenuButton from "./MenuButton";
import BackButton from "./BackButton";
import ClosePhaseButton from "@/components/ClosePhaseButton/ClosePhaseButton";
import { ref } from '@vue/reactivity';

export default {
  name: "menuHeader",
  components: { MenuButton, BackButton, ClosePhaseButton },
  props: {
    showMenuButton: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const notificationAmount = ref(0)
    const user = store.getters.getLoggedUser
    const league = store.getters.getCurrentLeague
    const season = league.getSeason
    const showClosePhase = ref(season.canChangePhase && league.belongsTo(user))

    if (notificationAmount.value > 9) {
      notificationAmount.value = '+9'
    }

    const showNotifications = false

    return {
      showNotifications,
      notificationAmount,
      showClosePhase
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./MenuHeader.sass"
</style>
