export default class PichichiPlayer {
  constructor (data) {
    this.goalsAgainst = data.goalsAgainst
    this.teamName = data.teamName
    this.teamId = data.teamId
    this.player = data.player
  }

  get getGoalsAgainst () {
    return this.goalsAgainst
  }

  get getFormattedGoalsAgainst () {
    return this.goalsAgainst + ' GR'
  }

  get getTeamName () {
    return this.teamName
  }

  get getTeamId () {
    return this.teamId
  }

  get getPlayer () {
    return this.player
  }
}
