import TeamFactory from "@/domain/services/TeamFactory"
import PlayerFactory from "@/domain/services/PlayerFactory"
import Offer from "@/domain/models/Offer"

class OfferFactory {
  get (data) {
    const offer = {
      id: data.id,
      teamFrom: TeamFactory.get(data.teamFrom),
      teamTo: TeamFactory.get(data.teamTo),
      player: PlayerFactory.get(data.player),
      amount: data.amount,
      accepted: data.accepted,
      expired: data.expired
    }

    return new Offer(offer)
  }
}

export default new OfferFactory()
