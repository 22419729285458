<template>
  <div class="customCheckbox" @click="$emit('changeBoolean')">
    <ion-checkbox class="customCheckbox__checkbox" :modelValue="model"/>
    <ion-label class="customCheckbox__label">{{ labelText }}</ion-label>
  </div>
</template>

<script>
import {IonCheckbox, IonLabel} from "@ionic/vue";

export default {
  name: "Checkbox",
  components: { IonCheckbox, IonLabel },
  props: {
    model: Boolean,
    labelText: String
  }
}
</script>

<style lang="sass" scoped>
  @import './Checkbox.sass'
</style>
