<template>
  <ion-segment
      scrollable
      :value="activeTab"
      class="navBarSegment"
      @ionChange="segmentChanged($event)"
      mode="md"
  >
    <ion-segment-button v-for="segment in segments" :value="segment.key" :key="segment.key">
      <ion-label>{{ segment.label }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script>
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/vue";

export default {
  name: 'NavBar',
  components: { IonSegment, IonSegmentButton, IonLabel },
  props: {
    segments: Array,
    activeTab: String
  },
  emits: ['changeActiveTab'],
  setup(props, { emit }) {

    const segmentChanged = (ev) => {
      emit('changeActiveTab', ev.detail.value)
    }

    return {
      segmentChanged
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './NavBar.sass'
</style>
