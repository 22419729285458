<template>
  <ion-page>
    <ion-header></ion-header>
    <ion-content>
      <h1>¡Enhorabuena!</h1>
      <Button text="Aceptar" @click="$router.push({name: 'select_league'})" />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent } from '@ionic/vue'
import Button from "../../components/Button/Button";

export default {
  name: "AcceptLegalTerms",
  components: { Button, IonPage, IonHeader, IonContent }
}
</script>
