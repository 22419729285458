import axios from '@/plugins/axios';
import PositionBidBatchFactory from "@/domain/services/PositionBidBatchFactory"

const URL_READ_BID_BATCH = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/bid-batch'

export function getBidBatch(leagueId, seasonId) {
  const url = URL_READ_BID_BATCH
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)

  return axios.get(url)
    .then(res => {
      return PositionBidBatchFactory.get(res.data)
    })
    .catch(error => Promise.reject(error.response.data))
}
