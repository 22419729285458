<template>
  <div class="playerInfoCard">
    <div class="playerInfoCard__left">
      <div class="playerInfoCard__left__initials">
        <PlayerPhoto :player="player" size="regular" />
      </div>
      <div class="playerInfoCard__left__club">
        <PlayerClub :player="player" />
      </div>
    </div>
    <div class="playerInfoCard__info">
      <div class="playerInfoCard__info__header">
        <div class="middle-header" @click="$emit('clicked')">
          <div class="playerInfoCard__info__name">
            {{ player.getName }}
          </div>
        </div>
        <div class="top-header" @click="$emit('clicked')">
          <div class="playerInfoCard__info__second-header">
            <PlayerFlag :player="player" />
            <span class="years">{{ player.years }} años </span>
            <SmallBadge>{{ player.getPosition }}</SmallBadge>
          </div>
        </div>
        <slot name="subtitle" />
      </div>
      <div class="playerInfoCard__info__footer">
        <div>
          <div class="playerInfoCard__info__footer__top">
            Salario
          </div>
          <div class="playerInfoCard__info__footer__bottom">
            {{ player.getFormattedSalary }}
          </div>
        </div>
        <div>
          <div class="playerInfoCard__info__footer__top">
            Cláusula
          </div>
          <div class="playerInfoCard__info__footer__bottom">
            {{ player.getFormattedTerminationClause }}
          </div>
        </div>
      </div>
    </div>
    <slot name="right">
      <div class="playerInfoCard__right" @click.stop>
        <div class="playerInfoCard__right__top">
          <slot>
            <div class="playerInfoCard__right__locked" v-if="locked">
              <img src="/assets/lock.svg" height="20">
            </div>
          </slot>
        </div>
        <div class="playerInfoCard__rtg">
          <span class="playerInfoCard__rtg__value">{{ player.getRTG }}</span>
          <span class="playerInfoCard__rtg__label">Rtg</span>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Player from "@/domain/models/Player";
import SmallBadge from '../Badge/SmallBadge.vue';
import PlayerPhoto from "./PlayerPhoto.vue";
import PlayerFlag from "./PlayerFlag.vue";
import PlayerClub from "./PlayerClub.vue";

export default {
  components: { SmallBadge, PlayerPhoto, PlayerFlag, PlayerClub },
  name: "PlayerInfoCard",
  props: {
    player: Player,
    locked: Boolean
  }
}
</script>

<style lang="sass" scoped>
  @import 'PlayerInfoCard.sass'
</style>
