<template>
  <div class="offersReceived">
    <div class="offersReceived__header">
      <span>Ofertas recibidas</span>
      <span v-if="totalOffersReceived > 0">{{ longCurrency(totalOffersReceived) }}</span>
    </div>
    <div v-for="offer in offers" :key="offer">
      <OffersReceivedPlayer
        :offer="offer"
        @showPlayer="showPlayer"
        @offerAccepted="showAcceptOfferModal"
        @offerRejected="showRejectOfferModal"
        @offerDeleted="deleteOffer"
      />
    </div>
    <div v-if="offers.length == 0" class="offersReceived__noReceived">
      No has recibido ofertas
    </div>
    <PopupModal
      :is-open="modals.accept"
      @closed="hideModal('accept')"
    >
      <div class="settleOfferModal">
        <div>
          ¿Deseas aceptar la oferta de {{ selectedOffer.getTeamFrom.getManager.getUsername }}
          para vender a {{ selectedOffer.getPlayer.getName }} por {{ selectedOffer.getFormattedAmountLong }}
        </div>
        <div>
          <SmallButton text="Aceptar oferta" @onClick="acceptOffer(selectedOffer)" />
        </div>
      </div>
    </PopupModal>
    <PopupModal
      :is-open="modals.reject"
      @closed="hideModal('reject')"
    >
      <div class="settleOfferModal">
        <div>
          ¿Deseas rechazar la oferta de {{ selectedOffer.getTeamFrom.getManager.getUsername }}
          para vender a {{ selectedOffer.getPlayer.getName }} por {{ selectedOffer.getFormattedAmountLong }}
        </div>
        <div>
          <SmallButton text="Rechazar oferta" @onClick="rejectOffer(selectedOffer)" />
        </div>
      </div>
    </PopupModal>
    <PopupMessage
      :isOpen="modals.showPlayerNotFoundModal"
      @closed="hideModal('showPlayerNotFoundModal')"
    >
      Este jugador ya no forma parte de tu plantilla
    </PopupMessage>
    <FullModal
      :openedModal="modals.playerProfile"
      @onPressBackButton="hideModal('playerProfile')"
    >
      <PlayerProfile
        :player="selectedPlayer"
      />
    </FullModal>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { longCurrency } from '@/domain/services/CurrencyFormatter'
import PopupModal from '@/components/PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'
import PopupMessage from '@/components/PopupMessage/PopupMessage.vue'
import OffersReceivedPlayer from './OffersReceivedPlayer.vue'
import FullModal from '@/components/FullModal/FullModal.vue'
import PlayerProfile from '@/components/Player/PlayerProfile.vue'
export default {
  components: {
    PopupModal,
    SmallButton,
    PopupMessage,
    OffersReceivedPlayer,
    FullModal,
    PlayerProfile,
  },
  setup() {
    const store = useStore()
    const selectedOffer = ref()
    const selectedPlayer = ref()
    const modals = reactive({
      accept: false,
      reject: false,
      playerProfile: false,
      showPlayerNotFoundModal: false
    })
    const totalOffersReceived = computed(() => {
      return offers.value.map(offer => {
          if (offer.isAccepted !== null) {
            return 0
          }
          return offer.getAmount
        }).reduce((a, b) => {
          return a + b
        }, 0)
    })
    const offers = ref([])

    const league = store.getters.getCurrentLeague
    const team = store.getters.getCurrentTeam

    const data = {
      leagueId: league.getId,
      seasonId: league.getSeason.getId,
      teamId: team.getId
    }
    store.dispatch('getOffers', data)
      .then(res => {
        offers.value = res
      })

    const rejectOffer = (offer) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        offerId: offer.getId
      }
      store.dispatch('rejectOffer', data)
        .then(() => {
          offer.setAccepted(false)
          modals.reject = false
        })
    }

    const acceptOffer = (offer) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        offerId: offer.getId
      }
      store.dispatch('acceptOffer', data)
        .then(() => {
          team.modifyBudget(offer.getAmount)
          team.removePlayer(offer.getPlayer)

          offer.setAccepted(true)
        })
        .catch(error => {
          if (error.errors == 'api.player.not_found') {
            modals.showPlayerNotFoundModal = true
          }
        })
        .finally(() => {
          modals.accept = false
        })
    }

    const deleteOffer = (offer) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        offerId: offer.getId
      }
      store.dispatch('deleteMyOffer', data)
        .then(() => {
          const index = offers.value.findIndex(offerInArray => offerInArray.getId == offer.getId)
          offers.value.splice(index, 1)
        })
    }

    const hideModal = (key) => {
      modals[key] = false
    }

    const showAcceptOfferModal = (offer) => {
      selectedOffer.value = offer
      modals.accept = true
    }

    const showRejectOfferModal = (offer) => {
      selectedOffer.value = offer
      modals.reject = true
    }
    const showPlayer = (player) => {
      selectedPlayer.value = player
      modals.playerProfile = true
    }

    return {
      totalOffersReceived,
      offers,
      modals,
      selectedOffer,
      selectedPlayer,

      rejectOffer,
      acceptOffer,
      deleteOffer,
      hideModal,
      longCurrency,
      showPlayer,
      showAcceptOfferModal,
      showRejectOfferModal,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OffersReceived.sass'
</style>