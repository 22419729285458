import Sponsor from "@/domain/models/Sponsor"

class SponsorFactory {
  get (data) {
    const sponsor = {
      id: data.id,
      name: data.name,
      wins: data.wins,
      ties: data.ties,
      loses: data.loses,
      maxScorer: data.maxScorer,
      maxMvp: data.maxMvp,
      cards: data.cards,
      zamora: data.zamora,
      changes: data.changes,
      bestSquad: data.bestSquad,
      locks: data.locks
    }

    return new Sponsor(sponsor)
  }
}

export default new SponsorFactory()
