import axios from '@/plugins/axios';

const URL_CREATE_SCORER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results/{resultId}/scorers'
const URL_FORCE_CREATE_SCORER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results/{resultId}/scorers?force'

export function createScorer(leagueId, seasonId, resultId, data) {
  const url = URL_CREATE_SCORER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{resultId}', resultId)

  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function forceCreateScorer(leagueId, seasonId, resultId, data) {
  const url = URL_FORCE_CREATE_SCORER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{resultId}', resultId)

  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}
