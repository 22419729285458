<template>
  <div class="timeTo">{{ formattedDate }}</div>
</template>

<script>
import { daysFromNow } from '@/domain/services/DateFormatter'
import { ref } from '@vue/reactivity'
import { onDeactivated, onMounted } from '@vue/runtime-core'
import {
  formatToDaysHoursMinutes,
  formatToDaysHoursMinutesSeconds,
} from '@/domain/services/DateFormatter'

export default {
  props: {
    date: Date,
    refresh: {
      type: Number,
      default: 60000
    },
    showSeconds: Boolean
  },
  setup(props) {
    const formatTime = props.showSeconds ? formatToDaysHoursMinutesSeconds : formatToDaysHoursMinutes

    const formattedDate = ref(formatTime(daysFromNow(props.date.getTime())))
    let interval = null

    onMounted(() => {
      interval = setInterval(() => {
        formattedDate.value = formatTime(daysFromNow(props.date.getTime()))
      }, props.refresh)
    })

    onDeactivated(() => {
      clearInterval(interval)
    })

    return { formattedDate }
  },
}
</script>