const messages = {
  "password_compromised_publicly": 'La contraseña es poco segura',
  "api.user.email_already_registered": 'Ya existe un usuario con ese email',

  "api.league.started": 'Oops! Parece que esta liga ya ha empezado.<br>¡Crea tu propia liga!',
  "api.league.user_already_added": 'Ya perteneces a esta liga',
  "api.league.exceeded_players": 'Esta liga no admite más participantes',
  "api.league.not_found": 'No existe una liga asociada con ese código',
}

export const getMessage = (errors) => {
  let key = errors
  if (typeof(key) === 'object') {
    key = errors[0].message
  }

  return messages[key]
}
