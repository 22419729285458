<template>
  <div class="dismisablePlayer">
    <slot />
    <span v-if="goals > 1">({{ goals }} goles)</span>
    <div class="close">
      <img src="/assets/close.svg" @click="close">
    </div>
  </div>
</template>
<script>
export default {
  emits: [ 'close' ],
  props: {
    playerId: String,
    goals: Number,
    ownGoal: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close', props.playerId)
    }

    return {
      close
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'DismisablePlayer.sass'
</style>
