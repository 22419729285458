<template>
  <div class="offers" :key="league">
    <TeamHealth
      :team="team"
      :showReminingBudget="true"
    />
    <OffersMade />
    <OffersReceived />
  </div>
</template>

<script>
import TeamHealth from '@/components/TeamHealth/TeamHealth'
import League from '@/domain/models/League'
import Team from '@/domain/models/Team'
import OffersMade from './OffersMade.vue'
import OffersReceived from './OffersReceived.vue'

export default {
  components: {
    TeamHealth,
    OffersMade,
    OffersReceived
  },
  props: {
    league: League,
    team: Team,
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import 'Offers.sass'
</style>