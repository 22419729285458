<template>
  <div>
    <CreateLeagueForm
      :invertedColor="invertedColor"
      @leagueCreated="leagueCreatedHandler"
      v-if="showCard == 'step1'"
    />
    <BaseCreation
      :invertedColor="invertedColor"
      :fromOnboarding="fromOnboarding"
      @create="createLeague"
      v-if="showCard == 'step2'"
    />
  </div>
</template>

<script>
import { ref, unref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import BaseCreation from './BaseCreation.vue'
import CreateLeagueForm from '@/components/LeagueForms/CreateLeagueForm.vue'
import { LEAGUE_BUDGET } from '@/domain/models/League.js'

export default {
  components: {
    BaseCreation,
    CreateLeagueForm,
  },
  props: {
    invertedColor: {
      type: Boolean,
      default: false
    },
    fromOnboarding: {
      type: Boolean,
      default: false
    },
    creationData: Object,
    startingCard: {
      type: String,
      default: 'step1'
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const sponsors = ref([])
    const showCard = ref(unref(props.startingCard))
    let creationData = unref(props.creationData)

    const leagueCreatedHandler = (form) => {
      creationData = form
      showCard.value = 'step2'
    }

    const createLeague = ({ sponsorId, teamName }) => {
      const data = {
        name: creationData.name,
        duration: creationData.duration,
        maxPlayers: creationData.maxPlayers,
        budget: LEAGUE_BUDGET,
        salaryTable: creationData.salaryTable,
        waitForOther: creationData.waitForOther,
        bidTime: creationData.bidTime,
        openMarketTime: creationData.openMarketTime,
      }
      let startAt = new Date(creationData.startAt);
      const now = new Date();
      if (startAt < now) {
        startAt = now;
      }
      store.dispatch('createUserLeague', data)
        .then((league) => {
          const data = {
            startAt: startAt.toGMTString(),
            leagueId: league.id
          }
          store.dispatch('createSeason', data)
            .then((season) => {
              const data = {
                sponsorId: sponsorId,
                leagueId: league.id,
                seasonId: season.id,
                teamName: teamName
              }
              store.dispatch('createTeam', data)
                .then(() => {
                  store.dispatch('getLeague', league.id)
                    .then(league => {
                      router.push({ name: 'draft', params: { id: league.getId } })
                    })
                })
            })
        })
    }

    return {
      showCard,
      sponsors,

      leagueCreatedHandler,
      createLeague,
    }
  },
}
</script>
