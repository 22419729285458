<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar slot="bottom" class="bottomTabs">
        <ion-tab-button tab="noticias" :href="newsLink" class="tabButton">
          <img src="/assets/home.svg" class="tabButton-icon">
          <div class="tabButton-label">
            <ion-label class="tabButton-label-text">Noticias</ion-label>
            <img src="/assets/blue-point.svg" class="tabButton-label-point"/>
          </div>
        </ion-tab-button>

        <ion-tab-button tab="league" :href="leagueLink" class="tabButton">
          <img src="/assets/league.svg" class="tabButton-icon">
          <div class="tabButton-label">
            <ion-label class="tabButton-label-text">Liga</ion-label>
            <img src="/assets/blue-point.svg" class="tabButton-label-point"/>
          </div>
        </ion-tab-button>

        <ion-tab-button tab="team" :href="teamLink" class="tabButton">
          <img src="/assets/team.svg" class="tabButton-icon">
          <div class="tabButton-label">
            <ion-label class="tabButton-label-text">Equipo</ion-label>
            <img src="/assets/blue-point.svg" class="tabButton-label-point"/>
          </div>
        </ion-tab-button>

        <ion-tab-button tab="market" :href="marketLink" class="tabButton">
          <img src="/assets/market.svg" class="tabButton-icon">
          <div class="tabButton-label">
            <ion-label class="tabButton-label-text">Mercado</ion-label>
            <img src="/assets/blue-point.svg" class="tabButton-label-point"/>
          </div>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { useStore } from 'vuex';
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonPage,
  IonRouterOutlet
} from '@ionic/vue';
import { computed } from '@vue/reactivity';

export default {
  name: 'Tabs',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet
  },
  setup() {
    const store = useStore()
    const newsLink = computed(() => {
      const leagueId = store.getters.getCurrentLeague.id
      return `/tabs/liga/${leagueId}/noticias`
    })
    const leagueLink = computed(() => {
      const leagueId = store.getters.getCurrentLeague.id
      return `/tabs/liga/${leagueId}/liga`
    })
    const teamLink = computed(() => {
      const leagueId = store.getters.getCurrentLeague.id
      return `/tabs/liga/${leagueId}/equipo`
    })
    const marketLink = computed(() => {
      const leagueId = store.getters.getCurrentLeague.id
      return `/tabs/liga/${leagueId}/mercado`
    })

    return {
      newsLink,
      leagueLink,
      teamLink,
      marketLink
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './Tabs.sass'
</style>
