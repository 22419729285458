import { formatSeconds } from "../services/DateFormatter"

export default class PositionBidBatch {
  constructor (data) {
    this.id = data.id,
    this.batchNumber = data.batchNumber
    this.currentDay = data.currentDay
    this.finishInSeconds = data.finishInSeconds
    this.bidBatchPlayers = data.bidBatchPlayers
  }

  get getId () {
    return this.id
  }

  get getBatchNumber () {
    return this.batchNumber
  }

  get getCurrentDay () {
    return this.currentDay
  }

  get getFinishInSeconds () {
    return this.finishInSeconds
  }

  get getFormattedFinishIn () {
    const {days, hours, mins} = formatSeconds(this.getFinishInSeconds)
    const res = []
    if (days > 0) {
      res.push(days + 'd')
    }
    if (hours > 0) {
      res.push(hours + 'h')
    }
    res.push(mins + 'm')
    return res.join(' ')
  }

  get getBidBatchPlayers () {
    return this.bidBatchPlayers
  }

  get getSortedBidBatchPlayers () {
    return this.bidBatchPlayers.sort(this.sort)
  }

  sort (group1, group2) {
    if (group1.getSortIndex > group2.getSortIndex) {
      return 1
    }
    if (group1.getSortIndex < group2.getSortIndex) {
      return -1
    }
    return 0
  }
}
