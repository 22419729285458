import Player from '@/domain/models/Player'

class PlayerFactory {
  get (data) {
    const player = {
      aw: data.aw,
      category: data.category,
      club: data.club,
      def: data.def,
      division: data.division,
      dri: data.dri,
      dw: data.dw,
      id: data.id,
      name: data.name,
      nationality: data.nationality,
      objective: data.objective,
      pac: data.pac,
      pas: data.pas,
      phy: data.phy,
      position: data.position,
      canonicalPosition: data.canonicalPosition,
      rtg: data.rtg,
      salary: data.salary,
      sho: data.sho,
      skill: data.skill,
      terminationClause: data.terminationClause,
      reimbursementAfterSell: data.reimbursementAfterSell,
      wf: data.wf,
      playerPhotoUrl: data.playerPhotoUrl,
      birthDate: data.birthDate,
      clubImageBadge: data.clubImageBadge
    }

    return new Player(player)
  }
}

export default new PlayerFactory()
