import axios from '@/plugins/axios'
import OfferFactory from '@/domain/services/OfferFactory'
import { uuid } from 'vue-uuid'

const URL_CREATE_OFFER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{fromTeamId}/offers'
const URL_GET_MY_OFFERS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/my-offers'
const URL_GET_OFFERS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/offers'
const URL_DELETE_OFFER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/offers/{offerId}'
const URL_DELETE_MY_OFFER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/my-offers/{offerId}'
const URL_ACCEPT_OFFER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/offers/{offerId}/accept'
const URL_REJECT_OFFER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/offers/{offerId}/reject'

export function createOffer(leagueId, seasonId, fromTeamId, toTeamId, playerId, amount) {
  const url = URL_CREATE_OFFER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{fromTeamId}', fromTeamId)
  const data = {
    id: uuid.v4(),
    toTeamId: toTeamId,
    playerId: playerId,
    amount: amount
  }
  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function getMyOffers(leagueId, seasonId, teamId) {
  const url = URL_GET_MY_OFFERS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
  return axios.get(url)
    .then(res => res.data.collection.map(offer => OfferFactory.get(offer)))
    .catch(error => Promise.reject(error.response.data))
}

export function getOffers(leagueId, seasonId, teamId) {
  const url = URL_GET_OFFERS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
  return axios.get(url)
    .then(res => res.data.collection.map(offer => OfferFactory.get(offer)))
    .catch(error => Promise.reject(error.response.data))
}

export function deleteOffer(leagueId, seasonId, teamId, offerId) {
  const url = URL_DELETE_OFFER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{offerId}', offerId)
  return axios.delete(url)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function deleteMyOffer(leagueId, seasonId, teamId, offerId) {
  const url = URL_DELETE_MY_OFFER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{offerId}', offerId)
  return axios.delete(url)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function acceptOffer(leagueId, seasonId, teamId, offerId) {
  const url = URL_ACCEPT_OFFER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{offerId}', offerId)
  return axios.put(url)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function rejectOffer(leagueId, seasonId, teamId, offerId) {
  const url = URL_REJECT_OFFER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{offerId}', offerId)
  return axios.put(url)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}
