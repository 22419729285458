import axios from '@/plugins/axios';
import { uuid } from 'vue-uuid';
import BidFactory from '../domain/services/BidFactory';

const URL_GET_BIDS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/bids'
const URL_CREATE_BID = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/bid-batch/{bidBatchId}/bid-batch-player/{bidBatchPlayerId}'
const URL_UPDATE_BID = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/bid-batch/{bidBatchId}/bid-batch-player/{bidBatchPlayerId}/bid/{bidId}';

export function getBids(leagueId, seasonId, teamId) {
  const url = URL_GET_BIDS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)

  return axios.get(url)
    .then(res => {
      return res.data.collection.map((bid) => {
        return BidFactory.get(bid)
      })
    })
    .catch(error => Promise.reject(error.response.data))
}

export function createBid(leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount) {
  const url = URL_CREATE_BID
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{bidBatchId}', bidBatchId)
    .replace('{bidBatchPlayerId}', bidBatchPlayerId)
  const data = {
    id: uuid.v4(),
    amount: amount
  }
  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function updateBid(bidId, leagueId, seasonId, teamId, bidBatchId, bidBatchPlayerId, amount) {
  const url = URL_UPDATE_BID
    .replace('{bidId}', bidId)
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{bidBatchId}', bidBatchId)
    .replace('{bidBatchPlayerId}', bidBatchPlayerId)
  const data = {
    amount: amount
  }
  return axios.put(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}
