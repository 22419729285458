<template>
  <div class="secondaryButton" @click="onClick">
    <span class="secondaryButton__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    text: String
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './SecondaryButton.sass'
</style>
