<template>
  <div class="smallBadge">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SmallBadge',
}
</script>

<style lang="sass" scoped>
  @import './SmallBadge.sass'
</style>
