<template>
  <div class="invitePeopleToLeague" :class="{ onboarding: fromOnboarding }">
    <div class="invitePeopleToLeague__body">
      <div class="invitePeopleToLeague__title">
        Invita a tus amigos <img src="/assets/stadium.png" alt="fire">
      </div>
      <div class="invitePeopleToLeague__code" @click="copyToClipboard(league.getCode)">
        <div class="invitePeopleToLeague__code__text">
          {{ league.getCode }}
        </div>
      </div>
      <div class="invitePeopleToLeague__text">
        ¡Cuantos más amigos, más divertido!
      </div>
    </div>
    <div class="invitePeopleToLeague__buttons">
      <PrimaryButton text="Compartir" @click="handleShare"/>
      <SecondaryButton v-if="fromOnboarding" text="En otro momento" @click="doNotShareCode" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import { modalController, toastController } from "@ionic/vue"
import { useRouter } from 'vue-router'
import PrimaryButton from "@/components/Button/PrimaryButton"
import SecondaryButton from "@/components/Button/SecondaryButton"

export default {
  name: "InvitePeopleToLeague",
  props: {
    fromOnboarding: {
      type: Boolean,
      default: false
    }
  },
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const router = useRouter()
    const store = useStore()

    const existShareNativeOption = () => {
      return !!navigator.share
    }

    const openToast = async () => {
      const toast = await toastController
        .create({
          message: 'Copiado al portapapeles',
          duration: 2000
        })
      return toast.present();
    }

    const copyToClipboard = (message) => {
      const fakeInput = document.createElement('input')
      fakeInput.value = message

      document.body.appendChild(fakeInput)

      fakeInput.select()
      fakeInput.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand("copy")
      fakeInput.remove()
      openToast()
    }

    const doNotShareCode = () => {
      modalController.dismiss()
      router.push({ name: 'draft', params: { id: league.value.getId } })
    }

    const goBack = () => {
      router.go(-1)
    }

    const handleShare = () => {
      const shareMessage = `Únete a mi Liga en FiFantasy y demuestra tu talento con el código ${league.value.getCode}`

      if(existShareNativeOption()) {
        const shareData = {
          title: 'Únete a mi liga',
          text: shareMessage,
          url: window.location.href,
        }

        navigator.share(shareData)
      } else {
        copyToClipboard(shareMessage)
      }
    }

    const league = computed(() => {
      return store.getters.getCurrentLeague
    })

    return {
      league,
      handleShare,
      existShareNativeOption,
      copyToClipboard,
      openToast,
      doNotShareCode,
      goBack
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./InvitePeopleToLeague.sass"
</style>
