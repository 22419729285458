<template>
  <div>
    <div>
      <Badge @click="showRanking('bestAttack')">Mejor ataque</Badge>
      <Badge @click="showRanking('bestDefense')">Mejor defensa</Badge>
      <Badge @click="showRanking('pichichi')">Pichichi</Badge>
      <Badge @click="showRanking('zamora')">Zamora</Badge>
      <Badge @click="showRanking('mvps')">MVPs</Badge>
    </div>

    <div class="rankings">
      <FullModal
        :openedModal="showSection.bestAttack"
        @onPressBackButton="hideAllRankings"
      >
        <BestAttack />
      </FullModal>
      <FullModal
        :openedModal="showSection.bestDefense"
        @onPressBackButton="hideAllRankings"
      >
        <BestDefense />
      </FullModal>
      <FullModal
        :openedModal="showSection.pichichi"
        @onPressBackButton="hideAllRankings"
      >
        <Pichichi />
      </FullModal>
      <FullModal
        :openedModal="showSection.zamora"
        @onPressBackButton="hideAllRankings"
      >
        <Zamora />
      </FullModal>
      <FullModal
        :openedModal="showSection.mvps"
        @onPressBackButton="hideAllRankings"
      >
        <MVPs />
      </FullModal>
    </div>
  </div>
</template>

<script>

import FullModal from "@/components/FullModal/FullModal.vue";
import Badge from "@/components/Badge/Badge.vue";
import BestAttack from "@/views/Rankings/Sections/BestAttack";
import BestDefense from "@/views/Rankings/Sections/BestDefense";
import Pichichi from "@/views/Rankings/Sections/Pichichi";
import Zamora from "@/views/Rankings/Sections/Zamora";
import MVPs from "@/views/Rankings/Sections/MVPs";
export default  {
  name: 'Rankings',
  components: {
    FullModal,
    Badge,
    BestAttack,
    BestDefense,
    Pichichi,
    Zamora,
    MVPs,
  },
  data () {
    return {
      showButtons: true,
      showSection: {
        bestAttack: false,
        bestDefense: false,
        pichichi: false,
        zamora: false,
        mvps: false,
        palero: false
      }
    }
  },
  methods: {
    showRanking(ranking) {
      this.hideAllRankings()
      this.showSection[ranking] = true
    },
    resetPage() {
      this.hideAllRankings()
    },
    hideAllRankings() {
      for (const section in this.showSection) {
        this.showSection[section] = false
      }
    }
  }
}
</script>

<style lang="sass">
  @import "./Rankings.sass"
</style>
