<template>
  <div class="leagueModal" :class="{ invertedColor: invertedColor }">
    <ion-row>
      <ion-col>
        <Form ref="createLeagueForm" @onSubmit="handleCreateLeague" @keyup.enter="handleCreateLeague">
          <span class="leagueModal__title">Nueva Liga <img src="/assets/info.svg" width="20" @click="openInfo" /></span>
          <div class="leagueModal__body">
            <Field name="leagueName" label="nombre de la liga" v-slot="{ field, meta }" rules="required">
            <Input
              v-model="form.name"
              v-bind="field"
              :valid="!meta.validated || meta.valid"
              placeholder="Nombre de la liga"
              inputType="text"
              :backgroundColor="invertedColor ? 'invertedColor' : ''"
            />
            </Field>
            <ErrorMessage name="leagueName" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br />
            <NumberSelector
              v-model="form.duration"
              :min="1"
              singularSuffix="día"
              pluralSuffix="días"
              :selectedIndex="9"
              :theme="invertedColor ? 'dark' : 'light'"
            >
              Duración
            </NumberSelector>
            <br />
            <NumberSelector
              v-model="form.maxPlayers"
              :min="6"
              :max="12"
              :selectedIndex="2"
              :theme="invertedColor ? 'dark' : 'light'"
            >
              Número máximo de jugadores
            </NumberSelector>
            <br />
            <BaseSelector
              v-model="form.salaryTable"
              :options="salaryTableOptions"
              :selectedIndex="1"
              :theme="invertedColor ? 'dark' : 'light'"
            >
              Masa salarial máxima
            </BaseSelector>
            <br />
            <BaseSelector
              v-model="form.mode"
              :options="modeOptions"
              :selectedIndex="0"
              :theme="invertedColor ? 'dark' : 'light'"
              @changed="setOptions"
            >
              Tipo de liga
            </BaseSelector>
            <ion-row
              class="leagueModal__custom-times"
              :class="{ 'leagueModal__custom-times--shown': form.mode == 'custom' }"
            >
              <ion-col size="4" size-sm="4">
                <ion-label class="leagueModal__label ion-hide-sm-up">⏰ Draft</ion-label>
                <ion-label class="leagueModal__label ion-hide-md-down">Tiempo para draft</ion-label>
                <BaseSelector
                  v-model="form.waitForOther"
                  :options="waitForOtherOptions"
                  :selectedIndex="0"
                  :theme="invertedColor ? 'dark' : 'light'"
                />
              </ion-col>
              <ion-col size="4" size-sm="4">
                <ion-label class="leagueModal__label ion-hide-sm-up">⏰ Pujas</ion-label>
                <ion-label class="leagueModal__label ion-hide-md-down">Tiempo para pujas</ion-label>
                <BaseSelector
                  v-model="form.bidTime"
                  :options="bidTimeOptions"
                  :selectedIndex="0"
                  :theme="invertedColor ? 'dark' : 'light'"
                />
              </ion-col>
              <ion-col size="4" size-sm="4">
                <ion-label class="leagueModal__label ion-hide-sm-up">⏰ Mer. libre</ion-label>
                <ion-label class="leagueModal__label ion-hide-md-down">Tiempo para Mer. libre</ion-label>
                <BaseSelector
                  v-model="form.openMarketTime"
                  :options="openMarketTimeOptions"
                  :selectedIndex="0"
                  :theme="invertedColor ? 'dark' : 'light'"
                />
              </ion-col>
            </ion-row>
            <ion-item class="leagueModal__dateSelector" :class="{ invertedColor: invertedColor }" lines="none">
              <ion-label class="leagueModal__dateSelector__title">Fecha de inicio</ion-label>
              <ion-datetime
                display-format="DD/MM/YYYY"
                v-model="form.startAt"
                :min="form.startAt"
                :max="form.maxStartAt"
              />
            </ion-item>
          </div>
          <div class="leagueModal__createButton">
            <PrimaryButton text="Crear Liga" @onClick="handleCreateLeague"/>
          </div>
        </Form>
        <PopupModal
          :is-open="openInfoModal"
          @closed="hideInfoModal"
        >
          <div class="popupModal__standard">
            <div>
              Lo más importante es que elijas la dificultad que desees entre las opciones de la
              masa salarial que ofrecemos. 45M es la opción más difícil ya que obligará a ajustar
              muy bien la plantilla.
            </div>
            <div>
              <SmallButton text="Cerrar" @onClick="hideInfoModal" />
            </div>
          </div>
        </PopupModal>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import { IonRow, IonCol, IonDatetime, IonLabel, IonItem } from '@ionic/vue'
import Form from "@/components/Input/Form"
import Input from "@/components/Input/Input";
import PrimaryButton from "../Button/PrimaryButton";
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"
import NumberSelector from '@/components/Selector/NumberSelector'
import BaseSelector from '@/components/Selector/BaseSelector'
import PopupModal from '../PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'


export default {
  name: "RegisterForm",
  components: {
    PrimaryButton,
    IonRow,
    IonCol,
    Form,
    Input,
    IonDatetime,
    IonLabel,
    IonItem,
    Field,
    ErrorMessage,
    NumberSelector,
    BaseSelector,
    PopupModal,
    SmallButton
  },
  props: {
    invertedColor: {
      type: Boolean,
      default: true
    }
  },
  emits: ['leagueCreated'],
  setup(_, { emit }) {
    let startLeague = new Date()
    let maxStartLeague = new Date()
    const createLeagueForm = ref()
    const openInfoModal = ref(false)

    startLeague.setDate(
      startLeague.getDate() + parseInt(process.env.VUE_APP_DAYS_TO_START_LEAGUE)
    )
    maxStartLeague.setDate(
      startLeague.getDate() + 365
    )
    const expressTimes = {
      waitForOther: 360,
      bidTime: 720,
      openMarketTime: 300,
    }
    const form = ref({
        name: '',
        duration: 10,
        maxPlayers: 8,
        budget: 150000000,
        salaryTable: 50000000,
        startAt: startLeague.toISOString(),
        mode: 'express',
        waitForOther: expressTimes.waitForOther,
        bidTime: expressTimes.bidTime,
        openMarketTime: expressTimes.openMarketTime,
        maxStartAt: maxStartLeague.toISOString()
    })
    const handleCreateLeague = () => {
      createLeagueForm.value.validate()
      .then(({ valid }) => {
        if (!valid) {
          return
        }

        form.value.startAt = (new Date(form.value.startAt)).toGMTString()

        emit('leagueCreated', form.value)
      })
    }

    const setOptions = (type) => {
      if (type == 'express') {
        form.value.bidTime = expressTimes.bidTime
        form.value.openMarketTime = expressTimes.openMarketTime
        form.value.waitForOther = expressTimes.waitForOther
      }
    }

    const salaryTableOptions = [
      {text: 'Modo Chill 55M', value: 55000000},
      {text: 'Modo Recomendado 50M', value: 50000000},
      {text: 'Modo Tryhard 45M', value: 45000000}
    ]

    const modeOptions = [
      {text: 'Modo express', value: 'express'},
      {text: 'Modo personalizado', value: 'custom'},
    ]

    const waitForOtherOptions = [
      {text: '6 mins.', value: 360},
      {text: '30 mins.', value: 1800},
      {text: '1 hora', value: 3600},
      {text: '6 horas', value: 21600},
    ]

    const bidTimeOptions = [
      {text: '12 mins.', value: 720},
      {text: '9 horas', value: 9 * 3600},
      {text: '16 horas', value: 16 * 3600},
      {text: '1 día', value: 86400},
    ]

    const openMarketTimeOptions = [
      {text: '5 mins.', value: 300},
      {text: '8 horas', value: 8 * 3600},
      {text: '18 horas', value: 18 * 3600},
      {text: '1 día', value: 86400},
    ]

    defineRule("required", required)

    const openInfo = () => {
      openInfoModal.value = true
    }

    const hideInfoModal = () => {
      openInfoModal.value = false
    }

    return {
      form,
      salaryTableOptions,
      modeOptions,
      waitForOtherOptions,
      bidTimeOptions,
      openMarketTimeOptions,
      createLeagueForm,
      openInfoModal,

      required,
      handleCreateLeague,
      setOptions,
      hideInfoModal,
      openInfo
    }
  }
};
</script>

<style lang="sass" scoped>
  @import './LeagueForms.sass'
</style>
