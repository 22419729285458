<template>
  <div class="bidElement">
    <PlayerInfoCard :player="player">
      <SmallButton
        v-if="canBid"
        :text="label"
        v-on:click.stop="showModal = true"
      />
      <template v-slot:subtitle>
        <div class="bidNumber" v-if="bidsCount > 0">
          {{ bidsCount }}
          <span v-if="bidsCount > 1">pujas</span>
          <span v-else>puja</span>
        </div>
      </template>
    </PlayerInfoCard>
    <PopupModal
      :isOpen="showModal"
      @closed="closed"
    >
      <BidForm
        :player="player"
        :budget="budget"
        :minAmount="player.getTerminationClause"
        @bid="bid"
      />
    </PopupModal>
  </div>
</template>

<script>
import PlayerInfoCard from '@/components/PlayerInfoCard/PlayerInfoCard.vue';
import SmallButton from '@/components/Button/SmallButton.vue';
import PopupModal from '@/components/PopupModal/PopupModal.vue';
import Player from '@/domain/models/Player.js';
import { ref } from '@vue/reactivity';
import BidForm from './BidForm.vue';
import { useStore } from 'vuex';

export default {
  name: 'BidElement',
  props: {
    player: Player,
    bidBatchId: String,
    bidBatchPlayerId: String,
    budget: Number,
    bidsCount: Number,
    label: {
      type: String,
      default: 'Pujar'
    },
    canBid: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PlayerInfoCard,
    SmallButton,
    PopupModal,
    BidForm,
  },
  emits: ['bid'],
  setup(props, { emit }) {
    const showModal = ref(false)
    const store = useStore()
    const league = store.getters.getCurrentLeague
    const team = store.getters.getCurrentTeam

    const closed = () => {
      showModal.value = false
    }

    const bid = (amount) => {
      store.commit('showLockScreen')
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        bidBatchId: props.bidBatchId,
        bidBatchPlayerId: props.bidBatchPlayerId,
        amount: amount
      }
      store.dispatch('createBid', data)
        .then(() => {
          showModal.value = false
          emit('bid', amount)
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    return {
      showModal,

      bid,
      closed
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'BidElement.sass'
</style>