<template>
  <FullModal>
    <template v-slot:back>
      <div
        class="modalContent__fixed"
        :style="'background-image: url(/assets/' + backgroundImage + ')'"
        slot="fixed"
      >
        <BackButton @onClick="$emit('onPressBackButton')" />
      </div>
    </template>
    <div class="contents">
      <slot></slot>
    </div>
  </FullModal>
</template>

<script>
import BackButton from '../Menu/BackButton.vue'
import FullModal from './FullModal.vue'
export default {
  components: {
    FullModal,
    BackButton
  },
  props: {
    backgroundImage: {
      type: String,
      default: null
    }
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import './FullModalImage.sass'
</style>