import axios from '@/plugins/axios'
import BotNewsFactory from '@/domain/services/BotNewsFactory'

const URL_GET_LEAGUE_BOT_NEWS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/bot-news'
const URL_GET_LEAGUE_USER_TRANSACTIONS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/transactions'

export function getBotNews(leagueId, seasonId) {
  const url = URL_GET_LEAGUE_BOT_NEWS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  return axios.get(url)
    .then(res => {
      return res.data.collection.map(botNews => BotNewsFactory.get(botNews))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getTransactions(leagueId, seasonId) {
  const url = URL_GET_LEAGUE_USER_TRANSACTIONS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  return axios.get(url)
    .then(res => {
      return res.data.collection.map(transaction => BotNewsFactory.get(transaction))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
