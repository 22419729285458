<template>
  <ion-page class="ion-page header">
    <ion-header class="ion-no-border">
      <MenuHeaderWithInfo
        class="menu__button"
      />
      <NavBar
        :segments="segments"
        :activeTab="activeTab"
        @changeActiveTab="changeActiveTab"
        class="navBar"
      />
    </ion-header>
    <ion-content scroll-x>
      <div class="tabContent">
        <Squad
          v-if="activeTab === segments[0].key"
          :team="team"
          :canSellPlayers="canSellPlayers"
        />
        <Finances
          v-if="activeTab === segments[1].key"
          :team="team"
        />
        <Offers
          v-if="activeTab === segments[2].key"
          :league="league"
          :team="team"
        />
        <Results
          v-if="activeTab === segments[3].key"
        />
        <UserSponsor
          v-if="activeTab === segments[4].key && team.getSponsor"
          :sponsor="team.getSponsor"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, ref } from 'vue';
import { IonPage, IonContent, IonHeader, onIonViewDidEnter } from '@ionic/vue';
import NavBar from "@/components/NavBar/NavBar";
import MenuHeaderWithInfo from "@/components/Menu/MenuHeaderWithInfo";
import Squad from "@/views/Tabs/Team/Squad";
import Finances from "@/views/Tabs/Team/Finances";
import Results from "@/views/Tabs/Team/Results";
import { useStore } from 'vuex';
import Offers from '@/views/Tabs/Team/Offers';
import UserSponsor from '@/components/UserSponsor/UserSponsor.vue';
import { useRoute } from 'vue-router';

export default  {
  name: 'TabTeam',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    MenuHeaderWithInfo,
    NavBar,
    Squad,
    Finances,
    Results,
    Offers,
    UserSponsor,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const segments = [
      { key: 'squad', label: 'Plantilla' },
      { key: 'finances', label: 'Finanzas' },
      { key: 'offers', label: 'Ofertas' },
      { key: 'results', label: 'Resultados' },
      { key: 'sponsor', label: 'Patrocinador' }
    ]
    const activeTab = ref('squad')
    const sponsor = ref(null)

    onIonViewDidEnter(() => {
      if (route.hash == '#sponsor') {
        activeTab.value = 'sponsor'
      }
    })

    const shownResultWindow = ref(false)
    const team = computed(() => store.getters.getCurrentTeam)
    const league = computed(() => store.getters.getCurrentLeague)
    const canSellPlayers = computed(() => league.value && league.value.getSeason.canSellPlayers)

    const changeActiveTab = (tabToBeSet) => {
      activeTab.value = tabToBeSet
    }
    const toggleResultWindow = () => {
      shownResultWindow.value = !shownResultWindow.value
    }

    return {
      segments,
      activeTab,
      shownResultWindow,
      league,
      team,
      canSellPlayers,
      sponsor,

      changeActiveTab,
      toggleResultWindow,
    }
  }
}
</script>

<style lang="sass" scoped>
 @import './TabTeam.sass'
</style>
