import Result from "@/domain/models/Result"
import TeamFactory from "@/domain/services/TeamFactory"
import ScorerFactory from "@/domain/services/ScorerFactory"
import Player from "@/domain/models/Player"

class ResultFactory {
  get (data) {
    const result = {
      id: data.id,
      homeTeam: TeamFactory.get(data.homeTeam),
      visitorTeam: TeamFactory.get(data.visitorTeam),
      homeResult: data.homeResult,
      visitorResult: data.visitorResult,
      homeYellowCards: data.homeYellowCards,
      visitorYellowCards: data.visitorYellowCards,
      homeRedCards: data.homeRedCards,
      visitorRedCards: data.visitorRedCards,
      scorers: createScorersIterator(data.scorers),
      mvp: createPlayer(data.mvp),
      mvpHome: data.mvpHome,
      matchDate: data.matchDate
    }

    return new Result(result)
  }
}

function createPlayer(player) {
  if (player === null) {
    return null
  }

  return new Player(player)
}

function createScorersIterator(scorers) {
  return scorers.map(scorer => ScorerFactory.get(scorer))
}

export default new ResultFactory()
