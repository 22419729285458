<template>
  <ion-page>
    <ion-content>
      <BasicViewWithEllipsesBackground :description="description">
        <template v-slot:title>
          <div class="selectAuth__title">
            Demuestra tu talento
          </div>
        </template>

        <template v-slot:footerButtons>
          <div class="selectAuth__buttons">
            <PrimaryButton
              text="Soy nuevo"
              @click="openModal('registerModal')"
            />
            <SecondaryButton
              text="Ya tengo cuenta"
              @click="openModal('loginModal')"
            />
          </div>

          <CustomModal
            cssClass="register"
            :openedModal="registerModal"
            @triggerModal="closeModal('registerModal')"
          >
            <RegisterForm
              @closeRegisterOpenLogin="closeRegisterOpenLogin"
              @closeModal="closeModal('registerModal')"
            />
          </CustomModal>

          <CustomModal
            :openedModal="loginModal"
            @triggerModal="closeModal('loginModal')"
          >
            <LoginForm
              @closeLoginOpenRegister="closeLoginOpenRegister"
              @closeLoginOpenForgotPassword="closeLoginOpenForgotPassword"
              @closeModal="closeModal('loginModal')"
            />
          </CustomModal>

          <FullModal
            :openedModal="forgotPasswordModal"
            @onPressBackButton="closeModal('forgotPasswordModal')"
          >
            <ForgotPasswordForm />
          </FullModal>
        </template>
      </BasicViewWithEllipsesBackground>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage} from '@ionic/vue'
import RegisterForm from "../../components/Auth/RegisterForm";
import LoginForm from "../../components/Auth/LoginForm";
import CustomModal from "../../components/CustomModal/CustomModal";
import PrimaryButton from "../../components/Button/PrimaryButton"
import SecondaryButton from "../../components/Button/SecondaryButton"
import ForgotPasswordForm from "../../components/Auth/ForgotPasswordForm";
import BasicViewWithEllipsesBackground from "../../components/BasicViewWithEllipsesBackground/BasicViewWithEllipsesBackground";
import FullModal from '@/components/FullModal/FullModal.vue';

export default {
  name: "SelectAuth",
  components: {
    BasicViewWithEllipsesBackground,
    ForgotPasswordForm,
    SecondaryButton,
    PrimaryButton,
    LoginForm,
    RegisterForm,
    CustomModal,
    IonPage,
    IonContent,
    FullModal
  },
  data() {
    return {
      registerModal: false,
      loginModal: false,
      forgotPasswordModal: false,
      description: '¿Quieres sacar el máximo rendimiento a tu FIFA? ¿Quieres competir en igualdad de condiciones con tus amigos? ¿Quieres demostrar tus habilidades sin necesidad de sacar dinero de tu bolsillo? Haz clic aquí 👇👇👇👇'
    };
  },
  methods: {
    openModal(variableName) {
      this[variableName] = true
    },
    closeModal(variableName) {
      this[variableName] = false
    },
    closeRegisterOpenLogin() {
      this.registerModal = false
      this.loginModal = true
    },
    closeLoginOpenRegister() {
      this.loginModal = false
      this.registerModal = true
    },
    closeLoginOpenForgotPassword() {
      this.loginModal = false
      this.forgotPasswordModal = true
    },
  }
};
</script>

<style lang="sass" scoped>
@import './SelectAuth.sass'
</style>
