import axios from 'axios';
import { store } from '@/store'
import JWTMessages from '@/plugins/jwt-messages'
import router from '@/router/index'

const customAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

customAxios.interceptors.request.use(
  config => {
    if (store.getters.hasUserLoggedIn) {
      const token = store.getters.getToken
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  },
  error => Promise.reject(error)
)

const UNAUTHORIZED = 401
customAxios.interceptors.response.use(
  res => res,
  error => {
    const responseStatus = error.response.data.code
    const responseMessage = error.response.data.message
    const expiredJWT = responseStatus === UNAUTHORIZED && responseMessage === JWTMessages.expiredJWT

    if (responseStatus !== UNAUTHORIZED) {
      return new Promise((_, reject) => {
        reject(error)
      })
    }

    if (expiredJWT) {
      store.dispatch('refreshToken')
        .then(() => {
          console.log(router.currentRoute.value.path)
          router.go()
          return Promise.resolve()
        })
        .catch(error => {
          console.log(error)
          router.push({ name: 'selectAuth' })
          return Promise.reject(error)
        })
    }

    return Promise.reject(error)
  }
)


export default customAxios
