import User from "@/domain/models/User"

class UserFactory {
  get (data) {
    const user = {
      id: data.id,
      email: data.email,
      fifaId: data.fifaId,
      username: data.username,
      avatar: processAvatar(data.avatar)
    }

    return new User(user)
  }
}

function processAvatar(avatar) {
  if (!avatar) {
    return '/assets/avatar-default.svg'
  }
  return avatar
}

export default new UserFactory()
