<template>
  <div :class="classNames">
    <ion-input
      :value="modelValue"
      :type="inputType"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
import { IonInput } from '@ionic/vue';

export default {
  name: "Input",
  components: { IonInput },
  emits: ["update:modelValue"],
  props: {
    placeholder: String,
    inputType: {
      type: String,
      default: 'text'
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    valid: {
      type: Boolean,
      default: true
    },
    modelValue: String,
  },
  computed: {
    classNames () {
      let hasError = '';
      if (!this.valid) {
        hasError = 'has-error'
      }
      return `customInput ${this.backgroundColor} ${hasError}`
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './Input.sass'
</style>
