<template>
  <div>
    <ion-row>
      <ion-col v-if="!recoveryEmailSent">
        <span class="auth__form__title">Recuperar contraseña</span>
        <div class="auth__form__body">
          <VForm ref="emailForm" @onSubmit="handleForgotPassword">
            <VField name="email" label="correo electrónico" v-slot="{ field, meta }" rules="required|email">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Email"
                  inputType="email"
                  @change="onChangeEmail"
              />
            </VField>
            <VErrorMessage name="email" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </VErrorMessage>
            <br/>
            <PrimaryButton text="Recuperar contraseña" @onClick="handleForgotPassword"/>
          </VForm>
        </div>
      </ion-col>
      <ion-col v-if="recoveryEmailSent">
        <span class="auth__form__title">Correo electrónico enviado</span>
        <div class="auth__form__body ion-padding-bottom">
          Te hemos enviado un correo electrónico con las instrucciones de cómo resetear tu contraseña
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue'
import { ref } from 'vue'
import { useStore } from 'vuex'
import Input from "../Input/Input";
import Form from "../Input/Form";
import PrimaryButton from "../Button/PrimaryButton";
import { Field, ErrorMessage, defineRule } from "vee-validate";
import { email as emailValidator, required } from "@vee-validate/rules";

export default {
  name: "ForgotPasswordForm",
  components: {
    PrimaryButton,
    Input,
    IonRow,
    IonCol,
    VField: Field,
    VForm: Form,
    VErrorMessage: ErrorMessage
  },
  setup() {
    const email = ref('')
    const emailForm = ref()
    const recoveryEmailSent = ref(false)

    const store = useStore()

    defineRule("email", emailValidator)
    defineRule("required", required)

    const onChangeEmail = (event) => {
      email.value = event.target.value
    }

    const handleForgotPassword = () => {
      emailForm.value.validate()
      .then(({ valid }) => {
        if (!valid) {
          return
        }

        store.commit('showLockScreen')
        store.dispatch('recoveryUserPassword', email.value)
          .catch(() => {})
          .finally(() => {
            store.commit('hideLockScreen')
            recoveryEmailSent.value = true
        })
      })
    }

    return {
      email,
      recoveryEmailSent,

      emailForm,

      emailValidator,
      required,

      onChangeEmail,
      handleForgotPassword
    }
  },
};
</script>

<style lang="sass" scoped>
  @import './Auth.sass'
</style>
