import { longDateTime } from '@/domain/services/DateFormatter'

export default class News {
  constructor (data) {
    this.id = data.id
    this.message = data.message
    this.createdAt = data.createdAt
    this.author = data.author
  }

  get getId () {
    return this.id
  }

  get getMessage () {
    return this.message
  }

  get getCreatedAt () {
    return new Date(this.createdAt)
  }

  get getFormattedCreatedAt () {
    return longDateTime(new Date(this.createdAt))
  }

  get getAuthor () {
    return this.author
  }

  get getCardName () {
    return 'RegularFeedCard'
  }
}
