import axios from '@/plugins/axios'
import NewsFactory from '@/domain/services/NewsFactory'
import { uuid } from 'vue-uuid'

const URL_GET_LEAGUE_NEWS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/news'
const URL_CREATE_LEAGUE_NEWS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/news'

export function getNews(leagueId, seasonId) {
  const url = URL_GET_LEAGUE_NEWS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  return axios.get(url)
    .then(res => {
      return res.data.collection.map(news => NewsFactory.get(news))
    })
    .catch(error => Promise.reject(error.response.data))
}

export function createNews(leagueId, seasonId, message) {
  const url = URL_CREATE_LEAGUE_NEWS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  const data = {
    id: uuid.v4(),
    message: message
  }
  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}