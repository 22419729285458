<template>
  <ion-page class="enterResult">
    <ion-content scroll-x>
      <ResultCard
        :id="id"
        :homeAvatar="homeAvatar"
        :homeName="homeName"
        :visitorAvatar="visitorAvatar"
        :visitorName="visitorName"
      />
      <Form ref="resultForm" @keyup.enter="saveResults">
        <div class="result__form">
          <ion-row>
            <ion-col class="result__form__elem">
              <Field :value="formData.matchDate ? formData.matchDate.toISOString() : null" name="matchDate" label="la fecha del partido" v-slot="{ field, meta }" rules="required">
                <div
                  class="elem elem-caret elem-datetime"
                  :class="{
                    'has-error': meta.validated && !meta.valid,
                    filled: formData.matchDate !== null
                  }">
                  <ion-datetime
                    display-format="DD MMM YYYY"
                    v-bind="field"
                    :value="formData.matchDate ? formData.matchDate.toISOString() : null"

                    placeholder="Fecha del partido"
                    :max="today"
                    @ionChange="setMatchDate"
                  />
                </div>
              </Field>
              <ErrorMessage name="matchDate" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.homeResult" name="homeGoals" label="goles del equipo local" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.homeResult"
                    :selectedIndex="formData.homeResult"
                  >
                    Goles del equipo local
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="homeGoals" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field
                v-model="formData.homeScorers"
                name="homeScorers"
                label="goleadores del equipo local"
                v-slot="{ field, meta }"
                rules="goalComparator:homeResult"
              >
                <ScorerSelector
                  :valid="!meta.validated || meta.valid"
                  v-bind="field"
                  :primaryTeam="primaryTeam"
                  :secondaryTeam="secondaryTeam"
                  :home="true"
                  :disabled="formData.homeResult == 0"
                  @append="appendHomeScorers"
                  @remove="removeHomeScorers"
                >
                  Goleadores del equipo local
                </ScorerSelector>
              </Field>
              <ErrorMessage name="homeScorers" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.visitorResult" name="visitorGoals" label="goles del equipo visitante" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.visitorResult"
                    :selectedIndex="formData.visitorResult"
                  >
                    Goles del equipo visitante
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="visitorGoals" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field
                v-model="formData.visitorScorers"
                name="visitorScorers"
                label="goleadores del equipo visitante"
                v-slot="{ field, meta }"
                rules="goalComparator:visitorResult"
              >
                <ScorerSelector
                  :valid="!meta.validated || meta.valid"
                  v-bind="field"
                  :primaryTeam="secondaryTeam"
                  :home="false"
                  :secondaryTeam="primaryTeam"
                  :disabled="formData.visitorResult == 0"
                  @append="appendVisitorScorers"
                  @remove="removeVisitorScorers"
                >
                  Goleadores del equipo visitante
                </ScorerSelector>
              </Field>
              <ErrorMessage name="visitorScorers" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.homeYellowCards" name="homeYellowCards" label="tarjetas amarillas" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.homeYellowCards"
                    :selectedIndex="formData.homeYellowCards"
                  >
                    Tarjetas amarillas del equipo local
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="homeYellowCards" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.visitorYellowCards" name="visitorYellowCards" label="tarjetas amarillas" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.visitorYellowCards"
                    :selectedIndex="formData.visitorYellowCards"
                  >
                    Tarjetas amarillas del equipo visitante
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="visitorYellowCards" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.homeRedCards" name="homeRedCards" label="tarjetas rojas" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.homeRedCards"
                    :selectedIndex="formData.homeRedCards"
                  >
                    Tarjetas rojas del equipo local
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="homeRedCards" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field v-model="formData.visitorRedCards" name="visitorRedCards" label="tarjetas rojas" v-slot="{ field, meta }" rules="required">
                <div class="elem" :class="{ 'has-error': meta.validated && !meta.valid }">
                  <NumberSelector
                    v-bind="field"
                    v-model="formData.visitorRedCards"
                    :selectedIndex="formData.visitorRedCards"
                  >
                    Tarjetas rojas del equipo visitante
                  </NumberSelector>
                </div>
              </Field>
              <ErrorMessage name="visitorRedCards" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="result__form__elem">
              <Field
                v-model="formData.mvp"
                name="mvp"
                label="MVP del partido"
                v-slot="{ field, meta }"
                rules="required"
              >
                <ScorerSelector
                  :valid="!meta.validated || meta.valid"
                  v-bind="field"
                  :primaryTeam="primaryTeam"
                  :secondaryTeam="secondaryTeam"
                  primaryTeamSufix=' (local)'
                  secondaryTeamSufix=' (visitante)'
                  @append="appendMvp"
                  @remove="removeMvp"
                >
                  MVP del partido
                </ScorerSelector>
              </Field>
              <ErrorMessage name="mvp" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </ion-col>
          </ion-row>
        </div>
      </Form>
      <div class="button" slot="fixed">
        <ion-button expand="full" @click="saveResults">
          <span class="start" part="start">Guardar resultado</span>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import {
  IonPage,
  IonContent,
  IonDatetime,
  IonButton,
  IonRow,
  IonCol,
} from '@ionic/vue'
import ResultCard from '@/components/ResultCard/ResultCard'
import Form from "@/components/Input/Form"
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"
import NumberSelector from '@/components/Selector/NumberSelector'
import ScorerSelector from '@/components/Selector/ScorerSelector'
import Result from '@/domain/models/Result'

export default {
  name: 'EnterResult',
  components: {
    ResultCard,
    NumberSelector,
    ScorerSelector,
    IonPage,
    IonContent,
    IonButton,
    IonDatetime,
    IonRow,
    IonCol,
    Form,
    Field,
    ErrorMessage,
  },
  emits: ['updated'],
  props: {
    id: String,
    homeTeamId: String,
    visitorTeamId: String,
    homeAvatar: String,
    homeName: String,
    visitorAvatar: String,
    visitorName: String,
    result: Result,
  },
  setup(props, { emit }) {
    const store = useStore()
    const league = store.getters.getCurrentLeague
    const primaryTeam = league.getSeason.getTeams.find(team => team.getId === props.homeTeamId)
    const secondaryTeam = league.getSeason.getTeams.find(team => team.getId === props.visitorTeamId)

    const formData = reactive({
      id: props.id,
      matchDate: props.result ? props.result.getMatchDate : null,
      homeResult: props.result ? props.result.getHomeResult : null,
      homeScorers: props.result ? props.result.getHomeSmallScorers : [],
      homeYellowCards: props.result ? props.result.getHomeYellowCards : null,
      homeRedCards: props.result ? props.result.getHomeRedCards : null,
      visitorResult: props.result ? props.result.getVisitorResult : null,
      visitorScorers: props.result ? props.result.getVisitorSmallScorers : [],
      visitorYellowCards: props.result ? props.result.getVisitorYellowCards : null,
      visitorRedCards: props.result ? props.result.getVisitorRedCards : null,
      mvp: props.result ? props.result.getMvpSmall : [],
    })

    const resultForm = ref()

    const appendHomeScorers = (scorer) => {
      formData.homeScorers.push(scorer)
    }
    const removeHomeScorers = (playerId) => {
      formData.homeScorers = formData.homeScorers.filter(homeScorer => {
        return homeScorer.playerId != playerId
      })
    }
    const appendVisitorScorers = (scorer) => {
      formData.visitorScorers.push(scorer)
    }
    const removeVisitorScorers = (playerId) => {
      formData.visitorScorers = formData.visitorScorers.filter(visitorScorer => {
        return visitorScorer.playerId != playerId
      })
    }
    const appendMvp = (scorer) => {
      formData.mvp = [scorer]
    }
    const removeMvp = () => {
      formData.mvp = []
    }

    const saveResults = () => {
      const scorers = []
      formData.homeScorers
      .concat(formData.visitorScorers)
      .forEach((scorer) => {
        const index = scorers.findIndex(player => {
          return player.playerId == scorer.playerId && player.home == scorer.home && player.ownGoal == scorer.ownGoal
        })
        if (index >= 0) {
          scorers[index].goals++
        } else {
          scorers.push(scorer)
        }
      })

      resultForm.value.validate()
      .then (valid => {
        if (!valid.valid) {
          return
        }

        const updatedResult = {
          matchDate: formData.matchDate.toGMTString(),
          homeResult: formData.homeResult,
          visitorResult: formData.visitorResult,
          homeYellowCards: formData.homeYellowCards,
          visitorYellowCards: formData.visitorYellowCards,
          homeRedCards: formData.homeRedCards,
          visitorRedCards: formData.visitorRedCards,
          mvp: formData.mvp[0].playerId,
          mvpHome: primaryTeam.getTeamPlayers.map(teamPlayer => teamPlayer.getPlayer).some((player) => player.getId == formData.mvp[0].playerId)
        }

        emit('updated', updatedResult, scorers);
      })
    }

    const today = (new Date()).toISOString()

    const setMatchDate = (data) => {
      formData.matchDate = new Date(data.detail.value)
    }

    defineRule("required", required)
    defineRule("goalComparator", (scorers, [ goalsScoredField ]) => {
      if (formData[goalsScoredField] === null) {
        return true
      }
      if (scorers.length != formData[goalsScoredField]) {
        return 'No coincide la cantidad de goles con los jugadores que han marcado'
      }
      return true
    })

    return {
      today,
      formData,

      setMatchDate,
      primaryTeam,
      secondaryTeam,

      appendHomeScorers,
      removeHomeScorers,
      appendVisitorScorers,
      removeVisitorScorers,
      appendMvp,
      removeMvp,

      saveResults,

      resultForm,

      required,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'EnterResult.sass'
</style>
