<template>
  <div class="ellipsesBackground">
    <img class="ellipse ellipse__primary__first" src="/assets/white-ellipse.png" alt="white ellipse"/>
    <img class="ellipse ellipse__secondary" src="/assets/blue-ellipse.png" alt="blue ellipse"/>
    <img class="ellipse ellipse__primary__second" src="/assets/white-ellipse.png" alt="white ellipse"/>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "EllipsesBackground"
}
</script>

<style lang="sass" scoped>
  @import 'EllipsesBackground.sass'
</style>
