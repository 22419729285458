<template>
  <ion-app class="App">
    <Menu />
    <ion-router-outlet id="main"/>
    <LockScreen :is-open="show" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import Menu from "@/components/Menu/Menu.vue";
import LockScreen from "@/components/LockScreen/LockScreen.vue";

export default defineComponent({
  name: 'App',
  components: {
    Menu,
    IonApp,
    IonRouterOutlet,
    LockScreen
  },
  computed: {
    show() {
      return this.$store.getters.showLockScreen
    }
  }
});
</script>

<style lang="sass" scoped>
  @import './App.sass'
</style>
