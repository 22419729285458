<template>
  <div class="regularCard">
    <InitialsAvatar mode="dark" size="large">
      <img src="/assets/lock.svg" height="70" />
    </InitialsAvatar>
    <div class="regularCard__message">
      Oops!<br>
      El mercado está cerrado
    </div>
  </div>
</template>

<script>
import InitialsAvatar from '@/components/InitialsAvatar/InitialsAvatar'

export default {
  components: {
    InitialsAvatar
  },
  setup() {

  },
}
</script>

<style lang="scss" scoped>
  @import 'RegularCard.sass'
</style>