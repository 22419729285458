<template>
  <div class="big-button" @click="$emit('onClick')">
    {{ text }}
  </div>
</template>

<script>

export default {
  name: "BigButton",
  props: {
    text: String
  }
};
</script>

<style lang="sass" scoped>
  @import './BigButton.sass'
</style>
