<template>
  <div class="bidElement">
    <PlayerInfoCard :player="bid.getBidBatchPlayer.getPlayer">
      <SmallButton
        v-if="canBid"
        :text="label"
        v-on:click.stop="showModal = true"
      />
      <template v-slot:subtitle>
        <div class="bidNumber" v-if="bid.getBidBatchPlayer.getBidsCount > 0">
          {{ bid.getBidBatchPlayer.getBidsCount }}
          <span v-if="bid.getBidBatchPlayer.getBidsCount > 1">pujas</span>
          <span v-else>puja</span>
        </div>
      </template>
    </PlayerInfoCard>
    <PopupModal
      :isOpen="showModal"
      @closed="closed"
    >
      <BidForm
        :player="bid.getBidBatchPlayer.getPlayer"
        :budget="team.getBudget + bid.getAmount"
        :minAmount="bid.getAmount"
        :canBidMin="false"
        @bid="placeBid"
      />
    </PopupModal>
  </div>
</template>

<script>
import PlayerInfoCard from '@/components/PlayerInfoCard/PlayerInfoCard'
import SmallButton from '@/components/Button/SmallButton'
import PopupModal from '@/components/PopupModal/PopupModal'
import Bid from '@/domain/models/Bid'
import { ref } from '@vue/reactivity'
import BidForm from './BidForm'
import { useStore } from 'vuex'

export default {
  name: 'UpdateBidElement',
  props: {
    bid: Bid,
    bidBatchId: String,
    label: {
      type: String,
      default: 'Subir puja'
    },
    canBid: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PlayerInfoCard,
    SmallButton,
    PopupModal,
    BidForm,
  },
  emits: ['bid'],
  setup(props, { emit }) {
    const showModal = ref(false)
    const store = useStore()
    const league = store.getters.getCurrentLeague
    const team = store.getters.getCurrentTeam

    const closed = () => {
      showModal.value = false
    }

    const placeBid = (amount) => {
      const diffAmount = amount - props.bid.getAmount
      store.commit('showLockScreen')
      const data = {
        bidId: props.bid.getId,
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        bidBatchId: props.bidBatchId,
        bidBatchPlayerId: props.bid.getBidBatchPlayer.getId,
        amount: amount
      }
      store.dispatch('updateBid', data)
        .then(() => {
          showModal.value = false
          emit('bid', diffAmount)
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    return {
      showModal,
      team,

      placeBid,
      closed
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'BidElement.sass'
</style>