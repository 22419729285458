<template>
  <div class="managers">
    <PrimaryButton class="managers__invite" text="Invitar a amigos" @onClick="toggleCard" />
    <div class="managers__items">
      <Manager
          v-for="team in league.getSeason.getTeams"
          v-bind:key="team.getId"
          :avatar="team.getManager.getAvatar"
          :name="team.getManager.getUsername"
          :fifaId="team.getManager.getFifaId"
          :team="team.getName"
          class="managers__item"
          :class="{ 'managers__item--deleted': team.isDeleted }"
      />
    </div>
    <FullModal
      :openedModal="showCard"
      @onPressBackButton="showCard = false"
    >
      <InvitePeopleToLeague />
    </FullModal>
  </div>
</template>

<script>
import Manager from "@/components/Manager/Manager.vue";
import PrimaryButton from "@/components/Button/PrimaryButton";
import League from "@/domain/models/League"
import FullModal from '@/components/FullModal/FullModal.vue';
import InvitePeopleToLeague from '@/components/InvitePeopleToLeague/InvitePeopleToLeague.vue';
import { ref } from '@vue/reactivity';

export default  {
  name: 'Managers',
  components: {
    PrimaryButton,
    Manager,
    FullModal,
    InvitePeopleToLeague,
  },
  props: {
    league: League
  },
  setup() {
    const showCard = ref(false)

    const toggleCard = () => {
      showCard.value = !showCard.value
    }

    return {
      showCard,
      toggleCard
    }
  }
}
</script>
<style lang="sass" scoped>
  @import "./Managers.sass"
</style>
