<template>
  <div class="listTeams__team-players">
    <div class="listTeams__team-players__header">
      <span class="listTeams__team-players__header--item" />
      <span class="listTeams__team-players__header--item">{{ team.getName }}</span>
      <span class="listTeams__team-players__header--item button">
        <PrimaryButton v-if="isLeagueOwner" text="Borrar" @onClick="showDeletePopup" />
      </span>
    </div>
    <div>
      <div class="listTeams__team-players__players">
        <PlayerGroup
          id="goalkeepers"
          name="goalkeeper"
          :players="team.getGroupedPlayers.goalkeeper"
          @playerClick="showPlayerInfo"
          :showRadio="false"
        />
        <PlayerGroup
          id="defenses"
          name="defenses"
          :players="team.getGroupedPlayers.defenses"
          @playerClick="showPlayerInfo"
          :showRadio="false"
        />
        <PlayerGroup
          id="centers"
          name="centers"
          :players="team.getGroupedPlayers.centers"
          @playerClick="showPlayerInfo"
          :showRadio="false"
        />
        <PlayerGroup
          id="strikers"
          name="strikers"
          :players="team.getGroupedPlayers.strikers"
          @playerClick="showPlayerInfo"
          :showRadio="false"
        />
      </div>
    </div>
  </div>
  <PopupModal
    :is-open="modals.deleteTeamWarningModal"
    extraClass="buyPlayer"
    @closed="hideModal('deleteTeamWarningModal')"
  >
    <div class="buyPlayer__contents">
      <div>
        ¿Deseas eliminar el equipo <b>{{ team.getName }}</b> de la liga?
      </div>
      <div>
        Todos los partidos que queden por jugar de darán por perdidos
      </div>
      <div>
        <SmallButton text="Sí, borrar el equipo" @onClick="deleteTeam" />
      </div>
    </div>
  </PopupModal>
  <PopupMessage
    :isOpen="modals.teamDeletedModal"
    @closed="hideModal('teamDeletedModal')"
  >
    Se ha eliminado el equipo correctamente.
  </PopupMessage>
  <PopupMessage
    :isOpen="modals.ownTeamDeletionModal"
    @closed="hideModal('ownTeamDeletionModal')"
  >
    No puedes eliminar tu propio equipo
  </PopupMessage>
  <FullModal
    :openedModal="modals['openPlayerInfoModal']"
    @onPressBackButton="hideModal('openPlayerInfoModal')"
  >
    <template v-slot:buttonLeftSide>
      <img src="/assets/lock.svg" v-if="selectedPlayer.isLocked">
    </template>
    <PlayerProfile
      :player="selectedPlayer.getPlayer"
    >
      <template v-slot:footer v-if="ownTeam.getId != team.getId">
        <div class="buttonList">
          <PrimaryButton
            text="Hacer oferta"
            :disabled="!canMakeOffer(selectedPlayer.getPlayer)"
            @onClick="makeOffer"
          />
          <PrimaryButton
            text="Comprar"
            :disabled="selectedPlayer.isLocked || !league.getSeason.canDirectBuy || !userCanPayForPlayer(selectedPlayer.getPlayer)"
            @onClick="buyPlayer"
          />
        </div>
      </template>
    </PlayerProfile>
  </FullModal>
  <PopupModal
    :is-open="modals.showMakeOfferModal"
    @closed="hideModal('showMakeOfferModal')"
  >
    <OfferForm
      :budget="ownTeam.getVirtualBudget"
      :player="selectedPlayer.getPlayer"
      @offer="offerMade"
    />
  </PopupModal>
  <PopupMessage
    :isOpen="modals.showOfferMadeModal"
    @closed="closeOfferMadeModal"
  >
    Tu oferta fue enviada correctamente
  </PopupMessage>
  <PopupModal
    :is-open="modals.showBuyPlayerModal"
    @closed="hideModal('showBuyPlayerModal')"
    extraClass="buyPlayer"
  >
    <div class="buyPlayer__contents">
      ¿Deseas comprar a {{ selectedPlayer.getPlayer.getName }} por {{ selectedPlayer.getPlayer.getFormattedTerminationClauseLong }}?
      <div>
        <SmallButton text="Comprar" @onClick="playerBought" />
      </div>
    </div>
  </PopupModal>
  <PopupModal
    :isOpen="modals.showAlreadyReceivedDirectBuyModal"
    extraClass="hasReceivedDirectBuy"
  >
    <div class="hasReceivedDirectBuy__contents">
      <div>
        <p>
          <b>{{ selectedPlayer.getPlayer.getName }}</b> ya ha recibido un hachazo esta temporada.
        </p>
        <p>
          ¡Prueba la temporada que viene!
        </p>
      </div>
      <SmallButton
        text="Cerrar"
        @onClick="modals.showAlreadyReceivedDirectBuyModal = !modals.showAlreadyReceivedDirectBuyModal"
      />
    </div>
  </PopupModal>
</template>

<script>
import PlayerGroup from '@/views/Tabs/Market/PlayerGroup.vue'
import Team from '@/domain/models/Team'
import League from '@/domain/models/League'
import { reactive, ref } from '@vue/reactivity'
import PlayerProfile from '../Player/PlayerProfile.vue'
import PrimaryButton from '../Button/PrimaryButton.vue'
import { useStore } from 'vuex'
import { STATUS_BIDS, STATUS_OPEN_MARKET } from "@/domain/models/Season"
import FullModal from '../FullModal/FullModal.vue'
import OfferForm from '../../views/Teams/OfferForm.vue'
import PopupModal from '../PopupModal/PopupModal.vue'
import PopupMessage from '../PopupMessage/PopupMessage.vue'
import SmallButton from '../Button/SmallButton.vue'

export default {
  components: {
    PlayerGroup,
    PlayerProfile,
    PrimaryButton,
    FullModal,
    OfferForm,
    PopupModal,
    PopupMessage,
    SmallButton,
  },
  props: {
    team: Team,
    league: League
  },
  setup(props) {
    const store = useStore()
    const selectedPlayer = ref()
    const modals = reactive({
      openPlayerInfoModal: false,
      showMakeOfferModal: false,
      showOfferMadeModal: false,
      showBuyPlayerModal: false,
      showAlreadyReceivedDirectBuyModal: false,
      deleteTeamWarningModal: false,
      teamDeletedModal: false,
      ownTeamDeletionModal: false,
    })
    const playersWithOffers = ref([])
    const ownTeam = store.getters.getCurrentTeam
    const user = store.getters.getLoggedUser
    const league = store.getters.getCurrentLeague;
    const isLeagueOwner = user.getId === league.getCreatedUser.getId

    const data = {
      leagueId: props.league.getId,
      seasonId: props.league.getSeason.getId,
      teamId: ownTeam.getId
    }
    store.dispatch('getMyOffers', data)
      .then(res => {
        playersWithOffers.value = res
        .filter(offer => {
          console.log(offer.isAccepted)
          return offer.isAccepted === null
        })
        .map(offer => offer.getPlayer.getId)
      })

    const showPlayerInfo = (player) => {
      selectedPlayer.value = player
      modals.openPlayerInfoModal = true
    }

    const makeOffer = () => {
      modals.showMakeOfferModal = true
    }

    const canMakeOffer = (player) => {
      return playersWithOffers.value.indexOf(player.getId) == -1 &&
      (
        props.league.getSeason.getStatus == STATUS_BIDS ||
        props.league.getSeason.getStatus == STATUS_OPEN_MARKET
      )
    }

    const offerMade = ({ amount, player }) => {
      store.commit('showLockScreen')

      const data = {
        leagueId: props.league.getId,
        seasonId: props.league.getSeason.getId,
        fromTeamId: ownTeam.getId,
        toTeamId: props.team.getId,
        playerId: player.getId,
        amount: amount
      }
      store.dispatch('createOffer', data)
        .then(() => {
          ownTeam.modifyBudget(amount * -1)
          modals.showOfferMadeModal = true
          playersWithOffers.value.push(player.getId)
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const buyPlayer = () => {
      if (selectedPlayer.value.hasReceivedDirectBuy) {
        modals.showAlreadyReceivedDirectBuyModal = true
        return
      }
      modals.showBuyPlayerModal = true
    }

    const hideModal = (modal) => {
      modals[modal] = false
    }

    const closeOfferMadeModal = () => {
      hideModal('openPlayerInfoModal')
      hideModal('showMakeOfferModal')
      hideModal('showOfferMadeModal')
    }

    const userCanPayForPlayer = (player) => {
      return player.getTerminationClause < ownTeam.getVirtualBudget
    }

    const playerBought = () => {
      store.commit('showLockScreen')
      const data = {
        leagueId: props.league.getId,
        seasonId: props.league.getSeason.getId,
        teamId: props.team.getId,
        teamPlayerId: selectedPlayer.value.getId
      }
      store.dispatch('buyTeamPlayer', data)
        .then(() => {
          const player = selectedPlayer.value.getPlayer
          props.team.modifyBudget(player.getTerminationClause)
          ownTeam.modifyBudget(player.getTerminationClause * -1)
          ownTeam.appendPlayer(player)
          props.team.removePlayer(player)
          hideModal('showBuyPlayerModal')
          hideModal('openPlayerInfoModal')
          store.dispatch('getLeague', props.league.getId)
          .then(() => {
            store.commit('hideLockScreen')
          })
        })
    }

    const showDeletePopup = () => {
      if (props.team.getManager.getId === user.getId) {
        modals.ownTeamDeletionModal = true
        return
      }
      modals.deleteTeamWarningModal = true
    }

    const deleteTeam = () => {
      const data = {
        leagueId: props.league.getId,
        seasonId: props.league.getSeason.getId,
        teamId: props.team.getId,
      }
      store.dispatch('deleteSeasonTeam', data)
      .then(() => {
        store.dispatch('getLeague', props.league.getId)
        .then(() => {
          hideModal('deleteTeamWarningModal')
          modals.teamDeletedModal = true
        })
      })
    }

    return {
      selectedPlayer,
      modals,
      ownTeam,
      isLeagueOwner,

      showPlayerInfo,
      canMakeOffer,
      makeOffer,
      hideModal,
      offerMade,
      buyPlayer,
      closeOfferMadeModal,
      userCanPayForPlayer,
      playerBought,
      deleteTeam,
      showDeletePopup,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'PlayerList.sass'
</style>
