<template>
  <ion-page>
    <ion-content>
      <BasicViewWithEllipsesBackground :description="description">
        <template v-slot:title>
          <div class="welcome__title">
            Bienvenido {{ userName }} <img src="/assets/fire.png" alt="fire">
          </div>
        </template>

        <template v-slot:footerButtons>
          <div class="welcome__buttons">
            <PrimaryButton text="¡Vamos!" @click="goTo"/>
          </div>
        </template>
      </BasicViewWithEllipsesBackground>
    </ion-content>
  </ion-page>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { IonContent, IonPage } from '@ionic/vue'
import PrimaryButton from "@/components/Button/PrimaryButton"
import BasicViewWithEllipsesBackground from "@/components/BasicViewWithEllipsesBackground/BasicViewWithEllipsesBackground";

export default {
  name: "Welcome",
  components: { BasicViewWithEllipsesBackground, PrimaryButton, IonPage, IonContent },
  setup() {
    const store = useStore()
    const router = useRouter()
    const userName = store.getters.getLoggedUser.username
    const description = 'A partir de aquí sois tú, tus habilidades y tu capacidad para competir contra el resto de tus compañeros  los que emprenderéis este camino. Estamos seguros que le sacarás el máximo rendimiento a tu fifa 😉'

    const goTo = () => {
      router.push({name: 'league_welcome'})
    }

    return {
      userName,
      description,

      goTo
    }
  }
};
</script>

<style lang="sass" scoped>
  @import './Welcome.sass'
</style>
