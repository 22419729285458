<template>
  <span class="player-flag">
    <img :src="image" height="10" :alt="player.getNationality" />
  </span>
</template>

<script>
import Player from "@/domain/models/Player";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerFlag",
  props: {
    player: Player,
  },
  setup(props) {
    return {
      image: process.env.VUE_APP_FLAG_API_URL + props.player.getNationality + '.png',
    };
  },
});
</script>
