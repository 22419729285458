import { shortNumber} from '@/domain/services/NumberFormatter'
import { longCurrency } from '@/domain/services/CurrencyFormatter'
import TeamPlayerFactory from '@/domain/services/TeamPlayerFactory'

export default class Team {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.avatar = data.avatar
    this.position = data.position
    this.manager = data.manager
    this.points = data.points
    this.matchesPlayed = data.matchesPlayed
    this.victories = data.victories
    this.loses = data.loses
    this.ties = data.ties
    this.goalsFor = data.goalsFor
    this.goalsAgainst = data.goalsAgainst
    this.yellowCards = data.yellowCards
    this.redCards = data.redCards
    this.budget = data.budget
    this.sponsor = data.sponsor
    this.virtualBudget = data.virtualBudget
    this.salaryTable = data.salaryTable
    this.exceededSalaryTable = data.exceededSalaryTable
    this.minPlayers = data.minPlayers
    this.teamPlayers = data.teamPlayers
    this.groupedPlayers = data.groupedPlayers
    this.deleted = data.deleted
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getAvatar () {
    return this.avatar || '/assets/logo.png'
  }

  get getPosition () {
    return this.position
  }

  get getManager () {
    return this.manager
  }

  get getPoints () {
    return this.points
  }

  get getMatchesPlayed () {
    return this.matchesPlayed
  }

  get getVictories () {
    return this.victories
  }

  get getLoses () {
    return this.loses
  }

  get getTies () {
    return this.ties
  }

  get getGoalsFor () {
    return this.goalsFor
  }

  get getFormattedGoalsFor () {
    return this.goalsFor + ' GF'
  }

  get getGoalsAgainst () {
    return this.goalsAgainst
  }

  get getFormattedGoalsAgainst () {
    return this.goalsAgainst + ' GC'
  }

  get getYellowCards () {
    return this.yellowCards
  }

  get getRedCards () {
    return this.redCards
  }

  get getBudget () {
    return this.budget
  }

  modifyBudget (amount) {
    this.budget += amount
    this.virtualBudget += amount
  }

  get getVirtualBudget () {
    return this.virtualBudget
  }

  setVirtualBudget (virtualBudget) {
    this.virtualBudget = virtualBudget
  }

  get getFormattedBudgetLong () {
    return longCurrency(this.budget)
  }

  get getFormattedVirtualBudgetLong () {
    return longCurrency(this.virtualBudget)
  }

  get getSponsor () {
    return this.sponsor
  }

  setSponsor (sponsor) {
    this.sponsor = sponsor
  }

  get getSalaryTable () {
    return this.salaryTable
  }

  get getFormattedSalaryTableLong () {
    return longCurrency(this.salaryTable)
  }

  get getFormattedBudgetShort () {
    return shortNumber(this.budget)
  }

  get hasExceededSalaryTable () {
    return this.exceededSalaryTable
  }

  get hasMinPlayers () {
    return this.minPlayers
  }

  get getTeamPlayers () {
    return this.teamPlayers
  }

  get getGroupedPlayers () {
    return this.groupedPlayers
  }

  get isDeleted () {
    return this.deleted
  }

  removePlayer (player) {
    const index = this
      .getTeamPlayers
      .findIndex(teamPlayerInTeam => {
        return teamPlayerInTeam.getPlayer.getId == player.getId
      })

    if (index > -1) {
      this.getTeamPlayers.splice(index, 1)
    }

    for (const groupKey in this.getGroupedPlayers) {
      const group = this.getGroupedPlayers[groupKey]

      const index = group.findIndex(playerInTeam => {
        return playerInTeam.getPlayer.getId == player.getId
      })
      if (index > -1) {
        group.splice(index, 1)
      }
    }
  }

  appendPlayer (player) {
    const data = {
      id: '',
      player: player,
      locked: false
    }
    const teamPlayer = TeamPlayerFactory.get(data)
    this.teamPlayers.push(teamPlayer)

    this.groupedPlayers[player.getCanonicalPosition].push(teamPlayer)
  }
}
