import axios from '@/plugins/axios'
import LeagueFactory from '@/domain/services/LeagueFactory'
import { uuid } from 'vue-uuid'

const URL_LEAGUE = '/api/users/leagues/{code}'
const URL_CREATE_USER_LEAGUE = '/api/users/me/leagues'
const URL_GET_USER_LEAGUES = '/api/users/me/leagues'
const URL_GET_USER_LEAGUE = '/api/users/me/leagues/{id}'
const URL_APPEND_USER_LEAGUE = '/api/users/me/leagues/managers'

export function getLeagueByCode(code) {
  const url = URL_LEAGUE.replace('{code}', code)
  return axios.get(url)
    .then(res => LeagueFactory.get(res.data))
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function createUserLeague(league) {
  const data = {
    id: uuid.v4(),
    name: league.name,
    duration: league.duration,
    maxPlayers: league.maxPlayers,
    budget: league.budget,
    salaryTable: league.salaryTable,
    waitForOther: league.waitForOther,
    bidTime: league.bidTime,
    openMarketTime: league.openMarketTime
  }

  return axios.post(URL_CREATE_USER_LEAGUE, data)
    .then(() => data)
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getUserLeagues() {
  return axios.get(URL_GET_USER_LEAGUES)
    .then(res => {
      return res.data.collection.map(league => LeagueFactory.get(league))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getLeague(leagueId) {
  const url = URL_GET_USER_LEAGUE.replace('{id}', leagueId)
  return axios.get(url)
    .then(res => {
      return LeagueFactory.get(res.data)
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function appendUserToLeague(code) {
  const data = {
    code: code
  }
  return axios.post(URL_APPEND_USER_LEAGUE, data)
    .then((res) => res)
    .catch(error => Promise.reject(error.response.data))
}
