<template>
  <div class="selectSponsorCard">
    <ion-row>
      <ion-col>
        <span class="selectSponsorCard__title">
          Selecciona un patrocinador
          <img src="/assets/info.svg" width="20" @click="openInfo" />
        </span>
      </ion-col>
    </ion-row>
    <ion-row v-for="sponsor in sponsors" :key="sponsor">
      <ion-col>
        <SponsorCard
          v-bind="sponsor"
          :backgroundImage="sponsor.getImage"
          @selected="$emit('sponsorSelected', sponsor)"
        />
      </ion-col>
    </ion-row>
    <PopupModal
      :is-open="openInfoModal"
      @closed="hideInfoModal"
    >
      <div class="popupModal__standard">
        <div>
          Recuerda que esta parte será vital al final de la temporada para la economía de tu club.
          Piensa cuál es tu estilo de juego, quienes serán tus rivales y su talento en
          FIFA y elige tu patrocinador.
        </div>
        <div>
          <SmallButton text="Cerrar" @onClick="hideInfoModal" />
        </div>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import {
  IonCol,
  IonRow
} from '@ionic/vue'
import SponsorCard from '@/components/SponsorCard/SponsorCard'
import PopupModal from '../PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'
import { ref } from 'vue'

export default {
  components: {
    IonCol,
    IonRow,
    SponsorCard,
    PopupModal,
    SmallButton
  },
  props: {
    sponsors: {
      type: Array,
      default: null
    }
  },
  setup() {
    const openInfoModal = ref(false)
    const openInfo = () => {
      openInfoModal.value = true
    }

    const hideInfoModal = () => {
      openInfoModal.value = false
    }

    return {
      openInfoModal,

      openInfo,
      hideInfoModal
    }
  },
}
</script>

<style lang="sass" scoped>
  @import './SelectSponsorCard.sass'
</style>
