<template>
  <ion-modal
      :is-open="openedModal"
      :cssClass="'customModal ' + cssClass"
      :swipeToClose="swipeToClose"
      @didDismiss="$emit('triggerModal')"
      mode="ios"
  >
    <div class="modalContent">
      <img
          class="modalContent__line"
          src="/assets/modal-line.svg"
          @click="$emit('triggerModal')"
          alt="close-line"
      >
      <slot></slot>
    </div>
  </ion-modal>
</template>

<script>
import {IonModal} from '@ionic/vue'

export default {
  name: "CustomModal",
  components: { IonModal },
  props: {
    openedModal: Boolean,
    swipeToClose: {
      type: Boolean,
      default: true
    },
    cssClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="sass">
  @import './CustomModal.sass'
</style>
