import PlayerFactory from '@/domain/services/PlayerFactory'
import MVPPlayer from '@/domain/models/MVPPlayer'

class MVPPlayerFactory {
  get (data) {
    const mvpPlayer = {
      quantity: data.quantity,
      teamName: data.teamName,
      teamId: data.team,
      player: PlayerFactory.get(data.player),
    }

    return new MVPPlayer(mvpPlayer)
  }
}

export default new MVPPlayerFactory()
