<template>
  <div class="initialsAvatar" :class="[size, mode]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'regular'
    },
    mode: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'InitialsAvatar.sass'
</style>