<template>
  <ion-loading
    :is-open="show"
    cssClass="lockScreen"
    message="Un segundo..."
    duration="50000"
    @didDismiss="onClose"
  />
</template>

<script>
import { IonLoading } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: { IonLoading },
  setup() {
    const show = ref(false);

    return { show }
  },
  methods: {
    onClose() {
      this.$store.commit('hideLockScreen')
    }
  }
});
</script>
<style lang="sass">
  @import './LockScreen.sass'
</style>
