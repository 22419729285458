<template>
  <div class="menu__trigger_button">
    <img src="/assets/menu_button.svg" @click="toggleMenu">
  </div>
</template>

<script>
import { menuController } from '@ionic/vue'

export default {
  name: "MenuButton",
  methods: {
    toggleMenu() {
      menuController.toggle()
    }
  }
}
</script>
