const messages = {
  "goalkeeper": 'Portero',
  "defenses": 'Defensa',
  "centers": 'Mediocentro',
  "strikers": 'Delantero',
}

const pluralMessages = {
  "goalkeeper": 'Porteros',
  "defenses": 'Defensas',
  "centers": 'Mediocentros',
  "strikers": 'Delanteros',
}

export const getMessage = (key) => {
  return messages[key]
}

export const getPluralMessage = (key) => {
  return pluralMessages[key]
}
