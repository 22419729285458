export default class Scorer {
  constructor (data) {
    this.id = data.id
    this.goals = data.goals,
    this.home = data.home,
    this.ownGoal = data.ownGoal,
    this.player = data.player
  }

  get getId () {
    return this.id
  }

  get getGoals () {
    return this.goals
  }

  get isHome () {
    return this.home
  }

  updateHome (isHome) {
    this.home = isHome
  }

  get isOwnGoal () {
    return this.ownGoal
  }

  get getPlayer () {
    return this.player
  }
}