export const STATUS_NONE = 'none'
export const STATUS_DRAFT = 'draft'
export const STATUS_BIDS = 'bids'
export const STATUS_OPEN_MARKET = 'open-market'
export const STATUS_REGULAR = 'regular'
export const STATUS_FINISHED = 'finished'

import { shortDate} from "../services/DateFormatter"

export default class Season {
  constructor (data) {
    this.id = data.id
    this.number = data.number
    this.bidStartAt = data.bidStartAt
    this.openMarketStartAt = data.openMarketStartAt
    this.regularStartAt = data.regularStartAt
    this.restartedAt = data.restartedAt
    this.status = data.status
    this.changeSponsor = data.changeSponsor
    this.directBuy = data.directBuy
    this.teams = data.teams
    this.managers = data.managers
}

  get getId () {
    return this.id
  }

  get getNumber () {
    return this.number
  }

  get getBidStartAt () {
    return new Date(this.bidStartAt)
  }

  get getOpenMarketStartAt () {
    return new Date(this.openMarketStartAt)
  }

  get getRestartedAt () {
    return new Date(this.restartedAt)
  }

  get getRegularStartAt () {
    return new Date(this.regularStartAt)
  }

  get getFormattedStartAt () {
    return shortDate(this.getStartAt)
  }

  get getStatus () {
    return this.status
  }

  get canChangeSponsor () {
    return this.changeSponsor
  }

  get canDirectBuy () {
    return this.directBuy
  }

  get getTeams () {
    return this.teams
  }

  get getPositionSortedTeams () {
    return this.teams.sort(function(teamA, teamB) {
      if (teamA.getPosition < teamB.getPosition) {
        return -1
      }
      if (teamA.getPosition > teamB.getPosition) {
        return 1
      }
      return 0
    })
  }

  get getPointsSortedTeams () {
    return this.teams.sort(function(teamA, teamB) {
      if (teamA.getPoints < teamB.getPoints) {
        return -1
      }
      if (teamA.getPoints > teamB.getPoints) {
        return 1
      }
      return 0
    })
  }

  get canSellPlayers () {
    return this.getStatus !== 'regular'
  }

  get getManagers () {
    return this.managers
  }

  get canChangePhase () {
    return this.getStatus === STATUS_NONE || this.getStatus === STATUS_DRAFT || this.getStatus === STATUS_BIDS || this.getStatus === STATUS_OPEN_MARKET
  }
}
