import axios from '@/plugins/axios';
import ResultFactory from '@/domain/services/ResultFactory'

const URL_GET_LEAGUE_RESULTS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results'
const URL_GET_RESULTS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results/{resultId}'
const URL_UPDATE_RESULTS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results/{resultId}'
const URL_FORCE_UPDATE_RESULTS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/results/{resultId}?force'

export function getTeamResults(leagueId, seasonId) {
  const url = URL_GET_LEAGUE_RESULTS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)

  return axios.get(url)
    .then(res => {
      return res.data.collection.map(result => ResultFactory.get(result))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function updateResult(leagueId, seasonId, resultId, data) {
  const url = URL_UPDATE_RESULTS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{resultId}', resultId)

  return axios.put(url, data)
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
export function forceUpdateResult(leagueId, seasonId, resultId, data) {
  const url = URL_FORCE_UPDATE_RESULTS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{resultId}', resultId)

  return axios.put(url, data)
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
export function getResult(leagueId, seasonId, resultId) {
  const url = URL_GET_RESULTS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{resultId}', resultId)

  return axios.get(url)
    .then(res => ResultFactory.get(res.data))
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
