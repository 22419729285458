<template>
  <PopupModal
    :is-open="isOpen"
    @closed="$emit('closed')"
  >
    <div class="popupMessage">
      <div class="title">
        <slot />
      </div>
      <div class="button">
        <SmallButton :text="closeButtonLabel" @onClick="$emit('closed')"></SmallButton>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import SmallButton from '../Button/SmallButton.vue'
import PopupModal from '../PopupModal/PopupModal.vue'
export default {
  components: {
    PopupModal,
    SmallButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    closeButtonLabel: {
      type: String,
      default: 'Cerrar'
    }
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import 'PopupMessage.sass'
</style>