<template>
  <ion-row v-if="startedLeagues.length > 0">
    <ion-col>
      <div class="leagueSelection__title">Ligas en curso</div>
      <div class="leagueSelection_leagues" v-for="league in startedLeagues" :key="league">
        <LeagueCard
          :league="league"
          @selected="goToLeague(league)"
        />
      </div>
    </ion-col>
  </ion-row>
  <ion-row v-if="finishedLeagues.length > 0">
    <ion-col>
      <div class="title">Ligas finalizadas</div>
      <div class="leagueSelection_leagues" v-for="league in finishedLeagues" :key="league">
      </div>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
} from '@ionic/vue'
import { useStore } from 'vuex'
import { ref } from '@vue/reactivity'
import LeagueCard from './LeagueCard'
import { useRouter } from 'vue-router'

export default {
  components: {
    IonRow,
    IonCol,
    LeagueCard,
  },
  emits: ['selected'],
  setup(_, { emit }) {
    const store = useStore()
    const router = useRouter()
    const startedLeagues = ref([])
    const finishedLeagues = ref([])

    const getLeagues = () => {
      store.commit('showLockScreen')
      store.dispatch('getUserLeagues')
        .then(leagues => {
          startedLeagues.value = leagues
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const goToLeague = (league) => {
      store.dispatch('getLeague', league.getId)
        .then(() => {
          router.push({ name: 'news', params: { id: league.getId }})
          emit('selected')
        })
    }

    getLeagues()

    return {
      startedLeagues,
      finishedLeagues,

      goToLeague,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'LeagueSelection.sass'
</style>