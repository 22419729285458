<template>
  <div class="leagueCard" :style="background" @click="select(league)">
    <span class="leagueCard__floatingName">
      {{ league.getName }}
    </span>
    <div class="leagueCard__position">
      {{ teamPosition }}º <span class="leagueCard__position--points">({{ teamPoints }} ptos)</span>
    </div>
    <div class="leagueCard__season">
      Temporada {{ league.getSeason.getNumber }}
    </div>
  </div>
</template>

<script>
import League from '@/domain/models/League'
import { useStore } from 'vuex'
import { getUserTeamByLeague } from '@/domain/services/TeamService'
import { computed } from '@vue/reactivity'

export default {
  emits: ['selected'],
  props: {
    league: League
  },
  setup(props, { emit }) {
    const store = useStore()
    const user = store.getters.getLoggedUser
    const team = getUserTeamByLeague(props.league, user)

    const background = computed(() => {
      return {
        '--background-image': 'url("/assets/covers/' + (props.league.getSeason.getBidStartAt.getFullYear().toString()) + '.jpeg")'
      }
    })
    const teamPosition = computed(() => team ? team.getPosition : 0)
    const teamPoints = computed(() => team ? team.getPoints : 0)

    const select = (league) => {
      emit('selected', league)
    }

    return {
      team,
      teamPosition,
      teamPoints,

      select,
      background
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'LeagueCard.sass'
</style>