import axios from '@/plugins/axios'
import { uuid } from 'vue-uuid'

const URL_LOGIN = '/api/auth/login-check'
const URL_REFRESH_TOKEN = '/api/auth/refresh-tokens'
const URL_REQUEST_PASSWORD_RESET = '/api/auth/password-reset'
const URL_PASSWORD_RESET = '/api/auth/update-password-from-reset'

export function login(formData) {
  return axios
    .post(URL_LOGIN, {
      username: formData.email,
      password: formData.password
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
export function refreshToken(token) {
  return axios
    .post(URL_REFRESH_TOKEN, {
      refreshToken: token
    })
    .then(res => {
      return res
    })
}
export function requestPasswordReset(email) {
  return axios
    .post(URL_REQUEST_PASSWORD_RESET, {
      id: uuid.v4(),
      email: email
    })
    .then(res => res)
}
export function passwordReset(token, password) {
  return axios
    .post(URL_PASSWORD_RESET, {
      token: token,
      password: password
    })
    .then(res => res)
}
