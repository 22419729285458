import PlayerFactory from "@/domain/services/PlayerFactory"
import BidBatchPlayer from "../models/BidBatchPlayer"

class BidBatchPlayerFactory {
  get (data) {
    const bidBatchPlayer = {
      id: data.id,
      bidsCount: data.bidsCount,
      player: PlayerFactory.get(data.player)
    }

    return new BidBatchPlayer(bidBatchPlayer)
  }
}

export default new BidBatchPlayerFactory()
