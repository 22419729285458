<template>
  <ion-row>
    <ion-col
      class="sponsorCard"
      :style="'background-image: url(/assets/' + backgroundImage + ')'"
      @click="show=true"
    >
      <BallBadge>{{ name }}</BallBadge>
      <FullModalImage
        :openedModal="show"
        @onPressBackButton="hide"
        :backgroundImage="backgroundImage"
      >
        <SponsorDetail
          :name="name"
          :wins="wins"
          :ties="ties"
          :loses="loses"
          :maxScorer="maxScorer"
          :maxMvp="maxMvp"
          :cards="cards"
          :zamora="zamora"
          :changes="changes"
          :bestSquad="bestSquad"
        />
        <ion-row>
          <ion-col>
            <PrimaryButton text="Seleccionar" @click="$emit('selected', id )" />
          </ion-col>
        </ion-row>
      </FullModalImage>
    </ion-col>
  </ion-row>
</template>

<script>
import { ref } from '@vue/reactivity'
import { IonCol, IonRow } from '@ionic/vue'
import SponsorDetail from './SponsorDetail.vue'
import FullModalImage from '@/components/FullModal/FullModalImage.vue'
import PrimaryButton from '@/components/Button/PrimaryButton.vue'
import BallBadge from '@/components/Badge/BallBadge.vue'

export default {
  name: 'SponsorCard',
  components: {
    IonCol,
    IonRow,
    SponsorDetail,
    FullModalImage,
    PrimaryButton,
    BallBadge
  },
  props: {
    id: String,
    name: String,
    wins: Number,
    ties: Number,
    loses: Number,
    maxScorer: Number,
    maxMvp: Number,
    cards: Number,
    zamora: Number,
    changes: Number,
    bestSquad: Number,
    backgroundImage: String,
  },
  setup() {
    const show = ref(false)

    const hide = () => {
      show.value = false
    }

    return {
      show,
      hide,
    }
  },
}
</script>

<style lang="sass" scoped>
  @import './SponsorCard.sass'
</style>
