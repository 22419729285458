<template>
  <div
    class="offersMadePlayer"
    @click.stop="$emit('showPlayer', offer.getPlayer)"
  >
    <div class="offersMadePlayer__avatar">
      <InitialsAvatar
        mode="dark"
      >
        {{ player.getInitials }}
      </InitialsAvatar>
    </div>
    <div class="offersMadePlayer__main">
      <div class="offersMadePlayer__main__header">
        <span class="offersMadePlayer__main__header__playerName">
          {{ player.getName }}
        </span>
        <span class="offersMadePlayer__main__header__teamName">
          {{ offer.getTeamTo.getName }}
        </span>
      </div>
      <div class="offersMadePlayer__main__footer">
        <span class="offersMadePlayer__main__footer__offer">
          Oferta: {{ offer.getFormattedAmountShort }} ·
        </span>
        <span class="offersMadePlayer__main__footer__status" :class="getClassStatus(offer.isAccepted)">
          {{ getStatus(offer.isAccepted, offer.isExpired) }}
        </span>
      </div>
    </div>
    <div class="offersMadePlayer__dismiss">
        <div
          class="delete"
          @click.stop="$emit('offerDeleted', offer)"
          v-if="offer.isAccepted !== null || offer.isExpired"
        >
          <img src="/assets/close.svg" width="12">
        </div>
        <div
          class="withdrawn"
          @click.stop="$emit('offerWithdrawn', offer)"
          v-else
        >
          <SmallButton
            text="Retirar"
            :color="null"
            class="withdrawn__button"
            @click.stop="$emit('offerWithdrawn', offer)"
          />
        </div>
    </div>
  </div>
</template>

<script>
import Offer from '@/domain/models/Offer'
import InitialsAvatar from '@/components/InitialsAvatar/InitialsAvatar.vue'
import { longCurrency } from '@/domain/services/CurrencyFormatter'
import SmallButton from '@/components/Button/SmallButton.vue'
import { unref } from '@vue/reactivity'

export default {
  components: {
    InitialsAvatar,
    SmallButton
  },
  props: {
    offer: Offer
  },
  setup(props) {
    const getStatus = (accepted, expired) => {
      if (expired) {
        return 'Expirada'
      }
      return {
        true: 'Aceptada',
        false: 'Rechazada',
        null: 'Pendiente'
      }[accepted]
    }

    const getClassStatus = (accepted) => {
      return {
        true: 'accepted',
        false: 'rejected',
        null: ''
      }[accepted]
    }

    const player = unref(props.offer.getPlayer)

    return {
      player,
      getStatus,
      getClassStatus,
      longCurrency
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OffersMadePlayer.sass'
</style>
