import { createStore } from 'vuex'

import state from '@/store/state'
import actions from '@/store/actions'
import getters from '@/store/getters'
import mutations from '@/store/mutations'

export const store = createStore({
  state() {
    return state
  },
  getters,
  mutations,
  actions
})
