<template>
  <SmallBadge class="ballBadge">
    <img src="/assets/soccer.png" width="12" />
    <slot />
  </SmallBadge>
</template>

<script>
import SmallBadge from './SmallBadge.vue'
export default {
  components: { SmallBadge },
}
</script>

<style lang="sass" scoped>
  .ballBadge
    display: inline-block
    padding: 5px
    img
      width: 12px
      height: 12px
      margin: 0 5px 0 0
</style>