<template>
  <ion-button
    mode="ios"
    class="primaryButton"
    color="primary"
    :disabled="disabled"
    expand="block"
    size="large"
    @click="onClick"
  >
    <span class="primaryButton__text">{{ text }}</span>
  </ion-button>
</template>

<script>
import { IonButton } from '@ionic/vue';

export default {
  name: "PrimaryButton",
  components: { IonButton },
  props: {
    text: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './PrimaryButton.sass'
</style>
