export default class SearchSort {
  constructor () {
    this.index = 0,
    this.data = [
      {
        value: null,
        icon: '/assets/sort.svg'
      },
      {
        value: 'DESC',
        icon: '/assets/sort-desc.svg'
      },
      {
        value: 'ASC',
        icon: '/assets/sort-asc.svg'
      },
    ]
  }

  inc () {
    this.index ++
  }

  get getValue () {
    return this.data[this.index % 3].value
  }

  get getIcon () {
    return this.data[this.index % 3].icon
  }
}
