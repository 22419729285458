<template>
  <div
    class="offersReceivedPlayer"
    @click.stop="$emit('showPlayer', player)"
  >
    <div class="offersReceivedPlayer__avatar">
      <InitialsAvatar
        mode="dark"
      >
        {{ player.getInitials }}
      </InitialsAvatar>
    </div>
    <div class="offersReceivedPlayer__main">
      <div class="offersReceivedPlayer__main__header">
        <span class="offersReceivedPlayer__main__header__playerName">
          {{ player.getName }}
        </span>
        <span class="offersReceivedPlayer__main__header__teamName">
          {{ offer.getTeamFrom.getName }}
        </span>
      </div>
      <div class="offersReceivedPlayer__main__footer">
        <span class="offersReceivedPlayer__main__footer__offer">
          Oferta: {{ offer.getFormattedAmountShort }} ·
        </span>
        <span class="offersReceivedPlayer__main__footer__status">
          {{ getStatus(offer.isAccepted, offer.isExpired) }}
        </span>
      </div>
    </div>
    <div class="offersReceivedPlayer__settle">
        <div
          class="delete"
          @click.stop="$emit('offerDeleted', offer)"
          v-if="offer.isAccepted !== null || offer.isExpired"
        >
          <img src="/assets/close.svg" width="20">
        </div>
        <InitialsAvatar
          v-if="offer.isAccepted == null && !offer.isExpired"
          mode="dark"
          class="reject"
          @click.stop="$emit('offerRejected', offer)"
        >
          <img src="/assets/close.svg" width="12">
        </InitialsAvatar>
        <InitialsAvatar
          v-if="offer.isAccepted == null && !offer.isExpired"
          mode="dark"
          class="accept"
          @click.stop="$emit('offerAccepted', offer)"
        >
          <img src="/assets/checked.svg" width="12">
        </InitialsAvatar>
    </div>
  </div>
</template>

<script>
import InitialsAvatar from '@/components/InitialsAvatar/InitialsAvatar.vue'
import Offer from '@/domain/models/Offer'
import { unref } from '@vue/reactivity'

export default {
  props: {
    offer: Offer
  },
  components: {
    InitialsAvatar
  },
  setup(props) {
    const player = unref(props.offer.getPlayer)
    const getStatus = (accepted, expired) => {
      if (expired) {
        return 'Expirada'
      }
      return {
        true: 'Aceptada',
        false: 'Rechazada',
        null: 'Pendiente'
      }[accepted]
    }

    return {
      player,

      getStatus
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OffersReceivedPlayer.sass'
</style>