<template>
  <table class="showResult">
    <tr class="showResult__results__top">
      <td>
        <div class="image">
          <img :src="result.getHomeTeam.getAvatar">
        </div>
      </td>
      <td>
        <div class="scores">
          <div>{{ result.getHomeResult }}</div>
          <div>-</div>
          <div>{{ result.getVisitorResult }}</div>
        </div>
      </td>
      <td>
        <div class="image">
          <img :src="result.getVisitorTeam.getAvatar">
        </div>
      </td>
    </tr>
    <tr class="showResult__results__bottom">
      <td>
        <div class="name">
          {{ result.getHomeTeam.getName }}
        </div>
      </td>
      <td>
        <div class="date">
          {{ result.getFormattedMatchDate }}
        </div>
      </td>
      <td>
        <div class="name">
          {{ result.getVisitorTeam.getName }}
        </div>
      </td>
    </tr>
  </table>
  <table class="showScorers showInfo">
    <tr>
      <td class="showScorers__names lateral">
        <div v-for="scorer in homeScorers" :key="scorer">
          {{ getScorerName(scorer) }}
        </div>
      </td>
      <td class="showScorers__center center"><img src="/assets/soccer.png">Goles</td>
      <td class="showScorers__names lateral">
        <div v-for="scorer in visitorScorers" :key="scorer">
          {{ getScorerName(scorer) }}
        </div>
      </td>
    </tr>
  </table>

  <table class="showCards showInfo yellowCards">
    <tr>
      <td class="showCards__amount lateral">
        <div>
          {{ result.getHomeYellowCards }}
        </div>
      </td>
      <td class="showCards__center center"><img src="/assets/yellowcard.png">Cards</td>
      <td class="showCards__amount lateral">
        <div>
          {{ result.getVisitorYellowCards }}
        </div>
      </td>
    </tr>
  </table>

  <table class="showCards showInfo redCards">
    <tr>
      <td class="showCards__amount lateral">
        <div>
          {{ result.getHomeRedCards }}
        </div>
      </td>
      <td class="showCards__center center"><img src="/assets/redcard.png">Cards</td>
      <td class="showCards__visitor lateral">
        <div>
          {{ result.getVisitorRedCards }}
        </div>
      </td>
    </tr>
  </table>
  <table class="showMVP showInfo">
    <tr>
      <td>
        <span v-if="result.getMvpHome && result.getMvp">{{ result.getMvp.getName }}</span>
        <span v-else>-</span>
      </td>
      <td class="showMVP__center center"><img src="/assets/fire.png">MVP</td>
        <span v-if="!result.getMvpHome && result.getMvp">{{ result.getMvp.getName }}</span>
        <span v-else>-</span>
    </tr>
  </table>
  <div class="button-bar" v-if="isLeagueOwner">
    <PrimaryButton text="Editar resultado" @onClick="saveResults" />
  </div>
  <FullModal
    :openedModal="showResultPopup"
    @onPressBackButton="close"
  >
    <EnterResult
      :id="result.getId"
      :homeTeamId="result.getHomeTeam.getId"
      :visitorTeamId="result.getVisitorTeam.getId"
      :homeAvatar="result.getHomeTeam.getAvatar"
      :homeName="result.getHomeTeam.getName"
      :visitorAvatar="result.getVisitorTeam.getAvatar"
      :visitorName="result.getVisitorTeam.getName"
      :result="result"
      @updated="closeRefresh"
    />
  </FullModal>
</template>

<script>
import { uuid } from 'vue-uuid'
import Result from '@/domain/models/Result'
import { useStore } from 'vuex';
import PrimaryButton from '../Button/PrimaryButton.vue'
import EnterResult from "@/components/ResultPopups/EnterResult";
import FullModal from "@/components/FullModal/FullModal";
import { ref } from 'vue';

export default {
  name: 'ShowResult',
  components: { PrimaryButton, EnterResult, FullModal },
  props: {
    result: Result
  },
  emits: ["saved"],
  setup(props, { emit }) {
    const store = useStore();
    const showResultPopup = ref(false);
    const league = store.getters.getCurrentLeague;
    const user = store.getters.getLoggedUser
    const isLeagueOwner = user.getId === league.getCreatedUser.getId;

    const homeScorers = props.result.getScorers
      .filter(scorer => {
        return (scorer.isHome && !scorer.isOwnGoal) || (!scorer.isHome && scorer.isOwnGoal)
      })

    const visitorScorers = props.result.getScorers
      .filter(scorer => {
        return (!scorer.isHome && !scorer.isOwnGoal) || (scorer.isHome && scorer.isOwnGoal)
      })

    const getScorerName = (scorer) => {
      const name = scorer.getPlayer.getName + ' (' + scorer.getGoals + ')'
      if (scorer.isOwnGoal) {
        return name + ' (PP)'
      }
      return name
    }
    const saveResults = () => {
      showResultPopup.value = true;
    };

    const closeRefresh = (updatedResult, scorers) => {
      store.commit('showLockScreen')

      store.dispatch('forceUpdateResult', {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        resultId: props.result.getId,
        data: updatedResult
      })
      .then(() => {
        if (scorers.length > 0) {
          store.dispatch('forceCreateScorer', {
            leagueId: league.getId,
            seasonId: league.getSeason.getId,
            resultId: props.result.getId,
            data: {
              scorers: scorers.map(scorer => {
                scorer.id = uuid.v4()
                delete scorer.name
                return scorer
              })
            }
          })
          .then(() => {
          })
          .catch(error => {
            console.error(['createScorer', error])
          })
        }
      })
      .catch((error) => {
        console.error(['updateResult', error])
      })
      .finally(() => {
        store.commit('hideLockScreen')
      })


      store.dispatch('getLeague', league.getId)
        .then(() => {
          emit("saved");
          close()
        })
    }

    const close = () => {
      showResultPopup.value = false;
    }

    return {
      homeScorers,
      visitorScorers,
      isLeagueOwner,
      showResultPopup,

      getScorerName,
      saveResults,
      closeRefresh,
      close,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./ShowResult.sass"
</style>
