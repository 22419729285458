import BidBatchPlayerFactory from "@/domain/services/BidBatchPlayerFactory"
import Bid from "@/domain/models/Bid"

class BidFactory {
  get (data) {
    const bid = {
      id: data.id,
      amount: data.amount,
      bidBatchPlayer: BidBatchPlayerFactory.get(data.bidBatchPlayer)
    }

    return new Bid(bid)
  }
}

export default new BidFactory()
