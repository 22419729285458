export const TYPE_WON_BID = 'won-bid'
export const TYPE_PLAYER_BOUGHT = 'player-bought'
export const TYPE_PLAYER_SOLD = 'player-sold'
export const TYPE_PLAYER_TRADED_SOLD = 'player-traded-sold'
export const TYPE_PLAYER_TRADED_BOUGHT = 'player-traded-bought'
export const TYPE_DIRECT_BUY_BOUGHT = 'direct-buy-bought'

import { longDateTime } from '@/domain/services/DateFormatter'

export default class BotNews {
  constructor (data) {
    this.id = data.id
    this.notes = data.notes
    this.type = data.type
    this.createdAt = data.createdAt
    this.referringUser = data.referringUser
  }

  get getId () {
    return this.id
  }

  get getNotes () {
    return JSON.parse(this.notes)
  }

  get getType () {
    return this.type
  }

  get getCardName () {
    return this
      .getType
      .replace(/-/g, ' ')
      .replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      .replace(/ /g, '') + 'FeedCard'
  }

  get getCreatedAt () {
    return new Date(this.createdAt)
  }

  get getFormattedCreatedAt () {
    return longDateTime(new Date(this.createdAt))
  }

  get getReferringUser () {
    return this.referringUser
  }
}
