<template>
  <div class="increaseDecrease">
    <ion-button class="increaseDecrease__button left" @click="$emit('decrease')">-</ion-button>
    <div class="increaseDecrease__contents"><slot /></div>
    <ion-button class="increaseDecrease__button right" @click="$emit('increase')">+</ion-button>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";

export default {
  components: {
    IonButton
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import 'IncreaseDecrease.sass'
</style>