import { longDate } from '@/domain/services/DateFormatter'

export default class Result {
  constructor (data) {
    this.id = data.id
    this.homeTeam = data.homeTeam
    this.visitorTeam = data.visitorTeam
    this.homeResult = data.homeResult
    this.visitorResult = data.visitorResult
    this.homeYellowCards = data.homeYellowCards
    this.visitorYellowCards = data.visitorYellowCards
    this.homeRedCards = data.homeRedCards
    this.visitorRedCards = data.visitorRedCards
    this.mvp = data.mvp
    this.mvpHome = data.mvpHome
    this.scorers = data.scorers
    this.matchDate = data.matchDate
  }

  get getId () {
    return this.id
  }

  get getHomeTeam () {
    return this.homeTeam
  }

  get getVisitorTeam () {
    return this.visitorTeam
  }

  get getHomeResult () {
    return this.homeResult
  }

  updateHomeResult (result) {
    this.homeResult = result
  }

  get getVisitorResult () {
    return this.visitorResult
  }

  updateVisitorResult (result) {
    this.visitorResult = result
  }

  get getHomeYellowCards () {
    return this.homeYellowCards
  }

  updateHomeYellowCards (cards) {
    this.homeYellowCards = cards
  }

  get getVisitorYellowCards () {
    return this.visitorYellowCards
  }

  updateVisitorYellowCards (cards) {
    this.visitorYellowCards = cards
  }

  get getHomeRedCards () {
    return this.homeRedCards
  }

  updateHomeRedCards (cards) {
    this.homeYellowCards = cards
  }

  get getVisitorRedCards () {
    return this.visitorRedCards
  }

  updateVisitorRedCards (cards) {
    this.homeYellowCards = cards
  }

  get getMatchDate () {
    if (this.matchDate === null) {
      return null
    }
    return new Date(this.matchDate)
  }

  updateMatchDate (matchDate) {
    this.matchDate = matchDate
  }

  get getFormattedMatchDate () {
    return longDate(this.getMatchDate)
  }

  get getMvp () {
    return this.mvp
  }

  get getMvpHome () {
    return this.mvpHome
  }

  updateMvp (mvp) {
    this.mvp = mvp
  }

  get getScorers () {
    return this.scorers
  }

  get getMvpSmall () {
    if (this.getMvp) {
      return [{
        playerId: this.getMvp.id,
        name: this.getMvp.name + " (local)",
        goals: 1,
        ownGoal: false,
        home: false,
      }]
    }

    return [{
      playerId: this.getMvpHome.id,
      name: this.getMvpHome.name + " (visitante)",
      goals: 1,
      ownGoal: false,
      home: true,
    }];
  }

  get getHomeScorers () {
    return this.scorers.filter(scorer => scorer.home)
  }

  get getHomeSmallScorers () {
    const homeSmallScorers = [];
    this.getHomeScorers.forEach((scorer) => {
      for (let i=0; i<scorer.goals; i++) {
        homeSmallScorers.push({
          playerId: scorer.player.id,
          name: scorer.player.name,
          goals: 1,
          ownGoal: scorer.ownGoal,
          home: true,
        });
      }
    });

    return homeSmallScorers;
  }

  get getVisitorScorers () {
    return this.scorers.filter(scorer => !scorer.home)
  }

  get getVisitorSmallScorers () {
    const visitorSmallScorers = [];
    this.getVisitorScorers.forEach((scorer) => {
      for (let i=0; i<scorer.goals; i++) {
        visitorSmallScorers.push({
          playerId: scorer.player.id,
          name: scorer.player.name,
          goals: 1,
          ownGoal: scorer.ownGoal,
          home: false,
        });
      }
    });

    return visitorSmallScorers;
  }

  updateHomeScorers (scorers) {
    this.scorers = this.scorers.filter(scorer => scorer.getHome)
    scorers = scorers.map(scorer => {
      scorer.updateHome(true)
      return scorer
    })
    this.scorers = this.scorers.concat(scorers)
  }

  updateVisitorScorers (scorers) {
    this.scorers = this.scorers.filter(scorer => !scorer.home)
    scorers = scorers.map(scorer => {
      scorer.updateHome(false)
      return scorer
    })
    this.scorers = this.scorers.concat(scorers)
  }
}
