export default class BidBatchPlayer {
  constructor (data) {
    this.id = data.id
    this.player = data.player
    this.bidsCount = data.bidsCount
  }

  get getId () {
    return this.id
  }

  get getPlayer () {
    return this.player
  }

  get getBidsCount () {
    return this.bidsCount
  }
}
