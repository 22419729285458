export default class Bid {
  constructor (data) {
    this.id = data.id,
    this.bidBatchPlayer = data.bidBatchPlayer
    this.amount = data.amount
  }

  get getId () {
    return this.id
  }

  get getBidBatchPlayer () {
    return this.bidBatchPlayer
  }

  get getAmount () {
    return this.amount
  }
}
