<template>
  <div class="loading">
    <ion-row class="ion-justify-content-start ion-align-self-center">
      <ion-col class="ion-text-center">
        <ion-spinner name="crescent" color="light"></ion-spinner>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonRow, IonCol, IonSpinner } from '@ionic/vue'

export default {
  name: 'Loading',
  components: { IonCol, IonRow, IonSpinner },
}
</script>

<style lang="sass" scoped>
  @import "./Loading.sass"
</style>
