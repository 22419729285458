<template>
  <BaseSelector
    :selectedIndex="selectedIndex"
    :singularSuffix="singularSuffix"
    :pluralSuffix="pluralSuffix"
    :theme="theme"
    :options="elementsArray"
    @update:modelValue="elementSelected"
  >
    <slot />
  </BaseSelector>
</template>

<script>
import BaseSelector from './BaseSelector'

import { toRefs } from 'vue';

export default {
  name: 'EnterResult',
  components: {
    BaseSelector
  },
  props: {
    max: {
      type: Number,
      default: 20
    },
    min: {
      type: Number,
      default: 0
    },
    selectedIndex: {
      type: Number,
      default: null
    },
    singularSuffix: {
      type: String,
      default: ''
    },
    pluralSuffix: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'light'
    }
  },
  emits: ['changed', 'blur', 'update:modelValue'],
  setup(props, { emit }) {
    let elementsArray = []
    const { max, min } = toRefs(props)

    for (let i = min.value; i <= max.value; i++) {
      elementsArray.push({
        text: i,
        value: i
      })
    }

    const elementSelected = (value) => {
      emit('update:modelValue', value)
    }

    return {
      elementsArray,

      elementSelected
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
