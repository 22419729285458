export const shortNumber = (number, decimals) => {
  if (typeof(decimals) == 'undefined') {
    decimals = 0
  }
  if (number > 1000000) {
    return parseFloat(number / 1000000).toFixed(decimals) + ' M'
  }
  if (number > 1000) {
    return Math.round(number / 1000) + ' K'
  }
  return number
}

export const longNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}