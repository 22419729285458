<template>
  <ion-page>
      <EllipsesBackground>
        <div class="splashScreen">
          <Logo/>
          <Loading/>
        </div>
      </EllipsesBackground>
  </ion-page>
</template>
<script>
import Logo from "@/components/Logo/Logo"
import Loading from "@/components/Loading/Loading"
import EllipsesBackground from "../../components/EllipsesBackground/EllipsesBackground";
import {IonPage} from "@ionic/vue";

export default {
  name: "SplashScreen",
  components: {EllipsesBackground, Loading, Logo, IonPage },
  async created() {
    await this.sleep(3000)
    this.goToSelectAuth()
  },
  methods: {
    goToSelectAuth() {
      this.$router.push({name: 'selectAuth'})
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './SplashScreen.sass'
</style>
