<template>
  <div class="Sections">
    <div class="title">
      Mejor defensa
    </div>
    <div v-for="team in teams" v-bind:key="team.getId" class="team" :class="{ 'team--deleted': team.isDeleted }">
      <InfoCard
          :avatar=team.getAvatar
          :title=team.getName
          :info=team.getFormattedGoalsAgainst
      />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import InfoCard from "@/components/Player/InfoCard";

  export default  {
  name: 'BestDefense',
  components: {
    InfoCard
  },
  setup() {
    const store = useStore()
    const teams = ref([])
    const league = store.getters.getCurrentLeague

    const data = {
      leagueId: league.getId,
      seasonId: league.getSeason.getId,
    }

    store.dispatch('getSeasonBestDefense', data)
      .then(retrievedTeams => {
        teams.value = retrievedTeams
      })

    return {
      teams
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "Sections.sass"
</style>
