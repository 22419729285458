<template>
  <div class="manager">
      <img
          class="manager__avatar"
          :src="avatar"
          alt="avatar image"
      />
    <div class="manager__info">
      <div class="manager__name">{{ name }}</div>
      <div class="manager__team">
        <span v-if="fifaId">
          <span>ID de Fifa: {{ fifaId }}</span> ·
        </span>
        <span>Equipo: {{ team }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Manager',
  props: {
    avatar: String,
    name: String,
    fifaId: String,
    team: String
  }
}
</script>
<style lang="sass" scoped>
  @import "./Manager.sass"
</style>
