import PlayerFactory from '@/domain/services/PlayerFactory'
import PichichiPlayer from '@/domain/models/PichichiPlayer'

class PichichiPlayerFactory {
  get (data) {
    const pichichiPlayer = {
      goals: data.goals,
      teamName: data.teamName,
      teamId: data.team,
      player: PlayerFactory.get(data.player),
    }

    return new PichichiPlayer(pichichiPlayer)
  }
}

export default new PichichiPlayerFactory()
