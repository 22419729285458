import PlayerFactory from '@/domain/services/PlayerFactory'
import ZamoraPlayer from '@/domain/models/ZamoraPlayer'

class ZamoraPlayerFactory {
  get (data) {
    const zamoraPlayer = {
      goalsAgainst: data.goalsAgainst,
      teamName: data.teamName,
      teamId: data.team,
      player: PlayerFactory.get(data.player),
    }

    return new ZamoraPlayer(zamoraPlayer)
  }
}

export default new ZamoraPlayerFactory()
