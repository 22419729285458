<template>
  <div class="playerStat">
    <div class="playerStat__title">{{ title }}</div>
    <div class="playerStat__value">{{ validatedValue }}</div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: "PlayerStat",
  props: {
    title: String,
    value: {
      type: [String, Number]
    }
  },
  setup(props) {
    const validatedValue = computed(() => {
      if (props.value == null || props.value == '') {
        return '-'
      }
      return props.value
    })

    return {
      validatedValue
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'PlayerStat.sass'
</style>
