<template>
  <ion-row class="team" :class="{ 'team--deleted': team.isDeleted }">
    <ion-col class="team__description" size="5">
      <img class="team__shield" :src="team.getAvatar" alt="team shield">
      <span class="team__name">{{ team.getName }}</span>
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getPoints }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.matchesPlayed }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getVictories }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getTies }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getLoses }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getGoalsFor }}
    </ion-col>
    <ion-col class="team__data" :class="{ 'team__data--deleted': team.isDeleted }" size="1">
      {{ team.getGoalsAgainst }}
    </ion-col>
  </ion-row>
</template>

<script>
import { IonCol, IonRow } from '@ionic/vue'

export default {
  name: "InfoRow",
  components: { IonCol, IonRow },
  props: {
    team: {
      type: Object
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './InfoRow.sass'
</style>
