<template>
  <ion-page>
    <ion-content>
      <div class="leagueWelcome">
        <Logo/>
        <div class="leagueWelcome__buttons">
          <PrimaryButton text="Crear nueva liga" @click="openModal('createLeagueModal')"/>
          <br/>
          <PrimaryButton text="Unirme a una liga" @click="openModal('joinLeagueModal')"/>
        </div>

        <CustomModal
          cssClass="register"
          :openedModal="modalHandler.createLeagueModal"
          @triggerModal="closeModal('createLeagueModal')"
        >
          <CreateLeagueForm
            :invertedColor="true"
            @leagueCreated="leagueCreatedHandler"
          />
        </CustomModal>
        <CustomModal
          :openedModal="modalHandler.joinLeagueModal"
          @triggerModal="closeModal('joinLeagueModal')"
        >
          <JoinLeagueForm
            invertedColor
            @closeModal="leagueJoinedHandler"
          />
        </CustomModal>
        <FullModal
          :openedModal="modalHandler.finishLeagueCreation"
          hideBackButton
          :backdropDismiss="false"
        >
          <LeagueCreation
            startingCard="step2"
            :creationData="formData"
            fromOnboarding
          />
        </FullModal>
        <FullModal
          :openedModal="modalHandler.finishLeagueJoin"
          hideBackButton
          :backdropDismiss="false"
        >
          <LeagueJoin
            startingCard="step2"
            fromOnboarding
            :league="league"
          />
        </FullModal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { ref } from "vue"
import { IonContent, IonPage } from "@ionic/vue"
import CustomModal from "@/components/CustomModal/CustomModal"
import Logo from "@/components/Logo/Logo"
import JoinLeagueForm from "@/components/LeagueForms/JoinLeagueForm"
import PrimaryButton from "@/components/Button/PrimaryButton"
import CreateLeagueForm from '@/components/LeagueForms/CreateLeagueForm.vue'
import FullModal from '@/components/FullModal/FullModal.vue'
import LeagueCreation from '@/components/LeagueCreation/LeagueCreation.vue'
import LeagueJoin from '@/components/LeagueCreation/LeagueJoin.vue'

export default {
  name: "LeagueWelcome",
  components: {
    IonPage,
    CustomModal,
    JoinLeagueForm,
    Logo,
    PrimaryButton,
    IonContent,
    CreateLeagueForm,
    FullModal,
    LeagueCreation,
    LeagueJoin,
  },
  setup() {
    const formData = ref({})
    const league = ref()

    const modalHandler = ref({
      joinLeagueModal: false,
      createLeagueModal: false,
      finishLeagueCreation: false,
      finishLeagueJoin: false,
    })

    const openModal = (variable) => {
      modalHandler.value[variable] = true
    }
    const closeModal = (variable) => {
      modalHandler.value[variable] = false
    }

    const leagueCreatedHandler = (form) => {
      closeModal('createLeagueModal')
      modalHandler.value['finishLeagueCreation'] = true
      formData.value = form
    }

    const leagueJoinedHandler = (selectedLeague) => {
      closeModal('joinLeagueModal')
      modalHandler.value['finishLeagueJoin'] = true
      league.value = selectedLeague
    }

    return {
      modalHandler,
      formData,
      league,
      openModal,
      closeModal,
      leagueCreatedHandler,
      leagueJoinedHandler,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'LeagueWelcome.sass'
</style>
