<template>
  <div class="table" :key="league">
    <ion-row class="table__header">
      <ion-col class="table__teamDescription" size="5"> Equipo</ion-col>
      <ion-col size="1" class="table__title">Pt</ion-col>
      <ion-col size="1" class="table__title">PJ</ion-col>
      <ion-col size="1" class="table__title">PG</ion-col>
      <ion-col size="1" class="table__title">PE</ion-col>
      <ion-col size="1" class="table__title">PP</ion-col>
      <ion-col size="1" class="table__title">GF</ion-col>
      <ion-col size="1" class="table__title">GC</ion-col>
    </ion-row>

    <InfoRow
        v-for="item in items"
        :team="item"
        :key="item.name"
        @click="showTeam(item)"
    />
  </div>
  <FullModal
    :openedModal="modals['openPlayerListModal']"
    @onPressBackButton="hideModal('openPlayerListModal')"
  >
    <PlayerList
      :team="selectedTeam"
      :league="league"
    />
  </FullModal>
</template>

<script>
import { IonCol, IonRow } from '@ionic/vue'
import InfoRow from '../InfoRow/InfoRow'
import FullModal from '../../FullModal/FullModal.vue'
import PlayerList from '../../PlayerList/PlayerList.vue'
import { reactive, ref } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  name: "InfoTable",
  components: {
    IonCol,
    IonRow,
    InfoRow,
    FullModal,
    PlayerList
  },
  props: {
    items: {
      type: Array
    }
  },
  setup() {
    const store = useStore()
    const modals = reactive({
      openPlayerListModal: false
    })
    const league = store.getters.getCurrentLeague
    const selectedTeam = ref()

    const showTeam = (team) => {
      selectedTeam.value = team
      modals.openPlayerListModal = true
    }

    const hideModal = (modal) => {
      modals[modal] = false
    }

    return {
      modals,
      league,
      selectedTeam,

      showTeam,
      hideModal,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './InfoTable.sass'
</style>
