<template>
  <div class="listTeams">
    <div
      v-for="leagueTeam in league.getSeason.getTeams"
      :key="leagueTeam"
      class="team"
      :class="{ 'team--deleted': leagueTeam.isDeleted }"
    >
      <InfoCard
        :avatar=leagueTeam.getAvatar
        :title=leagueTeam.getName
        :subtitle=leagueTeam.getManager.getUsername
        :info=leagueTeam.getFormattedBudgetShort
        @click="showPlayerList(leagueTeam)"
      />
    </div>
    <FullModal
      :openedModal="modals['openPlayerListModal']"
      @onPressBackButton="hideModal('openPlayerListModal')"
    >
      <template v-slot:buttonLeftSide>
        <div>
          <BallBadge @click="modals.showSponsorModal = true">{{ selectedTeam.getSponsor.getName }}</BallBadge>
        </div>
      </template>
      <PlayerList
        :team="selectedTeam"
        :league="league"
      />
    </FullModal>
    <FullModalImage
      :openedModal="modals.showSponsorModal"
      @onPressBackButton="hideModal('showSponsorModal')"
      :backgroundImage="selectedTeam.getSponsor ? selectedTeam.getSponsor.getImage : ''"
    >
      <SponsorDetail
        :name="selectedTeam.getSponsor.getName"
        :wins="selectedTeam.getSponsor.getWins"
        :ties="selectedTeam.getSponsor.getTies"
        :loses="selectedTeam.getSponsor.getLoses"
        :maxScorer="selectedTeam.getSponsor.getMaxScorer"
        :maxMvp="selectedTeam.getSponsor.getMaxMvp"
        :cards="selectedTeam.getSponsor.getCards"
        :zamora="selectedTeam.getSponsor.getZamora"
        :changes="selectedTeam.getSponsor.getChanges"
        :bestSquad="selectedTeam.getSponsor.getBestSquad"
      />
    </FullModalImage>
  </div>
</template>

<script>
import League from "@/domain/models/League"
import { reactive, ref } from '@vue/reactivity'
import FullModal from '@/components/FullModal/FullModal.vue'
import InfoCard from '@/components/Player/InfoCard.vue'
import { useStore } from 'vuex'
import BallBadge from '@/components/Badge/BallBadge.vue'
import SponsorDetail from '@/components/SponsorCard/SponsorDetail.vue'
import FullModalImage from '@/components/FullModal/FullModalImage.vue'
import PlayerList from '@/components/PlayerList/PlayerList.vue'

export default  {
  name: 'ListTeams',
  components: {
    InfoCard,
    FullModal,
    BallBadge,
    SponsorDetail,
    FullModalImage,
    PlayerList,
  },
  props: {
    league: League
  },
  setup() {
    const store = useStore()
    const selectedTeam = ref({})
    const modals = reactive({
      openPlayerListModal: false,
      showSponsorModal: false,
    })
    const team = store.getters.getCurrentTeam

    const showPlayerList = (team) => {
      selectedTeam.value = team
      modals.openPlayerListModal = true
    }

    const hideModal = (modal) => {
      modals[modal] = false
    }

    return {
      team,
      selectedTeam,
      modals,

      showPlayerList,
      hideModal,
    }
  }
}
</script>

<style lang="sass" scoped>
 @import './ListTeams.sass'
</style>
