import BidBatchPlayerFactory from "@/domain/services/BidBatchPlayerFactory"
import PositionBidBatchPlayer from "@/domain/models/PositionBidBatchPlayer"
import {
  POSITION_GOALKEEPER,
  POSITION_DEFENSE,
  POSITION_CENTERS,
  POSITION_STRIKERS
} from "@/domain/models/Player"

class PositionBidBatchPlayerFactory {
  get (data) {
    const index = {
      [POSITION_GOALKEEPER]: 1,
      [POSITION_DEFENSE]: 2,
      [POSITION_CENTERS]: 3,
      [POSITION_STRIKERS]: 4,
    }
    const positionBidBatchPlayer = {
      position: data.name,
      sortIndex: index[data.name],
      players: create(data.playersGroups)
    }

    return new PositionBidBatchPlayer(positionBidBatchPlayer)
  }
}

function create(players) {
  return players.map(player => BidBatchPlayerFactory.get(player))
}

export default new PositionBidBatchPlayerFactory()
