<template>
  <ion-modal
      :is-open="openedModal"
      :animated="false"
      cssClass="fullModal"
      :swipeToClose="swipeToClose"
      @didDismiss="$emit('onPressBackButton')"
      :backdropDismiss="backdropDismiss"
  >
      <ion-content
        scroll-x
        class="modalContent"
      >
        <div class="modalContent__top" slot="fixed">
          <slot name="back">
            <div class="modalContent__top__fixed">
              <div v-if="!hideBackButton">
                <BackButton @onClick="$emit('onPressBackButton')" />
              </div>
              <slot name="buttonLeftSide" />
            </div>
          </slot>
        </div>
        <div class="modalContent__content">
          <slot></slot>
        </div>
      </ion-content>
  </ion-modal>
</template>

<script>
import { IonModal, IonContent } from '@ionic/vue'
import BackButton from "@/components/Menu/BackButton";

export default {
  name: "FullModal",
  components: { IonModal, BackButton, IonContent },
  emits: ['onPressBackButton'],
  props: {
    openedModal: Boolean,
    backgroundImage: String,
    swipeToClose: {
      type: Boolean,
      default: true
    },
    hideBackButton: {
      type: Boolean,
      default: false
    },
    backdropDismiss: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="sass">
  @import './FullModal.sass'
</style>
