<template>
  <div class="leagueStatus">
    <div class="leagueStatus__message small">
      Ahora toca esperar a que se abran las pujas. Mientras, puedes
      ir entrenando a tu equipo en FIFA.
    </div>
    <div class="leagueStatus__panel">
      <SmallBadge class="leagueStatus__panel__badge">Draft ✅</SmallBadge>
      <div class="leagueStatus__panel__countdown">
        Finaliza en
        <div class="leagueStatus__panel__countdown__remining">
          <TimeTo :date="remaining" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallBadge from '../Badge/SmallBadge.vue'
import TimeTo from '@/components/TimeTo/TimeTo.vue'

export default {
  components: {
    SmallBadge,
    TimeTo
  },
  props: {
    remaining: Date
  },
  setup(props) {
    setInterval(() => {
      if (new Date().getTime() >= props.remaining.getTime()) {
        window.location.reload()
      }
    }, 1000)
  },
}
</script>

<style lang="sass" scoped>
  @import './LeagueStatus.sass'
</style>
