<template>
  <VForm ref="reference" @submit="$emit('onSubmit')">
    <slot></slot>
  </VForm>
</template>

<script>
import { Form } from "vee-validate";
import { configure } from "vee-validate";
import { localize } from '@vee-validate/i18n';
import es from '@vee-validate/i18n/dist/locale/es.json';

export default {
  name: "Form",
  components: {
    VForm: Form
  },
  setup() {
    configure({
      generateMessage: localize('es', es)
    })
  },
  methods: {
    validate() {
      return this.$refs.reference.validate();
    }
  }
}
</script>

<style lang="sass">
  @import './Form.sass'
</style>
