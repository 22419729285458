import PlayerFactory from '@/domain/services/PlayerFactory'
import TeamPlayer from "@/domain/models/TeamPlayer"

class TeamPlayerFactory {
  get (data) {
    const teamPlayer = {
      id: data.id,
      player: PlayerFactory.get(data.player),
      locked: data.locked,
      receivedDirectBuy: data.receivedDirectBuy    }

    return new TeamPlayer(teamPlayer)
  }
}

export default new TeamPlayerFactory()
