<template>
  <div class="results" :key="league">
    <div class="results__noResults" v-if="!isRegular">
      Cuando empiece la liga, verás aquí tus encuentros
    </div>
    <div v-if="isRegular">
      <div v-for="result in results" v-bind:key="result">
        <ResultCard
          :id="result.getId"
          :homeAvatar="result.getHomeTeam.getAvatar"
          :homeName="result.getHomeTeam.getName"
          :homeResult="result.getHomeResult"
          :visitorAvatar="result.getVisitorTeam.getAvatar"
          :visitorName="result.getVisitorTeam.getName"
          :visitorResult="result.getVisitorResult"
          :date="result.getFormattedMatchDate"
          :hasResults="result.getMatchDate!==null"
          :isLocal="result.getHomeTeam.getId == $store.getters.getCurrentTeam.getId"

          @showResult="showResult"
          @enterResult="enterResult"
          @cantEnterResult="cantEnterResult"
        />
      </div>
    </div>
  </div>
  <FullModal
    :openedModal="showResultPopup == 'enter'"
    @onPressBackButton="close"
  >
    <EnterResult
      :id="result.getId"
      :homeTeamId="result.getHomeTeam.getId"
      :visitorTeamId="result.getVisitorTeam.getId"
      :homeAvatar="result.getHomeTeam.getAvatar"
      :homeName="result.getHomeTeam.getName"
      :visitorAvatar="result.getVisitorTeam.getAvatar"
      :visitorName="result.getVisitorTeam.getName"
      @updated="closeRefresh"
    />
  </FullModal>
  <FullModal
    :openedModal="showResultPopup == 'cantEnter'"
    @onPressBackButton="close"
  >
    <CantEnterResult
      :id="result.getId"
      :homeAvatar="result.getHomeTeam.getAvatar"
      :homeName="result.getHomeTeam.getName"
      :visitorAvatar="result.getVisitorTeam.getAvatar"
      :visitorName="result.getVisitorTeam.getName"
    />
  </FullModal>
  <FullModal
    :openedModal="showResultPopup == 'show'"
    @onPressBackButton="close"
  >
    <ShowResult :result="result" @saved="close()" />
  </FullModal>
  <PopupModal
    :is-open="openInfoModal"
    @closed="hideInfoModal"
  >
    <div class="popupModal__standard">
      <div>
        Es la hora de competir, edita tus plantillas en FIFA e invita a tus amigos a
        partido amistoso online. Sube los resultados a la plataforma y demuestra quien
        es el mejor manager! Apunta los goleadores, tarjetas y mvp para que las
        clasificaciones sean más divertidas!
      </div>
      <div>
        <SmallButton text="Cerrar" @onClick="hideInfoModal" />
      </div>
    </div>
  </PopupModal>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ResultCard from "@/components/ResultCard/ResultCard";
import CantEnterResult from "@/components/ResultPopups/CantEnterResult";
import ShowResult from "@/components/ResultPopups/ShowResult";
import EnterResult from "@/components/ResultPopups/EnterResult";
import FullModal from "@/components/FullModal/FullModal";
import {
  STATUS_REGULAR,
} from "@/domain/models/Season";
import { uuid } from 'vue-uuid'
import PopupModal from '@/components/PopupModal/PopupModal.vue';
import SmallButton from '@/components/Button/SmallButton.vue'

export default {
  name: 'Results',
  components: {
    ResultCard,
    CantEnterResult,
    ShowResult,
    EnterResult,
    FullModal,
    PopupModal,
    SmallButton
  },
  setup() {
    const store = useStore()
    const results = ref()
    const result = ref()
    const showResultPopup = ref('')
    const openInfoModal = ref(!store.getters.getResultsPopupShown)

    const getTeamResults = async () => {
      const league = store.getters.getCurrentLeague

      return await store.dispatch('getTeamResults', {
        leagueId: league.getId,
        seasonId: league.getSeason.getId
      })
      .then((res) => {
        results.value = res
      })
    }

    const league = computed(() => {
      const league = store.getters.getCurrentLeague
      getTeamResults()

      return league
    })

    const isRegular = computed(() => store.getters.getCurrentLeague.getSeason.getStatus == STATUS_REGULAR)

    const showResult = (id) => {
      result.value = results.value.find(r => r.getId === id)
      showResultPopup.value = 'show'
    }
    const enterResult = (id) => {
      result.value = results.value.find(r => r.getId === id)
      showResultPopup.value = 'enter'
    }
    const cantEnterResult = (id) => {
      result.value = results.value.find(r => r.getId === id)
      showResultPopup.value = 'cantEnter'
    }
    const close = () => {
      setTimeout(() => {
        showResultPopup.value = ''
      }, 0)
    }
    const closeRefresh = (updatedResult, scorers) => {
      const league = store.getters.getCurrentLeague
      store.commit('showLockScreen')

      store.dispatch('updateResult', {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        resultId: result.value.id,
        data: updatedResult
      })
      .then(() => {
        if (scorers.length > 0) {
          store.dispatch('createScorer', {
            leagueId: league.getId,
            seasonId: league.getSeason.getId,
            resultId: result.value.id,
            data: {
              scorers: scorers.map(scorer => {
                scorer.id = uuid.v4()
                delete scorer.name
                return scorer
              })
            }
          })
          .then(() => {
          })
          .catch(error => {
            console.error(['createScorer', error])
          })
        }
      })
      .catch((error) => {
        console.error(['updateResult', error])
      })
      .finally(() => {
        store.commit('hideLockScreen')
      })

      store.dispatch('getLeague', league.getId)
        .then(() => {
          close()
        })
    }

    const hideInfoModal = () => {
      openInfoModal.value = false
      store.commit("resultsPopupShown")
    }

    return {
      results,
      result,
      league,
      isRegular,
      showResultPopup,
      openInfoModal,

      showResult,
      enterResult,
      cantEnterResult,
      close,
      closeRefresh,
      hideInfoModal,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'Results.sass'
</style>
