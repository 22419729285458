<template>
  <div class="result" :class="{ local: isLocal }" @click="throwEvent">
    <div class="result__avatar">
      <div>
        <img :src="homeAvatar">
      </div>
    </div>
    <div class="result__info">
      <div class="result__info__names">
        <div class="home">{{ homeName }}</div>
        <div class="vs">vs</div>
        <div class="visitor">{{ visitorName }}</div>
      </div>
      <div class="result__info__goals" v-if="hasResults">
        <div>{{ homeResult }}</div>
        <div>-</div>
        <div>{{ visitorResult }}</div>
      </div>
      <div class="result__info__date" v-if="hasResults && date">
        <div>{{ date }}</div>
      </div>
    </div>
    <div class="result__avatar">
      <div>
        <img :src="visitorAvatar">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultCard',
  components: { },
  props: {
    id: String,
    homeAvatar: String,
    homeName: String,
    homeResult: Number,
    visitorAvatar: String,
    visitorName: String,
    visitorResult: Number,
    date: String,
    hasResults: Boolean,
    isLocal: Boolean
  },
  emits: ['showResult', 'enterResult', 'cantEnterResult'],
  setup(props, { emit }) {
    const throwEvent = () => {
      if (props.hasResults) {
        emit('showResult', props.id)
        return
      }
      if (props.isLocal) {
        emit('enterResult', props.id)
        return
      }
      emit('cantEnterResult', props.id)
    }

    return {
      throwEvent
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'ResultCard.sass'
</style>
