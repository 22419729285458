<template>
  <div class="bidForm">
    <ion-row>
      <ion-col>
        <div class="bidForm__title">
          Pujar por {{ player.getName }}
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="bidForm__wallet">
          <img src="/assets/wallet.svg">
          <span>{{ formattedBudget }}</span>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="bidForm__wallet">
          <IncreaseDecrease
            @increase="increase"
            @decrease="decrease"
          >
            <ion-input
              class="input-amount"
              :value="formattedBidAmount"
              @ionFocus="removeFormat"
              @ionBlur="applyFormat"
            />
          </IncreaseDecrease>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="bidForm__button">
          <ion-button
            v-if="budget >= amount"
            class="bid"
            :disabled="!canBidMin && amount == minAmount"
            mode="ios"
            @click="$emit('bid', amount)"
          >
            Pujar
          </ion-button>
          <ion-button
            v-if="budget < amount"
            class="error"
            mode="ios"
          >
            <img src="/assets/danger.svg"> no tienes suficiente dinero
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonButton,
  IonInput
} from "@ionic/vue";
import Player from "@/domain/models/Player.js";
import { longCurrency } from "@/domain/services/CurrencyFormatter.js";
import IncreaseDecrease from '@/components/IncreaseDecrease/IncreaseDecrease.vue';
import { ref, unref } from '@vue/reactivity';

export default {
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IncreaseDecrease,
  },
  props: {
    player: Player,
    budget: Number,
    minAmount: Number,
    canBidMin: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const formattedBudget = longCurrency(props.budget)
    const min = unref(props.minAmount)
    const step = 1000
    const amount = ref(min)
    const formattedBidAmount = ref(longCurrency(amount.value))

    const increase = () => {
      amount.value += step
      formattedBidAmount.value = longCurrency(amount.value)
    }

    const decrease = () => {
      if (amount.value <= min) {
        return
      }
      amount.value -= step
      formattedBidAmount.value = longCurrency(amount.value)
    }

    const removeFormat = (ev) => {
      ev.target.value = amount.value
    }

    const applyFormat = (ev) => {
      amount.value = parseInt(ev.target.value.toString().replace(/[^0-9]+/, ''))
      if (amount.value < min) {
        amount.value = min
      }
      ev.target.value = longCurrency(amount.value)
    }

    return {
      formattedBudget,
      formattedBidAmount,
      amount,

      removeFormat,
      applyFormat,
      increase,
      decrease
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'BidForm.sass'
</style>