<template>
  <ion-page class="ion-page">
    <ion-content scroll-x>
      <div class="draft">
        <div class="draft__header">
          <div class="draft__header__title">
            Draft <img src="/assets/info.svg" width="20" @click="openInfo" />
          </div>
        </div>
        <div class="draft__message">
          <div class="draft__message__text">
            Selecciona un jugador de cada posición
          </div>
        </div>
        <div class="draft__players">
          <Form ref="draftPlayersForm" @onSubmit="save">
            <div
              v-for="draftedPlayer in draftedPlayers"
              :key="draftedPlayer"
              class="draft__players__player-group"
            >
              <Field
                :name="draftedPlayer.getId"
                label="jugador"
                v-model="selectedPlayers[draftedPlayer.getId].value"
                v-slot="{ field, meta }"
                rules="required"
              >
                <PlayerGroup
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  :id="draftedPlayer.getId"
                  :name="draftedPlayer.getName"
                  :players="draftedPlayer.getPlayers"
                  @changed="selectedPlayer"
                  @playerClick="openPlayerInfo"
                />
              </Field>
              <ErrorMessage :name="draftedPlayer.getId" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
            </div>
          </Form>
        </div>
        <div slot="fixed" class="draft__footer">
          <PrimaryButton text="Guardar" @onClick="save"></PrimaryButton>
        </div>
      </div>
      <FullModal
        :openedModal="openPlayerInfoModal"
        @onPressBackButton="hideModal"
      >
        <PlayerProfile
          :player="playerInModal.getPlayer"
        />
      </FullModal>
      <PopupModal
        :is-open="openInfoModal"
        @closed="hideInfoModal"
      >
        <div class="popupModal__standard">
          <div>
            Estos jugadores, no consumen dinero de las finanzas de tu club cuando los eliges,
            pero ten en cuanta sus salarios y la masa salarial elegida para la liga.<br /><br />
            Piensa que si algún jugador no te gusta, más tarde podrás despedirlo recibiendo
            para las arcas de tu club la mitad de su cláusula.
          </div>
          <div>
            <SmallButton text="Cerrar" @onClick="hideInfoModal" />
          </div>
        </div>
      </PopupModal>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, onIonViewWillEnter } from '@ionic/vue';
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import PlayerGroup from '@/views/Tabs/Market/PlayerGroup.vue';
import PrimaryButton from '@/components/Button/PrimaryButton.vue';
import Form from "@/components/Input/Form"
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"
import { useRouter } from 'vue-router';
import FullModal from '@/components/FullModal/FullModal.vue';
import PlayerProfile from '@/components/Player/PlayerProfile.vue';
import PopupModal from '../../components/PopupModal/PopupModal.vue';
import SmallButton from '@/components/Button/SmallButton.vue'

export default  {
  name: 'TabMarket',
  components: {
    IonContent,
    IonPage,
    PlayerGroup,
    PrimaryButton,
    Form,
    Field,
    ErrorMessage,
    FullModal,
    PlayerProfile,
    PopupModal,
    SmallButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const draftedPlayers = ref([])
    const selectedPlayers = ref({})
    const playerInModal = ref({})
    const draftPlayersForm = ref()
    const openPlayerInfoModal = ref(false)
    const openInfoModal = ref(false)

    onIonViewWillEnter(() => {
      fetchDraftPlayers()
    })

    const openPlayerInfo = (player) => {
      playerInModal.value = player
      openPlayerInfoModal.value = true
    }

    const openInfo = () => {
      openInfoModal.value = true
    }

    const hideModal = () => {
      openPlayerInfoModal.value = false
    }

    const hideInfoModal = () => {
      openInfoModal.value = false
    }

    const fetchDraftPlayers = () => {
      const league = store.getters.getCurrentLeague
      const team = store.getters.getCurrentTeam
      selectedPlayers.value = {}
      draftedPlayers.value = []
      if (team.getTeamPlayers.length > 0) {
        router.push({ name: 'news', params: { id: league.getId } })
        return
      }

      store.commit('showLockScreen')
      store.dispatch('getTeamDraftedPlayers', {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId
      })
        .then((groups) => {
          groups.forEach(group => {
            selectedPlayers.value[group.getId] = {}
          })
          draftedPlayers.value = groups.sort(sortGroup)
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const sortGroup = (group1, group2) => {
      if (group1.getSortIndex > group2.getSortIndex) {
        return 1
      }
      if (group1.getSortIndex < group2.getSortIndex) {
        return -1
      }
      return 0
    }

    const save = () => {
      const league = store.getters.getCurrentLeague
      const team = store.getters.getCurrentTeam
      draftPlayersForm.value.validate()
        .then(({ valid }) => {
          if (!valid) {
            return
          }
          store.commit('showLockScreen')
          const data = {
            leagueId: league.getId,
            teamId: team.getId,
            players: {}
          }
          for (var index in selectedPlayers.value) {
            let elem = selectedPlayers.value[index]
            if (typeof(data.players[elem.name]) == 'undefined') {
              data.players[elem.name] = []
            }
            data.players[elem.name].push(elem.value)
          }
          store.dispatch('saveTeamPlayers', data)
            .then(() => {
              store.dispatch('getLeague', league.getId)
                .then(() => {
                  router.push({ name: 'news', params: { id: league.getId } })
                })
            })
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const selectedPlayer = ({ playerGroupName, value, id }) => {
      selectedPlayers.value[id] = {
        value: value,
        name: playerGroupName
      }
    }

    defineRule("required", required)

    return {
      openPlayerInfoModal,
      openInfoModal,
      draftedPlayers,
      playerInModal,
      draftPlayersForm,
      selectedPlayers,

      openInfo,
      fetchDraftPlayers,
      hideInfoModal,
      selectedPlayer,
      save,
      openPlayerInfo,
      hideModal
    }
  }
}
</script>

<style lang="sass" scoped>
 @import 'Draft.sass'
</style>
