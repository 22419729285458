import { shortCurrency, longCurrency } from '../services/CurrencyFormatter'

export default class Offer {
  constructor (data) {
    this.id = data.id,
    this.teamFrom = data.teamFrom
    this.teamTo = data.teamTo
    this.player = data.player
    this.amount = data.amount
    this.accepted = data.accepted
    this.expired = data.expired
  }

  get getId () {
    return this.id
  }

  get getTeamFrom () {
    return this.teamFrom
  }

  get getTeamTo () {
    return this.teamTo
  }

  get getPlayer () {
    return this.player
  }

  get getAmount () {
    return this.amount
  }

  get getFormattedAmountShort () {
    return shortCurrency(this.getAmount)
  }

  get getFormattedAmountLong () {
    return longCurrency(this.getAmount)
  }

  get isAccepted () {
    return this.accepted
  }

  get isExpired () {
    return this.expired
  }

  setAccepted (accepted) {
    this.accepted = accepted
  }
}
