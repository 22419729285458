<template>
  <ion-row>
    <ion-col class="ion-text-center">
      <img src="/assets/logo.png"/>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonCol, IonRow } from '@ionic/vue'

export default {
  name: 'Logo',
  components: { IonCol, IonRow },
}
</script>
