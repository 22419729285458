<template>
  <section class="closePhaseButton">
    <PrimaryButton text="Cerrar fase" @onClick="openPhaseForwardModal = true" />
    <PopupModal
      :is-open="openPhaseForwardModal"
      @closed="hidePhaseForwardModal">
      <div class="popupModal__standard">
        <div class="modalHeader">
          <strong>¿Estás seguro de que quieres cerrar esta fase?</strong>
        </div>
        <div>
          Actualmente estás en la fase de <strong>{{ messages.from[status] }}</strong>.
          Al cerrarla pasarás a la fase de <strong>{{ messages.to[status] }}</strong>.
        </div>
        <div class="modalButtons">
          <SmallButton text="Cancelar" color="light" @onClick="hidePhaseForwardModal" />

          <SmallButton text="Continuar" @onClick="handlePhaseForward" />
        </div>
      </div>
    </PopupModal>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from 'vuex'
import PrimaryButton from "../Button/PrimaryButton.vue";
import PopupModal from "../PopupModal/PopupModal.vue";
import SmallButton from "../Button/SmallButton.vue";
import { STATUS_NONE, STATUS_BIDS, STATUS_OPEN_MARKET, STATUS_REGULAR } from "@/domain/models/Season"

export default defineComponent({
  name: "ClosePhaseButton",
  components: {PrimaryButton, SmallButton, PopupModal},
  setup() {
    const messages = {
      from: {
        [STATUS_NONE]: "esperando más jugadores",
        [STATUS_BIDS]: "pujas",
        [STATUS_OPEN_MARKET]: "mercado abierto",
        [STATUS_REGULAR]: "temporada regular",
      },
      to: {
        [STATUS_NONE]: "pujas",
        [STATUS_BIDS]: "mercado libre",
        [STATUS_OPEN_MARKET]: "temporada regular",
        [STATUS_REGULAR]: "",
      }
    };
    const store = useStore()
    const league = store.getters.getCurrentLeague
    const openPhaseForwardModal = ref(false);
    const status = league.getSeason.getStatus;
    const data = {
      leagueId: league.getId,
      seasonId: league.getSeason.getId,
    }

    const handlePhaseForward = () => {
      let promise;
      switch (status) {
        case STATUS_NONE:
          promise = moveToBid();
          break;
        case STATUS_BIDS:
        promise = moveToOpenMarket();
          break;
        case STATUS_OPEN_MARKET:
        promise = moveToRegular();
          break;
        default:
          break;
      }
      promise.then(() => {
        window.location.reload();
      });
    }

    const moveToBid = () => {
      return store.dispatch('moveToBidPhase', data)
    }

    const moveToOpenMarket = () => {
      return store.dispatch('moveToOpenMarketPhase', data)
    }

    const moveToRegular = () => {
      return store.dispatch('moveToRegularPhase', data)
    }

    const hidePhaseForwardModal = () => {
      openPhaseForwardModal.value = false;
    };

    return {
      openPhaseForwardModal,
      status,
      messages,
      hidePhaseForwardModal,
      handlePhaseForward
    };
  }
});
</script>

<style lang="scss" scoped>
  @import './ClosePhaseButton.sass'
</style>
