export default class MVPPlayer {
  constructor (data) {
    this.quantity = data.quantity
    this.teamId = data.teamId
    this.teamName = data.teamName
    this.player = data.player
  }

  get getQuantity () {
    return this.quantity
  }

  get getFormattedQuantity () {
    return this.getQuantity + ' T'
  }

  get getTeamName () {
    return this.teamName
  }

  get getTeamId () {
    return this.teamId
  }

  get getPlayer () {
    return this.player
  }
}
