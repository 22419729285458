export default class DraftedPlayersGroup {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.players = data.players
    this.sortIndex = data.sortIndex
  }

  get getId () {
    return this.id
  }

  get getName () {
    return this.name
  }

  get getPlayers () {
    return this.players
  }

  get getSortIndex () {
    return this.sortIndex
  }
}
