<template>
  <ion-modal
    :is-open="isOpen"
    :animated="false"
    :cssClass="'popupModal ' + extraClass"
    @didDismiss="$emit('closed')"
  >
    <div class="popupModal__inner-content">
      <slot />
    </div>
  </ion-modal>
</template>

<script>
import {
  IonModal,
} from "@ionic/vue"

export default {
  components: {
    IonModal,
  },
  emits: ['closed'],
  props: {
    isOpen: Boolean,
    extraClass: String,
  },
}
</script>

<style lang="sass" scoped>
  @import 'PopupModal.sass'
</style>

<style lang="sass">
.popupModal
  &__standard
    color: white
    display: flex
    flex-direction: column
    height: 100%
    align-items: center
    justify-content: space-around
    padding: 20px
    gap: 10px
</style>
