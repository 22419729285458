<template>
  <div class="Badge"><slot /></div>
</template>

<script>
export default {
  name: 'Badge'
}
</script>

<style lang="sass" scoped>
  @import './Badge.sass'
</style>
