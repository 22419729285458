<template>
  <ion-page class="ion-page header">
    <ion-header class="ion-no-border">
      <MenuHeaderWithInfo
        class="menu__button"
      />
      <NavBar
        :segments="segments"
        :activeTab="activeTab"
        @changeActiveTab="changeActiveTab"
        class="navBar"
      />
    </ion-header>
    <ion-content>
      <div class="tabContent">
        <Classification
          v-if="activeTab === segments[0].key"
          :league="league"
        />
        <Rankings
          v-if="activeTab === segments[1].key"
        />
        <Teams
          v-if="activeTab === segments[2].key"
          :league="league"
        />
        <Managers
          v-if="activeTab === segments[3].key"
          :league="league"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import {
  IonPage,
  IonContent,
  IonHeader
} from '@ionic/vue';
import Managers from "@/views/Managers/Managers.vue";
import Teams from "@/views/Teams/ListTeams.vue";
import Rankings from "@/views/Rankings/Rankings.vue";
import Classification from "@/views/Classification/Classification.vue";
import NavBar from "@/components/NavBar/NavBar";
import MenuHeaderWithInfo from "@/components/Menu/MenuHeaderWithInfo";

export default defineComponent({
  name: 'TabLeague',
  components: {
    MenuHeaderWithInfo,
    NavBar,
    Classification,
    Rankings,
    Teams,
    Managers,
    IonPage,
    IonContent,
    IonHeader
  },
  setup() {
    const store = useStore()
    const segments = [
        { key: 'classification', label: 'Clasificación' },
        { key: 'rankings', label: 'Rankings' },
        { key: 'teams', label: 'Equipos' },
        { key: 'managers', label: 'Managers' }
      ]
    const activeTab = ref('classification')
    const league = computed(() => store.getters.getCurrentLeague)

    const changeActiveTab = (selectedTab) => {
      activeTab.value = selectedTab
    }

    return {
      segments,
      activeTab,
      league,

      changeActiveTab
    }
  }
});

</script>

<style lang="sass" scoped>
  @import './TabLeague.sass'
</style>
