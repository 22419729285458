import axios from '@/plugins/axios';
import { uuid } from 'vue-uuid';
import DraftedPlayersGroupFactory from '@/domain/services/DraftedPlayersGroupFactory';

const URL_CREATE_TEAM = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams'
const URL_GET_TEAM = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}'
const URL_UPDATE_TEAM = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}'
const URL_GET_TEAM_DRAFTED_PLAYERS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/draftedPlayers'
const URL_SAVE_TEAM_PLAYERS = '/api/users/me/teams/{teamId}/players'

export function createTeam(leagueId, seasonId, sponsorId, teamName) {
  const url = URL_CREATE_TEAM
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  const data = {
    id: uuid.v4(),
    name: teamName,
    sponsorId: sponsorId
  }
  return axios.post(url, data)
    .then(() => data)
    .catch(error => Promise.reject(error.response.data))
}

export function getTeam(leagueId, seasonId, teamId) {
  const url = URL_GET_TEAM
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)

  return axios.get(url)
    .then((res) => res)
    .catch(error => Promise.reject(error.response.data))
}

export function getTeamDraftedPlayers(leagueId, seasonId, teamId) {
  const url = URL_GET_TEAM_DRAFTED_PLAYERS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)

  return axios.get(url)
    .then(res => res.data.map(draftedPlayersGroup => DraftedPlayersGroupFactory.get(draftedPlayersGroup)))
    .catch(error => Promise.reject(error.response.data))
}

export function saveTeamPlayers(teamId, players) {
  const url = URL_SAVE_TEAM_PLAYERS
    .replace('{teamId}', teamId)

  return axios.put(url, players)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function updateTeam(leagueId, seasonId, teamId, sponsorId) {
  const url = URL_UPDATE_TEAM
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
  const data = {
    sponsorId: sponsorId
  }
  return axios.put(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}
