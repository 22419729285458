<template>
  <div class="players">
    <PlayerGroup
      id="goalkeepers"
      name="goalkeeper"
      :showRadio="false"
      :players="team.getGroupedPlayers.goalkeeper"
      @playerClick="showPlayerProfile"
    />
    <PlayerGroup
      id="defenses"
      name="defenses"
      :showRadio="false"
      :players="team.getGroupedPlayers.defenses"
      @playerClick="showPlayerProfile"
    />
    <PlayerGroup
      id="centers"
      name="centers"
      :showRadio="false"
      :players="team.getGroupedPlayers.centers"
      @playerClick="showPlayerProfile"
    />
    <PlayerGroup
      id="strikers"
      name="strikers"
      :showRadio="false"
      :players="team.getGroupedPlayers.strikers"
      @playerClick="showPlayerProfile"
    />
    <FullModal
      :openedModal="modals.showPlayersModal"
      @onPressBackButton="hidePlayerProfile()"
    >
      <template v-slot:buttonLeftSide>
        <img src="/assets/lock.svg" v-if="selectedPlayer.isLocked">
      </template>
      <PlayerProfile
        :player="selectedPlayer.getPlayer"
      >
      <template v-slot:footer>
        <div class="buttonList">
          <PrimaryButton v-if="selectedPlayer.isLocked" text="Quitar blindaje" @onClick="removeLock(selectedPlayer)" />
          <PrimaryButton v-else text="Blindar jugador" @onClick="addLock(selectedPlayer)" />
          <PrimaryButton v-if="canSellPlayers" text="Despedir" @onClick="openSellModal(selectedPlayer)" />
        </div>
      </template>
      </PlayerProfile>
    </FullModal>
    <PopupModal
      :is-open="modals.showSellModal"
      @closed="hideSellModal()"
    >
      <div class="firePlayerModal">
        <div class="title">
          ¿Deseas despedir a {{ selectedPlayer.getPlayer.getName }}?
        </div>
        <div class="content">
          Los beneficios obtenidos después de la venta serán de
          {{ selectedPlayer.getPlayer.getFormattedReimbursementAfterSellLong }}
        </div>
        <div>
          <SmallButton text="Despedir" @click="sellPlayer(selectedPlayer)" />
        </div>
      </div>
    </PopupModal>
    <PopupModal
      :is-open="modals.showSuccessfulSell"
      @closed="closeSuccessfulSell()"
    >
      <div class="successfulSell">
        <div class="title">
          ¡Has vendido el jugador exitosamente!
        </div>
        <div class="button">
          <SmallButton text="Cerrar" @onClick="closeSuccessfulSell()"></SmallButton>
        </div>
      </div>
    </PopupModal>
    <PopupModal
      :is-open="modals.showLocksLimitReachedModal"
      @closed="hideModal('showLocksLimitReachedModal')"
    >
      <div class="locksLimitReached">
        <div class="title">
          ¡Oops!
        </div>
        <div>
          <b>Ya has blindado a {{ team.getSponsor.getLocks }} jugadores.</b>
        </div>
        <div>
          Para blindar a este jugador, primero tendrás que quitarle el blindaje a otro jugador.
        </div>
        <div class="button">
          <SmallButton text="Cerrar" @onClick="hideModal('showLocksLimitReachedModal')"></SmallButton>
        </div>
      </div>
    </PopupModal>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { modalController } from "@ionic/vue"
import PlayerProfile from '@/components/Player/PlayerProfile.vue'
import FullModal from '@/components/FullModal/FullModal.vue'
import { reactive, ref } from '@vue/reactivity'
import PrimaryButton from '@/components/Button/PrimaryButton.vue'
import PopupModal from '@/components/PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'
import Team from '@/domain/models/Team'
import PlayerGroup from '@/views/Tabs/Market/PlayerGroup.vue'

export default {
  name: 'Squad',
  components: {
    PlayerProfile,
    FullModal,
    PrimaryButton,
    PopupModal,
    SmallButton,
    PlayerGroup
  },
  props: {
    team: Team,
    canSellPlayers: Boolean
  },
  setup(props) {
    const store = useStore()
    const selectedPlayer = ref({})

    const modals = reactive({
      showPlayersModal: false,
      showSellModal: false,
      showSuccessfulSell: false,
      showLocksLimitReachedModal: false
    })

    const showPlayerProfile = (player) => {
      selectedPlayer.value = player
      modals.showPlayersModal = true
    }

    const hidePlayerProfile = () => {
      hideModal('showPlayersModal')
      modalController.dismiss()
    }

    const openSellModal = () => {
      modals.showSellModal = true
    }

    const hideSellModal = () => {
      hideModal('showSellModal')
      modalController.dismiss()
    }

    const sellPlayer = (player) => {
      const league = store.getters.getCurrentLeague
      store.commit('showLockScreen')
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: props.team.getId,
        playerId: player.getPlayer.getId
      }
      store.dispatch('sellPlayer', data)
        .then(() => {
          store.dispatch('refreshTeam')
            .then(() => {
              hidePlayerProfile(player.getId)
              store.commit('hideLockScreen')
              modals.showSuccessfulSell = true
            })
        })
    }

    const closeSuccessfulSell = () => {
      hideModal('showSuccessfulSell')
      modalController.dismiss()
    }

    const removeLock = (player) => {
      lockHandler(player, false)
    }

    const addLock = (player) => {
      lockHandler(player, true)
    }

    const lockHandler = (player, lock) => {
      const data = {
        leagueId: store.getters.getCurrentLeague.getId,
        seasonId: store.getters.getCurrentLeague.getSeason.getId,
        teamId: store.getters.getCurrentTeam.getId,
        teamPlayerId: player.getId,
        locked: lock
      }
      store.commit('showLockScreen')
      store.dispatch('updateTeamPlayer', data)
        .then(() => {
          player.setLocked(lock)
        })
        .catch((res) => {
          if (res.errors != 'api.team-player.reached_limit_locks') {
            return
          }

          modals.showLocksLimitReachedModal = true
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const hideModal = (key) => {
      modals[key] = false
    }

    return {
      modals,
      selectedPlayer,

      showPlayerProfile,
      hidePlayerProfile,
      openSellModal,
      hideSellModal,
      sellPlayer,
      closeSuccessfulSell,
      removeLock,
      addLock,
      hideModal
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './Squad.sass'
</style>