import Season from "@/domain/models/Season"
import TeamFactory from "@/domain/services/TeamFactory"
import UserFactory from "@/domain/services/UserFactory"

class SeasonFactory {
  get (data) {
    const season = {
      id: data.id,
      number: data.number,
      bidStartAt: data.bidStartAt,
      openMarketStartAt: data.openMarketStartAt,
      regularStartAt: data.regularStartAt,
      restartedAt: data.restartedAt,
      status: data.status,
      changeSponsor: data.changeSponsor,
      directBuy: data.directBuy,
      teams: createTeamIterator(data.teams),
      managers: createManagerIterator(data.managers),
    }

    return new Season(season)
  }
}

function createTeamIterator(teams) {
  return teams.map(team => TeamFactory.get(team))
}

function createManagerIterator(managers) {
  return managers.map(manager => UserFactory.get(manager))
}

export default new SeasonFactory()
