<template>
  <ion-button mode="ios" class="small-button" :color="color" @click="$emit('onClick')">
    {{ text }}
  </ion-button>
</template>

<script>

import { IonButton } from '@ionic/vue'

export default {
  name: "SmallButton",
  components: {
    IonButton
  },
  props: {
    text: String,
    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style lang="sass" scoped>
  @import './SmallButton.sass'
</style>
