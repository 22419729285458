const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']

export const shortDate = (date) => {
  if (date === null) {
    return null
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

export const longDateTime = (date) => {
  if (date === null) {
    return null
  }
  const { year, month, day, hour, minute } = formatDateTime(date)
  return `${day} ${month} ${year}, ${hour}:${minute}`
}

export const longDate = (date) => {
  if (date === null) {
    return null
  }
  const { year, month, day } = formatDateTime(date)
  return `${day} ${month} ${year}`
}

export function daysFromNow(timestampInMilliseconds) {
  timestampInMilliseconds -= (new Date()).getTime()
  timestampInMilliseconds /= 1000
  return formatSeconds(timestampInMilliseconds)
}

export function formatSeconds(seconds) {
  const days = Math.floor(seconds / 86400)
  seconds -= days * 86400
  const hours = Math.floor(seconds / 3600)
  seconds -= hours * 3600
  const mins = Math.floor(seconds / 60)
  seconds -= mins * 60
  return { days: days, hours: hours, mins: mins, secs: Math.floor(seconds)}
}

export function formatToDaysHoursMinutes({ days, hours, mins}) {
  const ret = []
  if (days > 0) {
    ret.push(`${days}d`)
  }
  if (hours > 0) {
    ret.push(`${hours}h`)
  }
  ret.push(`${mins}m`)

  return ret.join(' ')
}

export function formatToDaysHoursMinutesSeconds({ days, hours, mins, secs}) {
  const time = {
    days: days,
    hours: hours,
    mins: mins
  }
  const ret = formatToDaysHoursMinutes(time)

  return ret + ' ' + secs + 's'
}

function formatDateTime(date) {
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const month = months[date.getMonth()]
  const year = date.getFullYear()
  return { year, month, day, hour, minute }
}
