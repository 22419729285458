<template>
  <div class="finances" :key="league">
    <div class="finances__global">
      <div class="finances__global__element">
        <div class="finances__image">
          <img src="/assets/wallet.svg">
        </div>
        <div class="finances__global__element__contents">
          <div class="finances__global__element__contents__title">
            Balance
          </div>
          <div class="finances__global__element__contents__value">
            {{ team.getFormattedVirtualBudgetLong }}
          </div>
        </div>
      </div>
      <div class="finances__global__element">
        <div class="finances__image">
          <img src="/assets/activity.svg">
        </div>
        <div class="finances__global__element__contents">
          <div class="finances__global__element__contents__title">
            Masa salarial
          </div>
          <div class="finances__global__element__contents__value">
            <span class="current" :class="{ wrong: team.hasExceededSalaryTable }">
              {{ team.getFormattedSalaryTableLong }}
            </span> / {{ league.getFormattedSalaryTableLong }}
          </div>
        </div>
      </div>
      <div class="finances__global__element">
        <div class="finances__image">
          <img src="/assets/activity.svg">
        </div>
        <div class="finances__global__element__contents">
          <div class="finances__global__element__contents__title">
            Jugadores
          </div>
          <div class="finances__global__element__contents__value">
            <span class="current" :class="{ wrong: !team.hasMinPlayers }">
              {{ team.getTeamPlayers.length }}
             </span> / {{ league.getTeamMaxPlayers }}
          </div>
        </div>
      </div>
    </div>
    <div class="finances__transactions" v-if="transactions.length > 0">
      <div class="finances__transactions__title">
        Movimientos
      </div>
      <div class="finances__transactions__contents">
        <div v-for="transaction in transactions" :key="transaction">
          <div v-if="transaction.getType == TYPE_DIRECT_BUY_BOUGHT" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has hecho un hachazo por
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.playerName }} por {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
          <div v-if="transaction.getType == TYPE_WON_BID" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has ganado la puja por
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.playerName }} por {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
          <div v-if="transaction.getType == TYPE_PLAYER_BOUGHT" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has comprado a
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.playerName }} por {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
          <div v-if="transaction.getType == TYPE_PLAYER_SOLD" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has vendido a
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.playerName }} por {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
          <div v-if="transaction.getType == TYPE_PLAYER_TRADED_SOLD" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has vendido a
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.source }} a
                {{ transaction.getNotes.playerName }} por
                {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
          <div v-if="transaction.getType == TYPE_PLAYER_TRADED_BOUGHT" class="finances__transactions__contents__item">
            <div class="finances__image">
              <img src="/assets/buy.svg">
            </div>
            <div>
              <div class="finances__transactions__contents__item__title">
                Has comprado a
              </div>
              <div class="finances__transactions__contents__item__subtitle">
                {{ transaction.getNotes.source }} a
                {{ transaction.getNotes.playerName }} por
                {{ longCurrency(transaction.getNotes.amount) }}
              </div>
              <div class="finances__transactions__contents__item__date">
                {{ transaction.getFormattedCreatedAt }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Team from '@/domain/models/Team';
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import {
  TYPE_WON_BID,
  TYPE_PLAYER_BOUGHT,
  TYPE_PLAYER_SOLD,
  TYPE_PLAYER_TRADED_SOLD,
  TYPE_PLAYER_TRADED_BOUGHT,
  TYPE_DIRECT_BUY_BOUGHT,
} from '@/domain/models/BotNews';

import { longCurrency } from '@/domain/services/CurrencyFormatter'

export default {
  name: 'Squad',
  components: { },
  props: {
    team: Team
  },
  setup() {
    const store = useStore()
    const transactions = ref([])
    const league = computed(() => {
      const league = store.getters.getCurrentLeague
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId
      }
      store.dispatch('getTransactions', data)
        .then(retrievedTransactions => {
          retrievedTransactions.sort((transactionA, transactionB) => {
            if (transactionA.getCreatedAt.getTime() > transactionB.getCreatedAt.getTime()) {
              return -1
            }
            if (transactionA.getCreatedAt.getTime() < transactionB.getCreatedAt.getTime()) {
              return 1
            }
            return 0
          })
          transactions.value = retrievedTransactions
        })
      return league
    })

    return {
      league,
      transactions,
      TYPE_WON_BID,
      TYPE_PLAYER_BOUGHT,
      TYPE_PLAYER_SOLD,
      TYPE_PLAYER_TRADED_SOLD,
      TYPE_PLAYER_TRADED_BOUGHT,
      TYPE_DIRECT_BUY_BOUGHT,

      longCurrency,
    }
  }
}
</script>

<style lang="sass" scoped>
 @import 'Finances.sass'
</style>
