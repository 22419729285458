<template>
  <div class="offersMade">
    <div class="offersMade__header">
      <span>Ofertas realizadas</span>
      <span v-if="totalOffersMade > 0">{{ longCurrency(totalOffersMade) }}</span>
    </div>
    <div v-for="offer in offers" :key="offer">
      <OffersMadePlayer
        :offer="offer"
        @showPlayer="showPlayer"
        @offerDeleted="deleteOffer"
        @offerWithdrawn="showWithdrawOfferModal"
      />
    </div>
    <div v-if="offers.length == 0" class="offersMade__noMade">
      No has hecho ofertas
    </div>
  </div>
  <PopupModal
    :is-open="modals.withdrawn"
    @closed="hideModal('withdrawn')"
  >
    <div class="withdrawOfferModal">
      <div>
        ¿Deseas retirar tu oferta por {{ selectedOffer.getPlayer.getName }}
        por {{ selectedOffer.getFormattedAmountLong }}
      </div>
      <div>
        <SmallButton text="Retirar oferta" @onClick="withdrawOffer(selectedOffer)" />
      </div>
    </div>
  </PopupModal>
  <FullModal
    :openedModal="modals.playerProfile"
    @onPressBackButton="hideModal('playerProfile')"
  >
    <PlayerProfile
      :player="selectedPlayer"
    />
  </FullModal>
</template>

<script>
import { computed, reactive, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { longCurrency } from '@/domain/services/CurrencyFormatter'
import OffersMadePlayer from './OffersMadePlayer.vue'
import FullModal from '@/components/FullModal/FullModal.vue'
import PlayerProfile from '@/components/Player/PlayerProfile.vue'
import PopupModal from '@/components/PopupModal/PopupModal.vue'
import SmallButton from '@/components/Button/SmallButton.vue'
export default {
  components: {
    OffersMadePlayer,
    FullModal,
    PlayerProfile,
    PopupModal,
    SmallButton
  },
  setup() {
    const store = useStore()
    const offers = ref([])
    const totalOffersMade = computed(() => {
      return offers.value.map(offer => offer.getAmount).reduce((a, b) => {
        return a + b
      }, 0)
    })
    const modals = reactive({
      playerProfile: false,
      withdrawn: false
    })
    const selectedPlayer = ref()
    const selectedOffer = ref()

    const league = store.getters.getCurrentLeague
    const team = store.getters.getCurrentTeam

    const data = {
      leagueId: league.getId,
      seasonId: league.getSeason.getId,
      teamId: team.getId
    }
    store.dispatch('getMyOffers', data)
      .then(res => {
        offers.value = res
      })

    const withdrawOffer = (offer) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        offerId: offer.getId
      }
      store.dispatch('deleteOffer', data)
        .then(() => {
          const index = offers.value.findIndex(offerInArray => offerInArray.getId == offer.getId)
          const amount = offers.value[index].getAmount
          team.modifyBudget(amount)
          offers.value.splice(index, 1)
          modals.withdrawn = false
        })
    }

    const showWithdrawOfferModal = (offer) => {
      selectedOffer.value = offer
      modals.withdrawn = true
    }

    const deleteOffer = (offer) => {
      const data = {
        leagueId: league.getId,
        seasonId: league.getSeason.getId,
        teamId: team.getId,
        offerId: offer.getId
      }
      store.dispatch('deleteOffer', data)
        .then(() => {
          const index = offers.value.findIndex(offerInArray => offerInArray.getId == offer.getId)
          offers.value.splice(index, 1)
        })
    }

    const showPlayer = (player) => {
      selectedPlayer.value = player
      modals.playerProfile = true
    }

    const hideModal = (key) => {
      modals[key] = false
    }

    return {
      modals,
      selectedOffer,
      selectedPlayer,
      totalOffersMade,
      offers,

      hideModal,
      deleteOffer,
      showWithdrawOfferModal,
      withdrawOffer,
      showPlayer,
      longCurrency
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'OffersMade.sass'
</style>
