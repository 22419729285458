<template>
  <div class="auth__form">
    <ion-row>
      <ion-col>
        <span class="auth__form__title">Bienvenido</span>
        <div class="auth__form__body">
          <Form ref="loginForm" @onSubmit="handleLogin" @keyup.enter="handleLogin">
            <div class="auth__form__body__formError" v-if="formError">Correo electrónico o contraseña incorrectos</div>
            <Field name="email" label="correo electrónico" v-slot="{ field, meta }" rules="required|email">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Email"
                  inputType="email"
                  @change="onChangeValue($event, 'email')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="email" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <br/>
            <Field name="password" label="contraseña" v-slot="{ field, meta }" rules="required|password">
              <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Password"
                  inputType="password"
                  @change="onChangeValue($event, 'password')"
                  backgroundColor="invertedColor"
              />
            </Field>
            <ErrorMessage name="password" v-slot="{ message }">
              <div v-if="message" class="error">{{ message }}</div>
            </ErrorMessage>
            <div class="auth__form__body__footer">
              <div class="auth__form__rememberPassword">
                <!--
                <Checkbox
                  :model="formData.rememberPassword"
                  labelText="Recordar contraseña"
                  @changeBoolean="onChangeRememberPassword"
                />
              </div>
              -->
              <ion-label
                  class="auth__form__forgotPassword"
                  @click="$emit('closeLoginOpenForgotPassword')"
              >
                He olvidado mi contraseña
              </ion-label>
              </div>
            </div>
            <PrimaryButton text="Iniciar sesión" @onClick="handleLogin"/>
          </Form>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center auth__help">
        No tengo cuenta. <a @click="$emit('closeLoginOpenRegister')">Registrarme</a>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { IonRow, IonCol, IonLabel } from '@ionic/vue'
import Input from "../Input/Input";
import Form from "../Input/Form"
import PrimaryButton from "../Button/PrimaryButton"
import { Field, ErrorMessage, defineRule } from "vee-validate"
import { email as emailValidator, required } from "@vee-validate/rules"

export default {
  name: "LoginForm",
  components: {
    PrimaryButton,
    IonRow,
    IonCol,
    IonLabel,
    Form,
    Input,
    Field,
    ErrorMessage
  },
  emits: ['closeModal', 'closeLoginOpenForgotPassword', 'closeLoginOpenRegister'],
  setup(_, { emit }) {
    const formData = ref({
      email: '',
      password: '',
      rememberPassword: false
    })
    const loginForm = ref()
    const formError = ref(false)

    const store = useStore()
    const router = useRouter()

    const handleLogin = () => {
      loginForm.value.validate()
      .then(({ valid }) => {
        if (!valid) {
          return
        }
        formError.value = false
        store.commit('showLockScreen')
        store.dispatch('login', formData.value)
          .then(() => {
            emit('closeModal')
            router.push({ name: 'entryPoint' })
          })
          .catch((error) => {
            console.error(error)
            formError.value = true
          })
          .finally(() => {
            store.commit('hideLockScreen')
          })
      })
    }
    const onChangeValue = (event, key) => {
      formData.value[key] = event.target.value
    }
    const onChangeRememberPassword = () => {
      formData.value.rememberPassword = !this.formData.rememberPassword
    }

    const passwordValidator = (value) => {
      if (/^(?=.*[A-z])(?=.*\d).{8,}$/.test(value)) {
        return true
      }
      return '8 caracteres mínimo, con al menos una letra y un número'
    }

    defineRule("email", emailValidator)
    defineRule("required", required)
    defineRule("password", passwordValidator)

    return {
      formData,
      loginForm,
      formError,

      emailValidator,
      required,
      passwordValidator,

      handleLogin,
      onChangeValue,
      onChangeRememberPassword
    }
  }
};
</script>

<style lang="sass" scoped>
  @import './Auth.sass'
</style>
