import axios from '@/plugins/axios'
import TeamFactory from '@/domain/services/TeamFactory'
import PichichiPlayerFactory from '@/domain/services/PichichiPlayerFactory'
import ZamoraPlayerFactory from '@/domain/services/ZamoraPlayerFactory'
import MVPPlayerFactory from '@/domain/services/MVPPlayerFactory'
import { uuid } from 'vue-uuid'

const URL_CREATE_SEASON = '/api/users/me/leagues/{leagueId}/seasons'
const URL_GET_SEASON_BEST_ATTACK = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/best-attack'
const URL_GET_SEASON_BEST_DEFENSE = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/best-defense'
const URL_GET_SEASON_PICHICHI = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/pichichi'
const URL_GET_SEASON_ZAMORA = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/zamora'
const URL_GET_SEASON_MVPS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/mvps'
const URL_DELETE_SEASON_TEAM = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/team/{teamId}'
const URL_MOVE_TO_SPECIFIC_PHASE = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}?{action}'

export function createSeason(season) {
  const data = {
    id: uuid.v4(),
    startAt: season.startAt,
  }

  const url = URL_CREATE_SEASON.replace('{leagueId}', season.leagueId)

  return axios.post(url, data)
    .then(() => data)
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}


export function getSeasonBestAttack(leagueId, seasonId) {
  const url = URL_GET_SEASON_BEST_ATTACK
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  return axios.get(url)
    .then(res => {
      return res.data.map((team) => TeamFactory.get(team))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getSeasonBestDefense(leagueId, seasonId) {
  const url = URL_GET_SEASON_BEST_DEFENSE
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
return axios.get(url)
    .then(res => {
      return res.data.map((team) => TeamFactory.get(team))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getSeasonPichichi(leagueId, seasonId) {
  const url = URL_GET_SEASON_PICHICHI
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
return axios.get(url)
    .then(res => {
      return res.data.map((pichichiPlayer) => PichichiPlayerFactory.get(pichichiPlayer))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getSeasonZamora(leagueId, seasonId) {
  const url = URL_GET_SEASON_ZAMORA
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
return axios.get(url)
    .then(res => {
      return res.data.map((zamoraPlayer) => ZamoraPlayerFactory.get(zamoraPlayer))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function getSeasonMVPs(leagueId, seasonId) {
  const url = URL_GET_SEASON_MVPS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
return axios.get(url)
    .then(res => {
      return res.data.map((mvpPlayer) => MVPPlayerFactory.get(mvpPlayer))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function deleteSeasonTeam(leagueId, seasonId, teamId) {
  const url = URL_DELETE_SEASON_TEAM
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
  return axios.delete(url)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function moveToBidPhase(leagueId, seasonId) {
  const url = URL_MOVE_TO_SPECIFIC_PHASE
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{action}', 'bid')
  return axios.post(url)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function moveToOpenMarketPhase(leagueId, seasonId) {
  const url = URL_MOVE_TO_SPECIFIC_PHASE
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{action}', 'open-market')
  return axios.post(url)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function moveToRegularPhase(leagueId, seasonId) {
  const url = URL_MOVE_TO_SPECIFIC_PHASE
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{action}', 'regular')
  return axios.post(url)
    .then(res => {
      return res.data
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
