import axios from '@/plugins/axios'
import PlayerFactory from '@/domain/services/PlayerFactory'

const URL_SEARCH_FREE_AGENTS = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/free-agents'
const URL_BUY_PLAYER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/players'
const URL_SELL_PLAYER = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/players/{playerId}'

export function searchFreeAgents(leagueId, seasonId, name, sort) {
  const url = URL_SEARCH_FREE_AGENTS
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
  const params = {
    params: {
      name: name,
      sort: sort
    }
  }
  return axios.get(url, params)
    .then(res => {
      return res.data.collection.map(news => PlayerFactory.get(news))
    })
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function buyPlayer(leagueId, seasonId, teamId, playerId) {
  const url = URL_BUY_PLAYER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
  const data = {
    playerId: playerId
  }
  return axios.put(url, data)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}

export function sellPlayer(leagueId, seasonId, teamId, playerId) {
  const url = URL_SELL_PLAYER
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{playerId}', playerId)
  return axios.delete(url)
    .then(res => res)
    .catch(error => {
      return Promise.reject(error.response.data)
    })
}
