<template>
  <div class="baseSelector" :theme="theme" :class="{ filled: elements !== null }">
    <ion-button expand="full" fill="clear" @click="openPicker">
      <ion-label slot="start">
        <slot />
        <span v-if="elements !== null" class="wrapper">
          <span v-if="elements === 1">{{ elements }} {{ singularSuffix }}</span>
          <span v-if="elements !== 1">{{ elements }} {{ pluralSuffix }}</span>
        </span>
      </ion-label>
    </ion-button>
  </div>
</template>

<script>
import {
  IonButton,
  IonLabel,
  pickerController,
} from '@ionic/vue';

import { ref } from 'vue';

export default {
  name: 'EnterResult',
  components: {
    IonButton,
    IonLabel,
  },
  props: {
    selectedIndex: {
      type: Number,
      default: null
    },
    singularSuffix: {
      type: String,
      default: ''
    },
    pluralSuffix: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    theme: {
      type: String,
      default: 'light'
    }
  },
  emits: ['changed', 'blur', 'update:modelValue'],
  setup(props, { emit }) {
    const options = ref(props.options)
    const selectedIndex = ref(props.selectedIndex)

    const elements = ref(null)
    if (selectedIndex.value !== null) {
      elements.value = options.value[selectedIndex.value].text
    }

    const openPicker = async () => {
      const picker = await pickerController.create({
        columns: [{
          name: 'goals',
          options: options.value,
          selectedIndex: selectedIndex.value
        }],
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
          },
          {
            text: "Confirmar",
            handler: (value) => {
              elements.value = value.goals.text
              emit('update:modelValue', value.goals.value)
              emit('changed', value.goals.value)
            },
          },
        ],
      })

      for (let col of picker.columns) {
        for (let opt of col.options) {
          delete opt.selected;
          delete opt.duration;
          delete opt.transform;
        }
      }
      await picker.present();
    }

    return {
      elements,

      openPicker,
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './BaseSelector.sass'
</style>
