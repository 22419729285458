import axios from '@/plugins/axios'
import { uuid } from 'vue-uuid'

const URL_UPDATE = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/teamPlayers/{teamPlayerId}'
const URL_BUY = '/api/users/me/leagues/{leagueId}/seasons/{seasonId}/teams/{teamId}/teamPlayers/{teamPlayerId}/buy'

export function updateTeamPlayer(leagueId, seasonId, teamId, teamPlayerId, locked) {
  const url = URL_UPDATE
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{teamPlayerId}', teamPlayerId)

  const data = {
    locked: locked
  }

  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}

export function buyTeamPlayer(leagueId, seasonId, teamId, teamPlayerId) {
  const url = URL_BUY
    .replace('{leagueId}', leagueId)
    .replace('{seasonId}', seasonId)
    .replace('{teamId}', teamId)
    .replace('{teamPlayerId}', teamPlayerId)

  const data = {
    id: uuid.v4
  }

  return axios.post(url, data)
    .then(res => res)
    .catch(error => Promise.reject(error.response.data))
}