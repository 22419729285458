import PositionBidBatchPlayerFactory from "@/domain/services/PositionBidBatchPlayerFactory"
import PositionBidBatch from "../models/PositionBidBatch"

class PositionBidBatchFactory {
  get (data) {
    const positionBidBatch = {
      id: data.id,
      batchNumber: data.batchNumber,
      currentDay: data.currentDay,
      finishInSeconds: data.finishInSeconds,
      bidBatchPlayers: create(data.bidBatchPlayers)
    }

    return new PositionBidBatch(positionBidBatch)
  }
}

function create(bidBatches) {
  return bidBatches.map(bidBatch => PositionBidBatchPlayerFactory.get(bidBatch))
}

export default new PositionBidBatchFactory()
