<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Seleccionar</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <BigButton text="Crear nueva liga" />
      <BigButton text="Unirme a una liga" />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonTitle, IonToolbar } from '@ionic/vue'
import BigButton from "../components/Button/BigButton";

export default {
  name: "LeagueSelector",
  components: {BigButton, IonPage, IonHeader, IonContent, IonToolbar, IonTitle }
}
</script>
