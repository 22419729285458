<template>
  <ion-button expand="block" @click="$emit('onClick')">
    {{ text }}
  </ion-button>
</template>

<script>
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Button",
  components: { IonButton },
  props: {
    text: String
  }
})
</script>
