<template>
  <div class="infoCard">
    <div class="infoCard__left">
      <img
          class="infoCard__avatar"
          :src="avatar"
          alt="avatar image"
      />
      <div class="infoCard__info">
        <div class="infoCard__title">{{ title }}</div>
        <div class="infoCard__subtitle" v-if="subtitle">{{ subtitle }}</div>
      </div>
    </div>
    <div class="infoCard__rightInfo">
      <div class="infoCard__rightInfo__data">
        {{ info }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    avatar: String,
    title: String,
    subtitle: String,
    info: String
  }
}
</script>

<style lang="sass" scoped>
  @import './InfoCard.sass'
</style>
