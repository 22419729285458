<template>
  <section class="player-photo" :class="size">
    <div class="player-photo__avatar">
      <div class="player-photo__band" :class="bandExtraClass">{{ player.getObjective }}</div>
      <img :src="image" />
    </div>
  </section>
</template>

<script>
import Player from "@/domain/models/Player";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerPhoto",
  props: {
    player: Player,
    size: {
      type: String,
      default: "large",
    }
  },
  setup(props) {
    let bandExtraClass = null;
    if (props.player.getObjective !== "") {
      bandExtraClass = "player-photo__band--" + props.player.getObjective;
    }
    return {
      bandExtraClass: bandExtraClass,
      image: process.env.VUE_APP_PLAYER_IMAGE_API_URL + props.player.getPlayerPhotoUrl,
    };
  },
});
</script>

<style lang="sass" scoped>
  @import 'PlayerPhoto.sass'
</style>
