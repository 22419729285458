<template>
  <div class="sponsorDetail">
    <ion-row v-if="showImage">
      <ion-col>
        <div class="sponsorDetail__image">
          <img :src="'/assets/' + image">
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="sponsorDetail__title">
          {{ name }}
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="sponsorDetail__table">
          <table>
            <tr>
              <th>Ingresos por ganar</th>
              <td>{{ longCurrency(wins) }}</td>
            </tr>
            <tr>
              <th>Ingresos por empatar</th>
              <td>{{ longCurrency(ties) }}</td>
            </tr>
            <tr>
              <th>Ingresos por perder</th>
              <td>{{ longCurrency(loses) }}</td>
            </tr>
            <tr>
              <th>Máximo goleador</th>
              <td>{{ longCurrency(maxScorer) }}</td>
            </tr>
            <tr>
              <th>Máximo MVP</th>
              <td>{{ longCurrency(maxMvp) }}</td>
            </tr>
            <tr>
              <th>Tarjetas</th>
              <td>{{ longCurrency(cards) }}</td>
            </tr>
            <tr>
              <th>Zamora</th>
              <td>{{ longCurrency(zamora) }}</td>
            </tr>
          </table>
        </div>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { IonCol, IonRow } from '@ionic/vue'
import { longCurrency } from '@/domain/services/CurrencyFormatter'

export default {
  components: {
    IonCol,
    IonRow,
  },
  props: {
    showImage: {
      type: Boolean,
      default: false
    },
    name: String,
    wins: Number,
    ties: Number,
    loses: Number,
    maxScorer: Number,
    maxMvp: Number,
    cards: Number,
    zamora: Number,
    changes: Number,
    bestSquad: Number,
    image: String
  },
  setup() {
    return {
      longCurrency
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'SponsorDetail.sass'
</style>