<template>
  <EllipsesBackground>
    <div class="basicView">
      <div class="basicView__logo">
        <Logo />
      </div>
      <div class="basicView__info">
        <div class="basicView__title">
          <slot name="title"></slot>
        </div>
        <div class="basicView__description">
          {{ description }}
        </div>
      </div>
      <div class="basicView__footer">
        <slot name="footerButtons"></slot>
      </div>
    </div>
  </EllipsesBackground>
</template>

<script>
import Logo from "../../components/Logo/Logo";
import EllipsesBackground from "../../components/EllipsesBackground/EllipsesBackground"

export default {
  name: "BasicViewWithEllipsesBackground",
  components: { EllipsesBackground, Logo },
  props: {
    description: String,
  }
};
</script>

<style lang="sass" scoped>
  @import './BasicViewWithEllipsesBackground.sass'
</style>
