import UserFactory from "@/domain/services/UserFactory"
import BotNews from "@/domain/models/BotNews"

class BotNewsFactory {
  get (data) {
    const botNews = {
      id: data.id,
      notes: data.notes,
      type: data.type,
      createdAt: data.createdAt,
      referringUser: UserFactory.get(data.referringUser)
    }

    return new BotNews(botNews)
  }
}

export default new BotNewsFactory()
