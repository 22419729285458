export default class User {
  constructor (data) {
    this.id = data.id
    this.email = data.email
    this.fifaId = data.fifaId
    this.username = data.username
    this.avatar = data.avatar
  }

  get getId () {
    return this.id
  }

  get getEmail () {
    return this.email
  }

  get getFifaId () {
    return this.fifaId
  }

  get getUsername () {
    return this.username
  }

  get getInitials () {
    return this.getUsername[0]
  }

  get getAvatar () {
    return this.avatar || '/assets/avatar-default.svg'
  }
}
