import { getPluralMessage } from '@/domain/services/I18NMessages'

export default class PositionBidBatchPlayer {
  constructor (data) {
    this.position = data.position
    this.sortIndex = data.sortIndex
    this.players = data.players
  }

  get getPosition () {
    return this.position
  }

  get getLocalizedPosition () {
    return getPluralMessage(this.getPosition)
  }

  get getSortIndex () {
    return this.sortIndex
  }

  get getPlayers () {
    return this.players
  }
}
