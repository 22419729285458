import League from "@/domain/models/League"
import SeasonFactory from "@/domain/services/SeasonFactory"
import UserFactory from "@/domain/services/UserFactory"

class LeagueFactory {
  get (data) {
    const league = {
      id: data.id,
      name: data.name,
      active: data.active,
      code: data.code,
      maxPlayers: data.maxPlayers,
      budget: data.budget,
      salaryTable: data.salaryTable,
      duration: data.duration,
      teamMaxPlayers: data.teamMaxPlayers,
      season: SeasonFactory.get(data.currentSeason),
      createdUser: UserFactory.get(data.createdUser)
    }

    return new League(league)
  }
}

export default new LeagueFactory()
