<template>
  <div class="teamHealth">
    <div class="teamHealth__players" :class="{ wrong: !team.hasMinPlayers }">
      <div><img src="/assets/white-team.svg" height="23"></div>
      <div>{{ team.getTeamPlayers.length }}</div>
    </div>
    <div class="teamHealth__salary-table" :class="{ wrong: team.hasExceededSalaryTable }">
      <div><img src="/assets/activity.svg" height="21"></div>
      <div>{{ team.getFormattedSalaryTableLong }}</div>
    </div>
    <div class="teamHealth__budget normal">
      <div><img src="/assets/wallet.svg" height="18"></div>
      <div v-if="!showReminingBudget">{{ team.getFormattedBudgetLong }}</div>
      <div v-if="showReminingBudget">{{ team.getFormattedVirtualBudgetLong }}</div>
    </div>
  </div>
</template>

<script>
import Team from '@/domain/models/Team'

export default {
  props: {
    team: Team,
    showReminingBudget: {
      type: Boolean,
      default: false
    }
  },
  setup() {

  },
}
</script>

<style lang="sass" scoped>
  @import 'TeamHealth.sass'
</style>