<template>
  <div class="menuHeaderWithInfo">
    <MenuHeader
      :showMenuButton="showMenuButton"
      :showBackButton="showBackButton"
      @onPressBackButton="$emit('onPressBackButton')"
    >
      {{ title }}
      <template v-slot:info>
        {{ remaining }}
      </template>
    </MenuHeader>
  </div>
</template>

<script>
import MenuHeader from "./MenuHeader";
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "MenuHeaderWithInfo",
  components: { MenuHeader },
  props: {
    showMenuButton: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const title = computed(() => {
      return store.getters.getCurrentLeague.name
    })
    const remaining = computed(() => {
      return store.getters.getCurrentLeague.getTimeRemaining
    })

    return {
      title,
      remaining
    }
  }
}
</script>
