import UserFactory from "@/domain/services/UserFactory"
import News from "@/domain/models/News"

class NewsFactory {
  get (data) {
    const news = {
      id: data.id,
      message: data.message,
      createdAt: data.createdAt,
      author: UserFactory.get(data.author)
    }

    return new News(news)
  }
}

export default new NewsFactory()
