<template>
  <div>
    <PickTeamNameCard
      @nameSet="nameTeamHandler"
      v-if="showCard == 'nameTeam'"
    />
    <SelectSponsorCard
      :sponsors="sponsors"
      @sponsorSelected="sponsorSelectedHandler"
      v-if="showCard == 'selectSponsor'"
    />
    <InvitePeopleToLeague
      v-if="showCard == 'invitePeople'"
      :fromOnboarding="fromOnboarding"
    />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import PickTeamNameCard from '@/components/PickTeamNameCard/PickTeamNameCard'
import SelectSponsorCard from '@/components/SelectSponsorCard/SelectSponsorCard'
import InvitePeopleToLeague from '@/components/InvitePeopleToLeague/InvitePeopleToLeague.vue'

export default {
  components: {
    PickTeamNameCard,
    SelectSponsorCard,
    InvitePeopleToLeague,
  },
  props: {
    invertedColor: {
      type: Boolean,
      default: false
    },
    fromOnboarding: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'create' ],
  setup(_, { emit }) {
    const store = useStore()
    const sponsors = ref([])
    const showCard = ref('nameTeam')
    let teamName = ''

    const nameTeamHandler = (name) => {
      teamName = name
      store.commit('showLockScreen')
      showCard.value = 'selectSponsor'
      store.dispatch('getSponsors')
        .then((sponsorsList) => {
          sponsors.value = sponsorsList
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const sponsorSelectedHandler = (sponsor) => {
      showCard.value = 'invitePeople'
      emit('create', {
        sponsorId: sponsor.getId,
        teamName: teamName
      })
    }

    return {
      showCard,
      sponsors,

      sponsorSelectedHandler,
      nameTeamHandler,
    }
  },
}
</script>
