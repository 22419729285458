<template>
  <div class="cantEnterResult">
    <ResultCard
      :id="id"
      :homeAvatar="homeAvatar"
      :homeName="homeName"
      :visitorAvatar="visitorAvatar"
      :visitorName="visitorName"
    />
    <div class="cantEnterResult__message">
      El equipo local todavía no ha subido el resultado del partido
    </div>
  </div>
</template>

<script>
import ResultCard from '@/components/ResultCard/ResultCard'

export default {
  name: 'CantEnterResult',
  components: { ResultCard },
  props: {
    id: String,
    homeAvatar: String,
    homeName: String,
    visitorAvatar: String,
    visitorName: String
  },
}
</script>

<style lang="sass" scoped>
  @import "@/style/colors.sass"
  .cantEnterResult
    &__message
      margin-top: 40px
      color: $color__light-grey
      line-height: 20px
      text-align: center
      font-size: 14px
      font-weight: 600
</style>