<template>
<div class="userSponsor">
  <PrimaryButton
    v-if="league.getSeason.canChangeSponsor"
    text="Cambiar de patrocinador"
    @onClick="showModal('showSponsorListModal')"
  />
  <SponsorDetail
    showImage
    :id="sponsor.getId"
    :name="sponsor.getName"
    :wins="sponsor.getWins"
    :ties="sponsor.getTies"
    :loses="sponsor.getLoses"
    :maxScorer="sponsor.getMaxScorer"
    :maxMvp="sponsor.getMaxMvp"
    :cards="sponsor.getCards"
    :zamora="sponsor.getZamora"
    :image="sponsor.getImage"
  />
  <FullModal
    :openedModal="modals.showSponsorListModal"
    @onPressBackButton="hideModal('showSponsorListModal')"
  >
    <SelectSponsorCard
      :sponsors="sponsors"
      @sponsorSelected="sponsorSelectedHandler"
    />
  </FullModal>
  <PopupMessage
    :isOpen="modals.showSponsorChangedOkModal"
    @closed="hideModal('showSponsorChangedOkModal')"
  >
    Has cambiado de patrocinador correctamente
  </PopupMessage>
</div>
</template>

<script>
import SponsorDetail from '@/components/SponsorCard/SponsorDetail';
import Sponsor from '@/domain/models/Sponsor';
import PrimaryButton from '@/components/Button/PrimaryButton.vue';
import FullModal from '@/components/FullModal/FullModal.vue';
import SelectSponsorCard from '@/components/SelectSponsorCard/SelectSponsorCard.vue';
import { useStore } from 'vuex';
import { computed, reactive, ref } from '@vue/reactivity';
import PopupMessage from '@/components/PopupMessage/PopupMessage.vue';

export default {
  components: {
    PrimaryButton,
    SponsorDetail,
    SelectSponsorCard,
    FullModal,
    PopupMessage,
  },
  props: {
    sponsor: Sponsor
  },
  setup() {
    const store = useStore()
    const modals = reactive({
      showSponsorListModal: false,
      showSponsorChangedOkModal: false
    })

    const league = computed(() => store.getters.getCurrentLeague)

    const showModal = (key) => {
      modals[key] = true
    }

    const hideModal = (key) => {
      modals[key] = false
    }

    const sponsorSelectedHandler = (sponsor) => {
      store.commit('showLockScreen')
      hideModal('showSponsorListModal')
      const team = store.getters.getCurrentTeam
      const data = {
        leagueId: store.getters.getCurrentLeague.getId,
        seasonId: store.getters.getCurrentLeague.getSeason.getId,
        teamId: team.getId,
        sponsorId: sponsor.getId
      }
      store.dispatch('updateTeam', data)
        .then(() => {
          store.commit('hideLockScreen')
          team.setSponsor(sponsor)
          showModal('showSponsorChangedOkModal')
        })
    }

    const sponsors = ref([])

    store.dispatch('getSponsors')
      .then((sponsorList) => {
        sponsors.value = sponsorList
      })

    return {
      league,
      modals,
      showModal,
      hideModal,
      sponsors,

      sponsorSelectedHandler
    }
  }
}
</script>

<style lang="sass" scoped>
  @import './UserSponsor.sass'
</style>