import axios from '@/plugins/axios';
import SponsorFactory from '@/domain/services/SponsorFactory'

const URL_READ_SPONSOR = '/api/sponsors'

export function getSponsors() {
  return axios.get(URL_READ_SPONSOR)
    .then(res => res.data.collection.map(sponsor => SponsorFactory.get(sponsor)))
    .catch(error => Promise.reject(error.response.data))
}
